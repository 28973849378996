import {
  Box,
  Container,
  Stack,
  TextField,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  SvgIcon,
  Tab,
  Tabs,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as EyeIcon } from "../../assets/images/icons/eye-icon.svg";
import { ReactComponent as EyeIconOff } from "../../assets/images/icons/eye-off-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/icons/add-icon.svg";
import { ReactComponent as ClearIcon } from "../../assets/images/icons/cross-icon.svg";
import { ReactComponent as DescriptionIcon } from "../../assets/images/icons/description-icon.svg";
import { ReactComponent as RequirementsIcon } from "../../assets/images/icons/requirements-icon.svg";
import { ReactComponent as CodeIcon } from "../../assets/images/icons/code-lg-icon.svg";
import DeleteModelIcon from "../../assets/images/icons/delete-modal-icon.svg";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../services/GetTemplate";
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import { handlePutRequest } from "../../services/PutTemplate";
import { toast } from "react-toastify";
import { postWorkHistory } from "../../services/postWorkHistory";
import { formatString, reverseString } from "../HypotheticalAnalyzer/utils";
import DraggableTableRows from "./draggableRows";
import { splitIntoTwo } from "../HypotheticalAnalyzer/helperComponents/codeTab";
import CustomTableCell from "../../components/custom/table/ThemeTableCell";
import RequirementsTab from "../HypotheticalAnalyzer/helperComponents/requirementTab";
import { decryptSocCode } from "../../utils/decryptSocCode";
import { handleGetEmploymentEstimateAllDataRequest } from "../../services/getEstimatedEmployeeAllData";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const WorkHistory = () => {
  var dbName = "veToolkitDB";
  const [editableRow, setEditableRow] = useState(null);
  const [workHistoryHeight, setWorkHistoryHeight] = useState();
  const [resultHeight, setResultHeight] = useState();
  const [DOTTitleViewDisplay, setDOTTitleViewDisplay] = useState(false);
  // State to track selected state of each item
  const [selectedItems, setSelectedItems] = useState({});
  const [workHistoryRowData, setWorkHistoryRowData] = useState();
  const [workHistoryRowDataUpdated, setWorkHistoryRowDataUpdated] = useState();
  const [workField, setWorkField] = useState([]);
  const [MPSMS, setMPSMS] = useState([]);
  const [GOE, setGOE] = useState();
  const [RIASEC, setRIASEC] = useState([]);
  const [SOC, setSOC] = useState([]);
  const [ONET, setONET] = useState([]);

  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingAddData, setLoadingAddData] = useState(false);
  const [rowNumber, setRowNumber] = useState(-1);
  const [loadingDeleteData, setLoadingDeleteData] = useState(false);
  const [addToWorkBtn, setAddToWorkBtn] = useState(false);

  const profileId = localStorage.getItem("profileId");
  const [resultRows, setResultRows] = useState();
  const [sortResultsKey, setSortResultsKey] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [oewsNationalM2022Data, setOewsNationalM2022Data] = useState({});
  const [goeDefinations, setGoeDefinations] = useState([]);
  const [naicDefinations, setNaicDefinations] = useState([]);
  const [codeDefinations, setCodeDefinations] = useState([]);
  const [socOnetDefinitions, setSocOnetDefinitions] = useState([]);
  const [alternativeTitles, setAlternativeTitles] = useState([]);
  const DOTTitleViewRef = useRef(null);

  const dispatch = useDispatch();

  var dbCurrentVersion;

  const strengthOrder = {
    S: 1,
    L: 2,
    M: 3,
    H: 4,
    V: 5,
  };

  const sortResults = (sortResultsKeyLoc) => {
    setLoadingSearch(true);
    let sortOrderLocal = "asc";
    if (sortResultsKey === sortResultsKeyLoc) {
      sortOrderLocal = sortOrder === "asc" ? "desc" : "asc";
    }
    setSortResultsKey(sortResultsKeyLoc);
    switch (sortResultsKeyLoc) {
      case "code":
        setResultRows((prev) => {
          return sortOrderLocal === "asc"
            ? prev.sort((a, b) =>
                a.DotCodeFormated.localeCompare(b.DotCodeFormated)
              )
            : prev.sort((a, b) =>
                b.DotCodeFormated.localeCompare(a.DotCodeFormated)
              );
        });
        break;
      case "title":
        setResultRows((prev) => {
          return sortOrderLocal === "asc"
            ? prev.sort((a, b) => a.Title.localeCompare(b.Title))
            : prev.sort((a, b) => b.Title.localeCompare(a.Title));
        });
        break;
      case "svp":
        setResultRows((prev) => {
          return sortOrderLocal === "asc"
            ? prev.sort((a, b) => a.SVP - b.SVP)
            : prev.sort((a, b) => b.SVP - a.SVP);
        });
        break;
      case "physical":
        setResultRows((prev) => {
          const sorted = [...prev].sort((a, b) => {
            const aStrength = strengthOrder[a.Strength] || 0;
            const bStrength = strengthOrder[b.Strength] || 0;
            return sortOrderLocal === "asc"
              ? aStrength - bStrength
              : bStrength - aStrength;
          });
          return sorted;
        });
        break;
      // case "physical":
      //   setResultRows((prev) => {
      //     return sortOrderLocal === "asc"
      //       ? prev.sort((a, b) => a.Strength.localeCompare(b.Strength))
      //       : prev.sort((a, b) => b.Strength.localeCompare(a.Strength));
      //   });
      //   break;
      default:
        break;
    }
    setSortOrder(sortOrderLocal);
    setLoadingSearch(false);
  };

  const onSearchNaice = (item) => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["OewsNationalM2022"], "readonly");
      const objectStore = transaction.objectStore("OewsNationalM2022");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        const result = getData.result.find(
          (row) => row.OccCode.replace("-", "") == item["2018SocCode"]
        );
        setOewsNationalM2022Data(
          result ?? {
            OccCode:
              item["2018SocCode"].slice(0, 2) +
              "-" +
              item["2018SocCode"].slice(2),
          }
        );
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OewsNationalM2022", {
        autoIncrement: true,
      });
    };
  };

  useEffect(() => {
    var openRequestver = window.indexedDB.open(dbName);

    openRequestver.onsuccess = function (event) {
      var dbver = event.target.result;
      var currentVersion = dbver.version;
      dbCurrentVersion = currentVersion;

      // Perform other operations as needed
    };

    openRequestver.onerror = function (event) {
      console.error("Error opening database:", event.target.error);
    };

    // Cleanup function
    return () => {
      openRequestver?.result?.close();
    };
  }, []);

  // Define what happens when an item is selected
  const handleSelect = (itemId) => {
    setDOTTitleViewDisplay(true);
    scrollToDOTTitleView();
  };

  const handleDeselect = (itemId) => {
    setDOTTitleViewDisplay(false);
  };

  const scrollToDOTTitleView = () => {
    if (DOTTitleViewRef.current) {
      DOTTitleViewRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (DOTTitleViewDisplay) {
      scrollToDOTTitleView();
    }
  }, [DOTTitleViewDisplay]);

  // Convert DOT code and create array
  const convertDotCode = (dotCode) => {
    // Remove dots and hyphens and create array
    const formattedDot = dotCode.replace(/[.-]/g, "");
    return { dots: [formattedDot] };
  };

  // Function to toggle select/deselect and call respective functions
  const handleToggleSelect = async (items, itemId) => {
    // Usage in your code
    let empAllData = await handleGetEmploymentEstimateAllDataRequest([
      items?.dotcode?.replace(/[.-]/g, ""),
    ]);

    setValue(0);
    setWorkHistoryRowData(items);
    setWorkHistoryRowDataUpdated(
      empAllData ? empAllData?.data?.empArray[0] : {}
    );
    setWorkField(empAllData ? empAllData?.data?.empArray[0]?.WORKCODE : []);
    setMPSMS(empAllData ? empAllData?.data?.empArray[0]?.MPSMSCODE : []);
    setGOE(empAllData ? empAllData?.data?.empArray[0]?.GOE : "");
    setRIASEC(empAllData ? empAllData?.data?.empArray[0]?.RIASEC : []);
    setSOC(empAllData ? empAllData?.data?.empArray[0]?.SOC : []);
    setONET(empAllData ? empAllData?.data?.empArray[0]?.ONET : []);
    handleSearchDotCode(itemId); // Added by Faisal
    setSelectedItems((prevSelectedItems) => {
      const isSelected = !prevSelectedItems[itemId];
      // Call handleSelect or handleDeselect based on the new state
      if (isSelected) {
        handleSelect(itemId);
        // Deselect all other items
        return { [itemId]: true };
      } else {
        handleDeselect(itemId);
        return { [itemId]: false };
      }
    });
  };
  const [value, setValue] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      const innerHeight = window.innerHeight;
      setWorkHistoryHeight(innerHeight - 177);
      setResultHeight(innerHeight - 218);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [window.innerHeight]); // eslint-disable-line

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formik = useFormik({
    initialValues: {
      code: "",
      title: "",
      svp: "",
      physical: "",
    },
    validate: (data) => {
      let errors = {};
      // Check if the code contains only numbers
      if (data.code && !/^\d{3}\.?\d{3}-?\d{3}$/.test(data.code)) {
        errors.code = "Code must be numeric.";
      }

      if (!data.code && !data.title && !data.svp && !data.physical) {
        const message = "At least one field is required.";
        errors.message = message;
        toast.error("At least one field is required.");
      }

      return errors;
    },
    onSubmit: (data) => {
      setLoadingSearch(true);
      // Process code field to remove non-numeric characters in the copy
      const modifiedData = { ...data };
      if (modifiedData.code) {
        modifiedData.code = modifiedData.code.replace(/\D/g, ""); // Remove all non-numeric characters
      }
      handleSearch(modifiedData);
    },
  });

  const normalizeString = (str) => {
    return str
      .toLowerCase()
      .replace(/,/g, "") // Remove commas
      .replace(/\s+/g, " ") // Replace multiple spaces with a single space
      .trim(); // Remove leading and trailing spaces
  };

  const getAlternativeTitlesDotCode = (searchTerms) => {
    if (searchTerms.length === 1 && searchTerms[0] === "") return [];
    const dotCodes = alternativeTitles
      .filter((row) => {
        const titleCheck = searchTerms.every((term) =>
          normalizeString(row.Title).includes(term)
        );
        return titleCheck;
      })
      ?.map((value) => ({
        code: String(value.DotCode),
        title: String(value.Title),
      }));
    return dotCodes;
  };

  const handleSearch = (data) => {
    // Open the database
    const searchTerms = normalizeString(data?.title || "").split(" ");

    const alternativeDotCode = getAlternativeTitlesDotCode(searchTerms);

    const request = window.indexedDB.open(dbName, dbCurrentVersion);
    request.onerror = function (event) {
      console.error("Database error: " + event.target.errorCode);
    };
    request.onsuccess = function (event) {
      const db = event.target.result;

      // Start a transaction and get the object store
      const transaction = db.transaction(["DOTMasterTable"], "readonly");
      const store = transaction.objectStore("DOTMasterTable");
      const index = store.index("DotCode");

      var partialMatchResults = [];

      index.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor) {
          let value = cursor.value;
          var dotCodeMatch = String(value.DotCode);
          var titleMatch = String(value.Title);
          var descriptionMatch = String(value.DescriptionCombined);
          value["2018SocCode"] = decryptSocCode(value["2018SocCode"]);

          const titleCheck = searchTerms.every((term) =>
            normalizeString(titleMatch).includes(term)
          );

          const code = data?.code.replace(/[-.]/g, "");

          if (
            dotCodeMatch.includes(code) &&
            titleCheck &&
            (titleMatch?.toLowerCase()?.includes(data?.svp?.toLowerCase()) ||
              descriptionMatch
                ?.toLowerCase()
                ?.includes(data?.svp?.toLowerCase())) &&
            descriptionMatch
              ?.toLowerCase()
              ?.includes(data?.physical?.toLowerCase())
          ) {
            partialMatchResults.push(value);
          } else if (
            dotCodeMatch.includes(code) &&
            alternativeDotCode.some((altCode) => altCode.code === dotCodeMatch)
          ) {
            value = {
              ...value,
              Title: alternativeDotCode.find(
                (altCode) => altCode.code === dotCodeMatch
              ).title,
            };
            partialMatchResults.push(value);
          }
          cursor.continue();
        } else {
          setResultRows([]);
          setResultRows(partialMatchResults);
          partialMatchResults = [];
          setLoadingSearch(false);
        }
      };
    };
  };

  const handleEditRow = (code) => {
    setEditableRow(code);
  };

  const handleMonEmpChange = async (code, newValue) => {
    const year = newValue.toString().split("-")[0];
    const month = newValue.toString().split("-")[1];
    setEditableRow(null);
    const data = {
      profileId: code.profileid,
      empmonth: month || "",
      empyear: year || "",
      workHistoryId: code.id,
    };
    if (newValue) {
      const response = await dispatch(
        handlePutRequest(data, `work-history`, true, true)
      );
      if (response.statusCode === 200) {
        getWorkHistoryData();
      }
    }
  };
  const onDragEnd = async (rows) => {
    setRows(rows);

    const items = rows.map((row, index) => {
      return {
        ...row,
        display_order: index + 1,
      };
    });

    await dispatch(
      handlePutRequest(
        { profileId: profileId, workHistories: items },
        `work-history/order`,
        true,
        true
      )
    );
    // setEditableRow(null);
  };

  const handledDeleteWorkHistory = async (data) => {
    setLoadingDeleteData(true);

    const response = await handleDeleteRequest(
      `work-history/?workHistoryId=${data?.id}`,
      true
    );
    if (response?.statusCode === 200) {
      getWorkHistoryData();
      setOpen(false);
      setLoadingDeleteData(false);
    } else {
      setLoadingDeleteData(false);
    }
  };

  const handledAddData = async (row, index = 0) => {
    setRowNumber(index);
    setLoadingAddData(true);
    const data = {
      profileId: profileId,
      dotcode: row.DotCodeFormated?.toString(),
      title: row.Title,
      svp: row.SVP?.toString(),
      physical: row.Strength,
      description: row.DescriptionCombined,
    };

    const response = await postWorkHistory(data);

    if (response?.statusCode === 200) {
      resultRows?.splice(row, 1);
      getWorkHistoryData();
      setLoadingAddData(false);
    } else {
      setLoadingAddData(false);
    }
  };

  const getWorkHistoryData = async () => {
    const response = await handleGetRequest(
      `work-history?&profileId=${profileId}`
    );
    localStorage.setItem("workHistoryData", JSON.stringify(response?.data));
    setRows(response?.data);
  };

  useEffect(() => {
    getWorkHistoryData();
  }, []); // eslint-disable-line

  const handledClear = (e) => {
    e.preventDefault();
    formik.resetForm();
    setResultRows([]);
  };

  const handleClickOpen = async (value) => {
    setOpen(true);
    setWorkHistoryRowData(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Faisal's Work:
  const [tableDatabyDotCode, setTableDatabyDotCode] = useState();
  const [PhysicalDemandData, setPhysicalDemandData] = useState();
  const [enviromentalData, setEnviromentalData] = useState();
  const [DotData, setDotData] = useState();
  const [aptitudeData, setAptitudeData] = useState();
  const [socCodeData, setSocCodeData] = useState();
  const [goeCodeData, setGoeCodeData] = useState();
  const [oapCodeData, setoapCodeData] = useState();
  const [editableValue, setEditableValue] = useState({});
  const [oewsNationalData, setOewsNationalData] = useState({});
  const [selectedNetSoc, setSelectedNetSoc] = useState({});

  const handleInputChange = (row, inputValue) => {
    // Allow only digits and a single '/'
    // Allow only digits and a single '/'
    // Allow only digits and a single '-'
    inputValue = inputValue.replace(/[^0-9-]/g, "");

    // Restrict format to YY-MM
    if (inputValue.length === 2 && !inputValue.includes("-")) {
      inputValue += "-";
    }

    // Limit input length to 5 (YY-MM)
    if (inputValue.length > 5) {
      return;
    }

    // Validate month range
    const [, month] = inputValue.split("-");
    if (month && parseInt(month, 10) > 12) {
      return;
    }

    setEditableValue((prev) => ({ ...prev, [row.dotcode]: inputValue }));
  };

  const onSearchNet = (item) => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["OnnetSocConversions"], "readonly");
      const objectStore = transaction.objectStore("OnnetSocConversions");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        const result = getData.result.find(
          (row) => row.DotCode === item.DotCode
        );
        setSelectedNetSoc(result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OnnetSocConversions", { autoIncrement: true });
    };
  };

  const onSearchOews = (item) => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["OewsNationalM2022"], "readonly");
      const objectStore = transaction.objectStore("OewsNationalM2022");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        setOewsNationalData(getData.result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OewsNationalM2022", { autoIncrement: true });
    };
  };

  const fetchDataAndStore = (storeName, localStorageKey) => {
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = function (event) {
      console.error("Database error: " + event.target.errorCode);
    };

    request.onsuccess = function (event) {
      const db = event.target.result;

      const transaction = db.transaction([storeName], "readonly");
      const store = transaction.objectStore(storeName);

      const allRecords = [];

      store.openCursor().onsuccess = function (event) {
        const cursor = event.target.result;
        if (cursor) {
          allRecords.push(cursor.value);
          cursor.continue();
        } else {
          if (localStorageKey) {
            localStorage.setItem(localStorageKey, JSON.stringify(allRecords));
          }
        }
      };
    };
  };

  useEffect(() => {
    fetchDotTitleIndustryCodes();
  }, []);

  const fetchDotTitleIndustryCodes = () => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["DotTitleIndustrycodes"], "readonly");
      const objectStore = transaction.objectStore("DotTitleIndustrycodes");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        setAlternativeTitles(getData.result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("2010To2018Soc", {
        autoIncrement: true,
      });
    };
  };

  useEffect(() => {
    const storeMappings = [
      { storeName: "DotPhysicalDemands", localStorageKey: "PhysicalDemands" },
      {
        storeName: "EnvironmentalDemands",
        localStorageKey: "EnvironmentalDemands",
      },
      {
        storeName: "EnvironmentalDemandLevel",
        localStorageKey: "EnvironmentalDemandLevel",
      },
      { storeName: "DotGeds", localStorageKey: "DotGeds" },
      { storeName: "DotGedsLevels", localStorageKey: "DotGedsLevels" },
      { storeName: "Aptitudes", localStorageKey: "Aptitudes" },
    ];

    storeMappings.forEach(({ storeName, localStorageKey }) => {
      fetchDataAndStore(storeName, localStorageKey);
    });
  }, []);

  var PhysicalDemandsData = JSON.parse(localStorage.getItem("PhysicalDemands"));
  var EnvironmentalDemandsData = JSON.parse(
    localStorage.getItem("EnvironmentalDemands")
  );
  var EnvironmentalDemandLevelData = JSON.parse(
    localStorage.getItem("EnvironmentalDemandLevel")
  );
  var DotGedsData = JSON.parse(localStorage.getItem("DotGeds"));
  var AptitudesData = JSON.parse(localStorage.getItem("Aptitudes"));

  const handleSearchDotCode = (dotcodeFind) => {
    // Open the database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);
    request.onerror = function (event) {
      console.error("Database error: " + event.target.errorCode);
    };
    request.onsuccess = function (event) {
      const db = event.target.result;

      // Start a transaction and get the object store
      const transaction = db.transaction(["DOTMasterTable"], "readonly");

      const store = transaction.objectStore("DOTMasterTable");
      const index = store.index("DotCode");

      var partialMatchResults = [];

      // Open a cursor on the index
      index.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor) {
          const value = cursor.value;
          let dotCodeMatch = String(value.DotCode);
          let dotCodeFormattedMatch = String(value.DotCodeFormated);
          value["2018SocCode"] = decryptSocCode(value["2018SocCode"]);

          // Check if the dot_code contains the substring 'part_of_dot_code'
          if (dotCodeMatch.includes(dotcodeFind)) {
            partialMatchResults.push(value);
          }
          if (dotCodeFormattedMatch.includes(dotcodeFind)) {
            partialMatchResults.push(value);
          }
          cursor.continue();
        } else {
          setTableDatabyDotCode(partialMatchResults[0]);
          matchedRecordsPhysical(partialMatchResults);
          matchedRecordsEnvironmental(partialMatchResults);
          matchedRecordsGEDs(partialMatchResults);
          matchedRecordsAptitudes(partialMatchResults);
          matchedRecordsCodes(partialMatchResults);
          onSearchOews(partialMatchResults[0]);
          onSearchNet(partialMatchResults[0]);
          onSearchNaice(partialMatchResults[0]);
          partialMatchResults = [];
        }
      };
    };
  };

  const matchedRecordsPhysical = (partialMatchResults) => {
    var resultPhys = [];
    for (var i = 0; i < PhysicalDemandsData?.length; i++) {
      var CombinePhysSlug = `Phys${+(i + 1)}`;
      if (partialMatchResults[0])
        var PhysDemandValue = partialMatchResults[0][CombinePhysSlug];
      var outputPhys = {
        name: PhysicalDemandsData[i]?.Name,
        value: PhysDemandValue,
      };
      resultPhys.push(outputPhys);
    }
    setPhysicalDemandData(resultPhys);
  };

  const matchedRecordsEnvironmental = (partialMatchResults) => {
    var result = [];
    for (var i = 0; i < EnvironmentalDemandsData?.length; i++) {
      if (EnvironmentalDemandsData[i].IsMultiLevel) {
        var CombineEnvSlug = `Env${i + 1}`;
        if (partialMatchResults[0])
          var envDemandValue = partialMatchResults[0][CombineEnvSlug] - 1;

        var envDemandLevels = EnvironmentalDemandLevelData.filter(
          (row) => row.EnvironmentalDemandId === EnvironmentalDemandsData[i].Id
        );
        var output = {
          name: EnvironmentalDemandsData[i]?.Description,
          value: envDemandLevels[envDemandValue]?.Description,
        };
        result.push(output);
      } else {
        var CombineEnvSlug = `Env${+(i + 1)}`;
        if (partialMatchResults[0])
          var demandValue = partialMatchResults[0][CombineEnvSlug];

        var output = {
          name: EnvironmentalDemandsData[i]?.Description,
          value: demandValue,
        };
        result.push(output);
      }
    }
    setEnviromentalData(result);
  };

  const matchedRecordsGEDs = (partialMatchResults) => {
    var result = [];
    for (var i = 0; i < DotGedsData?.length; i++) {
      if (i == 0) {
        var reasoningLevel = partialMatchResults[0]?.GedG;
        var outputGEDs = {
          name: DotGedsData[0]?.Name,
          value: reasoningLevel,
        };
        result.push(outputGEDs);
      } else if (i == 1) {
        // GedE mean math
        var mathLevel = partialMatchResults[0].GedE;
        var outputGEDs = {
          name: DotGedsData[1]?.Name,
          value: mathLevel,
        };
        result.push(outputGEDs);
      } else if (i == 2) {
        // GedD mean Language
        var languageLevel = partialMatchResults[0].GedD;
        var outputGEDs = {
          name: DotGedsData[2]?.Name,
          value: languageLevel,
        };
        result.push(outputGEDs);
      } else {
        break;
      }
    }
    setDotData(result);
  };

  const matchedRecordsAptitudes = (partialMatchResults) => {
    var result = [];
    for (var i = 0; i < AptitudesData?.length; i++) {
      var demandAptChar = AptitudesData[i].ShortCharacter;
      var demandAptDesc = AptitudesData[i].Description;
      var combinedSlugApt = `Apt${demandAptChar}`;
      var demndValueApt = partialMatchResults[0][combinedSlugApt];

      var outputAptitude = {
        description: demandAptDesc,
        value: demndValueApt,
      };
      result.push(outputAptitude);
    }
    setAptitudeData(result);
  };

  const matchedRecordsCodes = (partialMatchResults) => {
    const [socCode, goeCode, oapCode] = [
      partialMatchResults[0].SocCode,
      partialMatchResults[0].GoeCode,
      partialMatchResults[0].OapCode,
    ];

    let socCodeResult = [];
    let goeCodeResult = [];
    let oapCodeResult = [];

    const handleRequestSuccess =
      (storeName, matchCode, resultArray, setResultData) => (event) => {
        const db = event.target.result;
        const transaction = db.transaction([storeName], "readonly");
        const store = transaction.objectStore(storeName);

        store.openCursor().onsuccess = function (event) {
          const cursor = event.target.result;
          if (cursor) {
            const codeMatch = String(cursor.value.Code);
            if (codeMatch == matchCode) {
              const output = {
                type: `${storeName.split("Code")[0].toUpperCase()}-${
                  cursor.value.Type
                }`,
                code: codeMatch,
                description: cursor.value.Text,
              };
              resultArray.push(output);
            }
            cursor.continue();
          } else {
            setResultData(resultArray);
          }
        };
      };

    const openDatabase = (storeName, matchCode, resultArray, setResultData) => {
      const request = window.indexedDB.open(dbName, dbCurrentVersion);
      request.onerror = (event) => {
        console.error("Database error: " + event.target.errorCode);
      };
      request.onsuccess = handleRequestSuccess(
        storeName,
        matchCode,
        resultArray,
        setResultData
      );
    };

    openDatabase("OesCodeDefination", socCode, socCodeResult, setSocCodeData);
    openDatabase("OesCodeDefination", goeCode, goeCodeResult, setGoeCodeData);
    openDatabase("OapCodeDefinations", oapCode, oapCodeResult, setoapCodeData);
  };

  useEffect(() => {
    fetch("GoeDefinations.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setGoeDefinations(data);
      return;
    });
    fetch("socOnetDefinitions.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSocOnetDefinitions(data);
      return;
    });
    fetch("LongDefinations.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCodeDefinations(data);
      return;
    });
    fetch("niacDefinitionsV2.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setNaicDefinations(data);
      return;
    });
  }, []);

  const getGoeTitle = (goeCode) => {
    const formattedGoeCode = "0" + goeCode;
    const filteredGoe = goeDefinations?.find((goe) => {
      return goe.SocCode === formattedGoeCode;
    });
    return filteredGoe ? filteredGoe.GoeTitle : "-";
  };
  const getOnetTitle = (OnnetCode) => {
    const formattedGoeCode = OnnetCode;
    const filteredOnet = socOnetDefinitions?.find((onet) => {
      return onet.ONET_SOC_C_8 === formattedGoeCode;
    });
    return filteredOnet ? filteredOnet.LayTitle_c_128 : "-";
  };

  const getSocCode = () => {
    return oewsNationalM2022Data.OccCode;
  };

  const getSocTitle = () => {
    return oewsNationalM2022Data.OccTitle;
  };

  return (
    <>
      <Container
        maxWidth={false}
        sx={{ paddingTop: "80px", paddingBottom: 2, px: { xs: "10px" } }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box className="bg-white" mb={1} padding={2} borderRadius={"10px"}>
            <Stack direction={"row"} gap={2}>
              <Box flexGrow={1}>
                <TextField
                  name="code"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  hiddenLabel
                  fullWidth
                  size="small"
                  variant="filled"
                  placeholder="Code"
                />
              </Box>
              <Box flexGrow={1}>
                <TextField
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  hiddenLabel
                  fullWidth
                  size="small"
                  variant="filled"
                  placeholder="Title"
                />
              </Box>
              <Box flexGrow={1}>
                <TextField
                  hiddenLabel
                  fullWidth
                  size="small"
                  variant="filled"
                  placeholder="Keywords"
                  name="svp"
                  value={formik.values.svp}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box flexGrow={1}>
                <TextField
                  hiddenLabel
                  fullWidth
                  size="small"
                  variant="filled"
                  placeholder="Description"
                  name="physical"
                  value={formik.values.physical}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<ClearIcon />}
                  onClick={handledClear}
                >
                  Clear
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  startIcon={<SearchIcon />}
                  type="submit"
                  disabled={loadingSearch}
                >
                  Search
                </Button>
              </Box>
            </Stack>
          </Box>
        </form>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            {/* <Box  py={4}> */}
            <Box overflow={"auto"} maxHeight={`${workHistoryHeight}px`}>
              <Box className="bg-white" padding={1} borderRadius={"10px"}>
                <Typography variant="h6">Work History</Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    paddingBottom: 0,
                    paddingTop: 0,
                    height: "auto",
                    boxShadow: "none",
                  }}
                >
                  <div>
                    <DraggableTableRows
                      {...{
                        rows,
                        onDragEnd,
                        handleMonEmpChange,
                        editableRow,
                        editableValue,
                        handleInputChange,
                        handleEditRow,
                        selectedItems,
                        handleToggleSelect,
                        setAddToWorkBtn,
                        handleClickOpen,
                      }}
                    />
                  </div>
                </TableContainer>
              </Box>
              {DOTTitleViewDisplay && (
                <Box
                  className="bg-white"
                  padding={1}
                  borderRadius={"10px"}
                  marginTop={"10px"}
                  height={"auto"}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{ width: "100%" }}
                    ref={DOTTitleViewRef}
                  >
                    <Typography variant="h6">DOT Title View</Typography>
                    <Box>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="none"
                        sx={{
                          "& .MuiTabs-flexContainer": {
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: 2,
                          },
                        }}
                      >
                        <Tab
                          label="Description"
                          icon={<DescriptionIcon />}
                          iconPosition="start"
                          {...a11yProps(0)}
                          sx={{
                            color:
                              value === 0
                                ? "#ffffff !important"
                                : "#666672 !important",
                            backgroundColor:
                              value === 0 ? "#0089D7" : "#F5F5F5",
                            minHeight: "20px",
                            paddingX: "10px",
                            paddingY: "8px",
                            alignItems: "end",
                          }}
                        />
                        <Tab
                          label="Requirements"
                          {...a11yProps(1)}
                          icon={<RequirementsIcon />}
                          iconPosition="start"
                          sx={{
                            color:
                              value === 1
                                ? "#ffffff !important"
                                : "#666672 !important",
                            backgroundColor:
                              value === 1 ? "#0089D7" : "#F5F5F5",
                            minHeight: "20px",
                            paddingX: "10px",
                            paddingY: "8px",
                            alignItems: "end",
                          }}
                        />
                        <Tab
                          label="Codes"
                          icon={<CodeIcon />}
                          iconPosition="start"
                          {...a11yProps(2)}
                          sx={{
                            color:
                              value === 2
                                ? "#ffffff !important"
                                : "#666672 !important",
                            backgroundColor:
                              value === 2 ? "#0089D7" : "#F5F5F5",
                            minHeight: "20px",
                            paddingX: "10px",
                            paddingY: "8px",
                            alignItems: "end",
                          }}
                        />
                        <a
                          href={`/dotDetail?dotCode=${
                            workHistoryRowData?.DotCode ||
                            workHistoryRowData?.dotcode
                          }`}
                          target="_blank"
                        >
                          {/* <button
                            style={{
                              backgroundColor: "#F5F5F5",
                              minHeight: "20px",
                              padding: "10px 8px",
                              display: "flex",
                              alignItems: "end",
                              borderRadius: 10,
                              color: "#666672",
                              cursor: "alias",
                            }}
                          >
                            Link
                          </button> */}
                          <button
                            style={{
                              backgroundColor: "#F5F5F5",
                              minHeight: "20px",
                              padding: "10px 8px",
                              display: "flex",
                              alignItems: "center", // Changed from "end" to "center" for better alignment
                              gap: "4px", // Add space between icon and text
                              borderRadius: 10,
                              color: "#666672",
                              cursor: "alias",
                            }}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V16M19 3H14M19 3V8M19 3L8.5 13.5"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Tab
                          </button>
                        </a>
                      </Tabs>
                    </Box>
                  </Stack>

                  <TabPanel value={value} index={0}>
                    <Box
                      sx={{
                        position: "relative",
                      }}
                    >
                      <Stack
                        direction={"row"}
                        my={1}
                        gap={1}
                        alignItems={"center"}
                      >
                        <Box flexGrow={1}>
                          <Typography
                            variant="p"
                            component={"p"}
                            color={"primary"}
                          >
                            {workHistoryRowData?.Title ||
                              workHistoryRowData?.title}
                          </Typography>
                        </Box>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                        >
                          <Typography
                            variant="p"
                            component={"p"}
                            color={"text.grey"}
                            sx={{ display: "flex" }}
                          >
                            <CodeIcon />
                            Code
                          </Typography>
                          <Typography
                            variant="h6"
                            component={"h6"}
                            fontSize={"0.875rem"}
                          >
                            {workHistoryRowData?.DotCodeFormated ||
                              workHistoryRowData?.dotcode}
                          </Typography>
                        </Stack>
                      </Stack>

                      <Typography
                        variant="p"
                        component={"p"}
                        color={"#484957"}
                        paddingBottom={"10px"}
                      >
                        {formatString(
                          workHistoryRowData?.DescriptionCombined ||
                            workHistoryRowData?.description
                        )}
                      </Typography>
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <RequirementsTab
                      rowData={workHistoryRowData}
                      rowDataUpdated={workHistoryRowDataUpdated}
                      DotData={DotData}
                      PhysicalDemandData={PhysicalDemandData}
                      dbCurrentVersion={dbCurrentVersion}
                      enviromentalData={enviromentalData}
                      tableDatabyDotCode={tableDatabyDotCode}
                      aptitudeData={aptitudeData}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Box
                      sx={{
                        position: "relative",
                      }}
                    >
                      <Stack
                        direction={"row"}
                        my={1}
                        gap={1}
                        alignItems={"center"}
                      >
                        <Box flexGrow={1}>
                          <Typography
                            variant="p"
                            component={"p"}
                            color={"primary"}
                          >
                            {workHistoryRowData?.Title ||
                              workHistoryRowData?.title}
                          </Typography>
                        </Box>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                        >
                          <Typography
                            variant="p"
                            component={"p"}
                            color={"text.grey"}
                            sx={{ display: "flex" }}
                          >
                            <CodeIcon />
                            Code
                          </Typography>
                          <Typography
                            variant="h6"
                            component={"h6"}
                            fontSize={"0.875rem"}
                          >
                            {workHistoryRowData?.DotCodeFormated ||
                              workHistoryRowData?.dotcode}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        sx={{
                          borderBottom: "1px solid #666672",
                          padding: "10px",
                        }}
                      >
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"primary"}
                          fontWeight={"bold"}
                          fontSize={"15px"}
                          width={"20%"}
                        >
                          Type
                        </Typography>

                        <Typography
                          variant="p"
                          component={"p"}
                          color={"primary"}
                          fontWeight={"bold"}
                          fontSize={"15px"}
                          width={"20%"}
                        >
                          Code
                        </Typography>
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"primary"}
                          fontWeight={"bold"}
                          fontSize={"15px"}
                          width={"20%"}
                        >
                          Definitions
                        </Typography>
                      </Stack>
                      <Box
                        borderRadius={"10px"}
                        backgroundColor={"#F5F5F5"}
                        marginBottom={"5px"}
                      >
                        <Stack
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          sx={{
                            paddingTop: "10px",
                            paddingX: "10px",
                            paddingBottom: "5px",
                            backgroundColor: "#eaeaea",
                            borderRadius: "10px 10px 0px 0px",
                          }}
                          className="hover:bg-white"
                        >
                          <Typography variant="p" component={"p"} width={"20%"}>
                            Work Fields
                          </Typography>
                          <Stack
                            width={"80%"}
                            direction={"column"}
                            gap={"20px"}
                          >
                            {/* {codeDefinations?.map((item, index) => {
                              return (
                                item.TypeName === "Work Fields" &&
                                (() => {
                                  const isItemPresent = splitIntoTwo(
                                    tableDatabyDotCode?.WorkFields
                                  )?.includes(item?.Code);
                                  return isItemPresent ? (
                                    <Stack direction={"row"}>
                                      <Typography
                                        variant="p"
                                        component={"p"}
                                        width={"25%"}
                                      >
                                        {item?.Code}
                                      </Typography>
                                      <Typography
                                        variant="p"
                                        component={"p"}
                                        width={"75%"}
                                      >
                                        {item?.LongDefination}
                                      </Typography>
                                    </Stack>
                                  ) : (
                                    <></>
                                  );
                                })()
                              );
                            })} */}
                            {workField?.length > 0 &&
                              workField?.map((item, index) => {
                                return (
                                  <Stack direction={"row"}>
                                    <Typography
                                      variant="p"
                                      component={"p"}
                                      width={"25%"}
                                    >
                                      {item?.code}
                                    </Typography>
                                    <Typography
                                      variant="p"
                                      component={"p"}
                                      width={"75%"}
                                    >
                                      {item?.title}
                                    </Typography>
                                  </Stack>
                                );
                              })}
                          </Stack>
                        </Stack>
                        <Stack
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          sx={{
                            paddingTop: "10px",
                            paddingX: "10px",
                            paddingBottom: "5px",
                          }}
                          className="hover:bg-white"
                        >
                          <Typography variant="p" component={"p"} width={"20%"}>
                            MPSMS
                          </Typography>
                          <Stack
                            width={"80%"}
                            direction={"column"}
                            gap={"20px"}
                          >
                            {/* {codeDefinations?.map((item, index) => {
                              return (
                                item?.Code === tableDatabyDotCode?.MPSMS &&
                                (() => {
                                  const isItemPresent =
                                    item.LongDefination !== "NULL";
                                  return isItemPresent ? (
                                    <Stack direction={"row"}>
                                      <Typography
                                        variant="p"
                                        component={"p"}
                                        width={"25%"}
                                      >
                                        {item?.Code}
                                      </Typography>
                                      <Typography
                                        variant="p"
                                        component={"p"}
                                        width={"75%"}
                                      >
                                        {item?.LongDefination}
                                      </Typography>
                                    </Stack>
                                  ) : (
                                    <></>
                                  );
                                })()
                              );
                            })} */}
                            {MPSMS?.length > 0 &&
                              MPSMS?.map((item, index) => (
                                <Stack direction={"row"} key={index}>
                                  <Typography
                                    variant="p"
                                    component={"p"}
                                    width={"25%"}
                                  >
                                    {item?.code}
                                  </Typography>
                                  <Typography
                                    variant="p"
                                    component={"p"}
                                    width={"75%"}
                                  >
                                    {item?.title}
                                  </Typography>
                                </Stack>
                              ))}
                          </Stack>
                        </Stack>
                        <Stack
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          sx={{
                            paddingTop: "10px",
                            paddingX: "10px",
                            paddingBottom: "5px",
                            backgroundColor: "#eaeaea",
                          }}
                          className="hover:bg-white"
                        >
                          <Typography variant="p" component={"p"} width={"20%"}>
                            GOE
                          </Typography>
                          <Typography variant="p" component={"p"} width={"20%"}>
                            {GOE?.code}
                          </Typography>
                          <Typography variant="p" component={"p"}>
                            {/* {getGoeTitle(tableDatabyDotCode?.GoeCode)
                              .split(":")[1]
                              ?.trim()} */}
                            {GOE?.title}
                          </Typography>
                        </Stack>
                        <Stack
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          sx={{
                            paddingTop: "10px",
                            paddingX: "10px",
                            paddingBottom: "5px",
                          }}
                          className="hover:bg-white"
                        >
                          <Typography variant="p" component={"p"} width={"20%"}>
                            RIASEC
                          </Typography>
                          <Box width={"80%"}>
                            {codeDefinations?.map((item, index) => {
                              const riasecArray =
                                tableDatabyDotCode?.RIASEC?.split("") || [];
                              return (
                                riasecArray.includes(item?.Code) &&
                                item?.TypeName === "RIASEC" && (
                                  <Stack direction={"row"} mb={"5px"}>
                                    <Typography
                                      width={"25%"}
                                      key={index}
                                      variant="p"
                                      component={"p"}
                                      style={{ display: "block" }}
                                    >
                                      {item.Code}
                                    </Typography>

                                    <Typography
                                      className="flex flex-col gap-2"
                                      variant="p"
                                      component={"p"}
                                      width={"75%"}
                                    >
                                      <span key={index}>
                                        {item?.LongDefination}
                                      </span>
                                    </Typography>
                                  </Stack>
                                )
                              );
                            })}
                          </Box>
                        </Stack>

                        <Stack
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          sx={{
                            paddingTop: "10px",
                            paddingX: "10px",
                            paddingBottom: "5px",
                            backgroundColor: "#eaeaea",
                          }}
                          className="hover:bg-white"
                        >
                          <Typography variant="p" component={"p"} width={"20%"}>
                            SOC Code
                          </Typography>
                          {SOC?.length > 0 &&
                            SOC.map((item, index) => {
                              return (
                                <>
                                  <Typography
                                    variant="p"
                                    component={"p"}
                                    width={"20%"}
                                  >
                                    {item?.code &&
                                      item?.code?.slice(0, 2) +
                                        "-" +
                                        item?.code?.slice(2)}
                                  </Typography>
                                  <Typography
                                    variant="p"
                                    component={"p"}
                                    width={"20%"}
                                  >
                                    {item?.title && item?.title}
                                  </Typography>
                                </>
                              );
                            })}
                        </Stack>

                        <Stack
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          sx={{
                            paddingTop: "10px",
                            paddingX: "10px",
                            paddingBottom: "5px",
                          }}
                          className="hover:bg-white"
                        >
                          <Typography variant="p" component={"p"} width={"20%"}>
                            O*NET
                          </Typography>
                          {ONET?.length > 0 &&
                            ONET.map((item, index) => {
                              return (
                                <>
                                  <Typography
                                    variant="p"
                                    component={"p"}
                                    width={"20%"}
                                  >
                                    {item?.code}
                                  </Typography>
                                  <Typography
                                    variant="p"
                                    component={"p"}
                                    width={"20%"}
                                  >
                                    {item?.title}
                                  </Typography>
                                </>
                              );
                            })}
                        </Stack>
                      </Box>
                    </Box>
                  </TabPanel>
                  {addToWorkBtn && (
                    <Box pt={1} textAlign={"right"}>
                      <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        disabled={loadingAddData}
                        onClick={() => handledAddData(workHistoryRowData)}
                      >
                        Add to Work History
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box
              className="bg-white"
              padding={1}
              borderRadius={"10px"}
              position={"relative"}
            >
              <Typography variant="h6">Results</Typography>
              {loadingSearch && (
                <Stack
                  sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 4,
                    backgroundColor: "#95959512",
                    borderRadius: "10px",
                  }}
                >
                  <CircularProgress />
                </Stack>
              )}

              <TableContainer
                component={Paper}
                sx={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  overflow: "scroll",
                  height: `${resultHeight}px`,
                  boxShadow: "none",
                }}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <CustomTableCell
                        align="left"
                        label="Code"
                        sortResultsKey="code"
                        sortOrder={sortOrder}
                        currentSortKey={sortResultsKey}
                        onSort={sortResults}
                        sortKey="code"
                      />
                      <CustomTableCell
                        align="left"
                        label="Title"
                        sortResultsKey="title"
                        sortOrder={sortOrder}
                        currentSortKey={sortResultsKey}
                        onSort={sortResults}
                        sortKey="title"
                      />
                      <CustomTableCell
                        align="left"
                        label="SVP"
                        sortResultsKey="svp"
                        sortOrder={sortOrder}
                        currentSortKey={sortResultsKey}
                        onSort={sortResults}
                        sortKey="svp"
                      />
                      <CustomTableCell
                        align="left"
                        label="Physical"
                        sortResultsKey="physical"
                        sortOrder={sortOrder}
                        currentSortKey={sortResultsKey}
                        onSort={sortResults}
                        sortKey="physical"
                      />
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resultRows?.map((row, index) => {
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "& td, & th": { paddingY: "4px !important" },
                            "&:hover": { backgroundColor: "#E6F3FB66" },
                          }}
                          key={row?.DotCodeFormated}
                        >
                          <TableCell component="th" scope="row">
                            <span className="whitespace-nowrap">
                              {row?.DotCodeFormated}
                            </span>
                          </TableCell>
                          <TableCell align="left">{row?.Title}</TableCell>
                          <TableCell align="left">{row?.SVP}</TableCell>
                          <TableCell align="left">{row?.Strength}</TableCell>
                          <TableCell align="right">
                            <Stack
                              direction={"row"}
                              spacing={1}
                              sx={{ justifyContent: "end" }}
                            >
                              <div
                                className="AddIconButton"
                                style={{
                                  cursor: loadingAddData
                                    ? "not-allowed"
                                    : "pointer",
                                  padding: 5,
                                  borderRadius: 5,
                                }}
                                onClick={() =>
                                  !loadingAddData && handledAddData(row, index)
                                }
                              >
                                {loadingAddData && rowNumber === index ? (
                                  <CircularProgress
                                    style={{ width: 25, height: 25 }}
                                  />
                                ) : (
                                  <SvgIcon component={AddIcon} inheritViewBox />
                                )}
                              </div>
                              <div
                                className={`${
                                  selectedItems[row.DotCode]
                                    ? "EyeIconOffButton"
                                    : "EyeIconButton"
                                }`}
                                style={{
                                  cursor: "pointer",
                                  border: 0,
                                  padding: 5,
                                  borderRadius: 5,
                                }}
                                onClick={() => {
                                  handleToggleSelect(row, row.DotCode);
                                  setAddToWorkBtn(true);
                                }}
                              >
                                {selectedItems[row.DotCode] ? (
                                  <SvgIcon
                                    component={EyeIconOff}
                                    inheritViewBox
                                  />
                                ) : (
                                  <SvgIcon component={EyeIcon} inheritViewBox />
                                )}
                              </div>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{ sx: { width: "25%", borderRadius: "25px" } }}
        >
          <DialogContent sx={{ padding: 3 }}>
            <Stack gap={1} alignItems={"center"}>
              <Box>
                <img src={DeleteModelIcon} alt="" />
              </Box>
              <Typography variant="p" paddingTop={1}>
                Are you sure to delete ?
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ padding: 3, paddingTop: 0, gap: 2 }}>
            <Box flexGrow={1}>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                color="error"
                onClick={() => handledDeleteWorkHistory(workHistoryRowData)}
                disabled={loadingDeleteData}
              >
                Delete
              </Button>
            </Box>
            <Box flexGrow={1}>
              <Button
                color="grey"
                variant="contained"
                sx={{ width: "100%" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Fragment>
    </>
  );
};

export default WorkHistory;
