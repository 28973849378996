
import React from 'react';
import { Stack, Typography } from '@mui/material';

const ORSInfoRow = ({ label, value }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography
        variant="span"
        component={"span"}
        color={"text.grey"}
        marginTop={"10px"}
      >
        {label}
      </Typography>
      <Typography
        variant="span"
        component={"span"}
        marginTop={"10px"}
      >
        {value}
      </Typography>
    </Stack>
  );
};

export default ORSInfoRow;