import { ReactComponent as DeleteIcon } from "../../assets/images/icons/delet-icon.svg";
import { ReactComponent as EyeIcon } from "../../assets/images/icons/eye-icon.svg";
import { ReactComponent as EyeIconOff } from "../../assets/images/icons/eye-off-icon.svg";
import DragIcon from "../../assets/images/icons/dots.png";
import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Table,
  TableRow,
  TableCell,
  TextField,
  Stack,
  ToggleButton,
  IconButton,
  SvgIcon,
  TableHead,
  TableBody,
} from "@mui/material";

const DraggableTableRows = ({
  rows,
  handleMonEmpChange,
  editableRow,
  editableValue,
  handleInputChange,
  handleEditRow,
  selectedItems,
  handleToggleSelect,
  setAddToWorkBtn,
  handleClickOpen,
  onDragEnd,
}) => {
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(rows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onDragEnd(items);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="tableRows">
        {(provided) => (
          <Table {...provided.droppableProps} ref={provided.innerRef}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Drag</TableCell>
                <TableCell align="left">Code</TableCell>
                <TableCell align="left"><div className="w-[120px]">Title</div></TableCell>
                <TableCell align="left">SVP</TableCell>
                <TableCell align="left">Physical</TableCell>
                <TableCell align="left">Months Employed</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => (
                <Draggable
                  key={row?.id}
                  draggableId={row?.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <TableRow
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "& td, & th": { paddingY: "4px !important" },
                        "&:hover": { backgroundColor: "#E6F3FB66" },
                      }}
                    >
                      <TableCell
                        {...provided.dragHandleProps}
                        sx={{ width: "20px", cursor: "grab" }}
                      >
                        <img src={DragIcon} alt="drag" style={{width: 20}} />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <span className="whitespace-nowrap">{row.dotcode}</span>
                      </TableCell>
                      <TableCell align="left">{row.title}</TableCell>
                      <TableCell align="left">{row.svp}</TableCell>
                      <TableCell align="left">{row.physical}</TableCell>
                      <TableCell align="left">
                        {editableRow === (row.dotcode || row.DotCode)? (
                          <TextField
                            hiddenLabel
                            size="small"
                            variant="filled"
                            placeholder=""
                            onBlur={(e) =>
                              handleMonEmpChange(row, e.target.value)
                            }
                            value={editableValue[row.dotcode] ?? row.empmonth}
                            onChange={(e) =>
                              handleInputChange(row, e.target.value)
                            }
                            autoFocus
                            sx={{ py: 1, maxWidth: "60px", width: "100%" }}
                            InputProps={{
                              className: "monEmp__input",
                              style: { textAlign: "center" },
                            }}
                          />
                        ) : (
                          <div
                            onClick={() => handleEditRow(row.dotcode)}
                            className="cursor-pointer"
                          >
                            {row.empmonth || 0}
                          </div>
                        )}
                      </TableCell>

                      <TableCell align="right">
                      <Stack
                        direction={"row"}
                        spacing={1}
                        sx={{ justifyContent: "end" }}
                      >
                        <ToggleButton
                          size="small"
                          value="check"
                          className={`${
                            selectedItems[row.dotcode]
                              ? "EyeIconOffButton"
                              : "EyeIconButton"
                          }`}
                          sx={{ border: 0 }}
                          selected={!!selectedItems[row.dotcode]} // Use double negation to ensure boolean
                          onChange={() => {
                            handleToggleSelect(row, row.dotcode);
                            setAddToWorkBtn(false);
                          }}
                        >
                          {selectedItems[row.dotcode] ? (
                            <SvgIcon component={EyeIconOff} inheritViewBox />
                          ) : (
                            <SvgIcon component={EyeIcon} inheritViewBox />
                          )}
                        </ToggleButton>
                        <IconButton
                          size="small"
                          className="DeleteIconButton"
                          sx={{ padding: "7px" }}
                          onClick={() => handleClickOpen(row)}
                        >
                          <SvgIcon component={DeleteIcon} inheritViewBox />
                        </IconButton>
                      </Stack>
                    </TableCell>
                    </TableRow>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </TableBody>
          </Table>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableTableRows;
