import {
  Box,
  Container,
  Stack,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Paper,
} from "@mui/material";
import { ReactComponent as RightArrowIcon } from "../../assets/images/icons/right-arrow-icon-Large.svg";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Requirements } from "./helperComponents/requirements";
import { CodeTab } from "./helperComponents/codeTab";
import { formatString } from "./utils";

const HypotheticalToolDOTExpandedView = () => {
  const [oewsNationalData, setOewsNationalData] = useState({});
  const [employEstimate, setEmployEstimate] = useState(null);
  const location = useLocation();
  const rowData = location.state || {};
  localStorage.removeItem("row");
  localStorage.setItem("row", JSON.stringify(rowData));
  var dbName = "veToolkitDB";
  var dbCurrentVersion;
  useEffect(() => {
    var openRequestver = window.indexedDB.open(dbName);

    openRequestver.onsuccess = function (event) {
      var dbver = event.target.result;
      var currentVersion = dbver.version;
      dbCurrentVersion = currentVersion;

      // Perform other operations as needed
      // Start a transaction to access the object store
      const transaction = dbver.transaction(["OewsNationalM2022"], "readonly");
      const objectStore = transaction.objectStore("OewsNationalM2022");

      // Retrieve data from the object store
      const getData = objectStore.getAll();
      getData.onsuccess = () => {
        // Update state with the retrieved data
        const idToMatch = rowData["2018SocCode"]; // this data is coming from the DOT search API
        const filteredOews = getData.result?.find((value) => {
          return value.OccCode === idToMatch;
        });
        // setOewsNationalM2022Data(filteredOews);
        setOewsNationalData(filteredOews);
      };
    };

    openRequestver.onerror = function (event) {
      console.error("Error opening database:", event.target.error);
    };

    // Cleanup function
    return () => {
      openRequestver?.result?.close();
    };
  }, []);
  const [socCodeData, setSocCodeData] = useState();
  const [goeCodeData, setGoeCodeData] = useState();
  const [oapCodeData, setoapCodeData] = useState();

  useEffect(() => {
    matchedRecordsCodes(rowData);
  }, []);
 const matchedRecordsCodes = (partialMatchResults) => {
    const [socCode, goeCode, oapCode] = [
      partialMatchResults.SocCode,
      partialMatchResults.GoeCode,
      partialMatchResults.OapCode,
    ];

    let socCodeResult = [];
    let goeCodeResult = [];
    let oapCodeResult = [];

    const handleRequestSuccess =
      (storeName, matchCode, resultArray, setResultData) => (event) => {
        const db = event.target.result;
        const transaction = db.transaction([storeName], "readonly");
        const store = transaction.objectStore(storeName);

        store.openCursor().onsuccess = function (event) {
          const cursor = event.target.result;
          if (cursor) {
            const codeMatch = String(cursor.value.Code);
            if (codeMatch == matchCode) {
              const output = {
                type: `${storeName.split("Code")[0].toUpperCase()}-${
                  cursor.value.Type
                }`,
                code: codeMatch,
                description: cursor.value.Text,
              };
              resultArray.push(output);
            }
            cursor.continue();
          } else {
            setResultData(resultArray);
          }
        };
      };

    const openDatabase = (storeName, matchCode, resultArray, setResultData) => {
      const request = window.indexedDB.open(dbName, dbCurrentVersion);
      request.onerror = (event) => {
        console.error("Database error: " + event.target.errorCode);
      };
      request.onsuccess = handleRequestSuccess(
        storeName,
        matchCode,
        resultArray,
        setResultData
      );
    };

    openDatabase("OesCodeDefination", socCode, socCodeResult, setSocCodeData);
    openDatabase("OesCodeDefination", goeCode, goeCodeResult, setGoeCodeData);
    openDatabase("OapCodeDefinations", oapCode, oapCodeResult, setoapCodeData);
  };

  return (
    <>
      <Container
        maxWidth
        sx={{ paddingTop: "80px", paddingBottom: 2, px: { xs: "10px" } }}
      >
        <Grid container spacing={"10px"}>
          <Grid item xs={8}>
            <Stack
              spacing={1}
              className="bg-white"
              padding={1}
              borderRadius={"10px"}
            >
              <Box sx={{ backgroundColor: "#F5F5F5" }} borderRadius={"10px"}>
                <Stack direction={"row"} padding={1}>
                  <Box textAlign={"center"} flexGrow={1}>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      {employEstimate?.toLocaleString("en-US") || "-"}
                    </Typography>
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Employment Estimate
                    </Typography>
                  </Box>
                  <Box textAlign={"center"} flexGrow={1}>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      {oewsNationalData?.TotalEmp?.toLocaleString("en-US") ||
                        "-"}
                    </Typography>
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Total Employment in OEWS Group
                    </Typography>
                  </Box>
                  <Box textAlign={"center"} flexGrow={1}>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      {rowData?.customEmployment?.toLocaleString("en-US") ||
                        "-"}
                    </Typography>
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Custom Employment
                    </Typography>
                  </Box>
                </Stack>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#F5F5F5",
                }}
                borderRadius={"10px"}
              >
                <Stack padding={1}>
                  <Box
                    sx={{
                      paddingBottom: "3px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      component={"h6"}
                      color={"primary"}
                      sx={{ borderBottom: "1px solid #666672" }}
                    >
                      Custom Notes
                    </Typography>
                  </Box>
                  <Box sx={{ paddingTop: "5px" }}>
                    <Typography variant="p" component={"p"}>
                      {rowData?.note}
                    </Typography>
                  </Box>
                </Stack>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#F5F5F5",
                }}
                borderRadius={"10px"}
              >
                <Stack padding={1}>
                  <Box
                    sx={{
                      paddingBottom: "3px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      component={"h6"}
                      color={"primary"}
                      sx={{ borderBottom: "1px solid #666672" }}
                    >
                      Description
                    </Typography>
                  </Box>
                  <Box sx={{ paddingTop: "5px" }}>
                    <Typography variant="p" component={"p"}>
                      {formatString(location.state.DescriptionCombined)}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Stack>
            <Stack
              spacing={1}
              className="bg-white"
              padding={1}
              borderRadius={"10px"}
              mt={2}
            >
              <CodeTab
                {...{
                  tableDatabyDotCode: rowData,
                  oapCodeData,
                  goeCodeData,
                  socCodeData,
                  rowData,
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Requirements
              {...{
                tableDatabyDotCode: rowData,
                partialMatchResultsState: [rowData],
                setParentEmployEstimate: setEmployEstimate,
              }}
            />
            <Box mt={1}>
              <Link to="/SOCGroupInfo">
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  endIcon={<RightArrowIcon />}
                  size="large"
                >
                  SOC Analysis
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default HypotheticalToolDOTExpandedView;
