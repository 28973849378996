import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import GetDefination from "../../../utils/getDefination";
import { handleGetEmploymentEstimateRequest } from "../../../services/getEmployementEstimate";

// we have 2 components for requirements. Other is requirementsTab
export const Requirements = ({
  tableDatabyDotCode,
  partialMatchResultsState,
  setParentEmployEstimate
}) => {
  var dbCurrentVersion;
  var dbName = "veToolkitDB";

  let EnvironmentalDemandsData = JSON.parse(
    localStorage.getItem("EnvironmentalDemands")
  );
  let EnvironmentalDemandLevelData = JSON.parse(
    localStorage.getItem("EnvironmentalDemandLevel")
  );
  let DotGedsData = JSON.parse(localStorage.getItem("DotGeds"));

  let DotGedsLevelsData = JSON.parse(localStorage.getItem("DotGedsLevels"));
  let AptitudesData = JSON.parse(localStorage.getItem("Aptitudes"));
  let PhysicalDemandsData = JSON.parse(localStorage.getItem("PhysicalDemands"));

  const [DotData, setDotData] = useState();
  const [PhysicalDemandData, setPhysicalDemandData] = useState();
  const [enviromentalData, setEnviromentalData] = useState();
  const [aptitudeData, setAptitudeData] = useState();
  const [EmployEstimate, setEmployEstimate] = useState("");

  useEffect(() => {
    var openRequestver = window.indexedDB.open(dbName);

    openRequestver.onsuccess = function (event) {
      var dbver = event.target.result;
      var currentVersion = dbver.version;
      dbCurrentVersion = currentVersion;

      // Perform other operations as needed
    };

    openRequestver.onerror = function (event) {
      console.error("Error opening database:", event.target.error);
    };

    // Cleanup function
    return () => {
      openRequestver?.result?.close();
    };
  }, []);

  useEffect(() => {
    const getEstimateCode = async (dotCode) => {
      const data = await handleGetEmploymentEstimateRequest(dotCode);
      setEmployEstimate(data?.data?.data?.[0]?.dotEstEmp ?? "--");
      if (setParentEmployEstimate){
        setParentEmployEstimate(data?.data?.data?.[0]?.dotEstEmp ?? "--");
      }
    };
    if (tableDatabyDotCode) {
      setEmployEstimate("");
      let dotCode =
        tableDatabyDotCode.dotcode ||
        tableDatabyDotCode.DotCode ||
        tableDatabyDotCode.dotCode;
      dotCode = String(dotCode).replace(/[-.]/g, "");
      getEstimateCode(dotCode);
    }
  }, [tableDatabyDotCode]);

  useEffect(() => {
    matchedRecordsPhysical(partialMatchResultsState);
    matchedRecordsEnvironmental(partialMatchResultsState);
    matchedRecordsGEDs(partialMatchResultsState);
    matchedRecordsAptitudes(partialMatchResultsState);
  }, [partialMatchResultsState]);

  const matchedRecordsPhysical = (partialMatchResults) => {
    if (!PhysicalDemandsData) return;
    var resultPhys = [];
    for (var i = 0; i < PhysicalDemandsData.length; i++) {
      var CombinePhysSlug = `Phys${+(i + 1)}`;
      var PhysDemandValue =
        partialMatchResults &&
        partialMatchResults.length > 0 &&
        partialMatchResults[0][CombinePhysSlug];
      var outputPhys = {
        name: PhysicalDemandsData[i]?.Name,
        value: PhysDemandValue && PhysDemandValue,
      };
      resultPhys.push(outputPhys);
    }
    setPhysicalDemandData(resultPhys);
  };

  const matchedRecordsEnvironmental = (partialMatchResults) => {
    var result = [];
    for (var i = 0; i < EnvironmentalDemandsData.length; i++) {
      if (EnvironmentalDemandsData[i].IsMultiLevel) {
        var CombineEnvSlug = `Env${i + 1}`;
        var envDemandValue =
          partialMatchResults &&
          partialMatchResults.length > 0 &&
          partialMatchResults[0][CombineEnvSlug] - 1;

        var envDemandLevels = EnvironmentalDemandLevelData.filter(
          (row) => row.EnvironmentalDemandId === EnvironmentalDemandsData[i].Id
        );
        var output = {
          name: EnvironmentalDemandsData[i]?.Description,
          value: envDemandLevels[envDemandValue]?.Description,
        };
        result.push(output);
      } else {
        var CombineEnvSlug = `Env${+(i + 1)}`;
        var demandValue =
          partialMatchResults &&
          partialMatchResults.length > 0 &&
          partialMatchResults[0][CombineEnvSlug];

        var output = {
          name: EnvironmentalDemandsData[i]?.Description,
          value: demandValue,
        };
        result.push(output);
      }
    }
    setEnviromentalData(result);
  };

  const matchedRecordsGEDs = (partialMatchResults) => {
    var result = [];
    for (var i = 0; i < DotGedsData.length; i++) {
      if (i == 0) {
        // GedG mean reasoning
        var reasoningLevel =
          partialMatchResults &&
          partialMatchResults.length > 0 &&
          partialMatchResults[0].GedG;
        var outputGEDs = {
          name: DotGedsData[0]?.Name,
          value: reasoningLevel,
        };
        result.push(outputGEDs);
      } else if (i == 1) {
        // GedE mean math
        var mathLevel =
          partialMatchResults &&
          partialMatchResults.length > 0 &&
          partialMatchResults[0].GedE;
        var outputGEDs = {
          name: DotGedsData[1]?.Name,
          value: mathLevel,
        };
        result.push(outputGEDs);
      } else if (i == 2) {
        // GedD mean Language
        var languageLevel =
          partialMatchResults &&
          partialMatchResults.length > 0 &&
          partialMatchResults[0].GedD;
        var outputGEDs = {
          name: DotGedsData[2]?.Name,
          value: languageLevel,
        };
        result.push(outputGEDs);
      } else {
        break;
      }
    }
    setDotData(result);
  };

  const matchedRecordsAptitudes = (partialMatchResults) => {
    var result = [];
    for (var i = 0; i < AptitudesData.length; i++) {
      var demandAptChar = AptitudesData[i].ShortCharacter;
      var demandAptDesc = AptitudesData[i].Description;
      var combinedSlugApt = `Apt${demandAptChar}`;
      var demndValueApt =
        partialMatchResults &&
        partialMatchResults.length > 0 &&
        partialMatchResults[0][combinedSlugApt];
      var OapCode =
        partialMatchResults &&
        partialMatchResults.length > 0 &&
        partialMatchResults[0].OapCode;

      var outputAptitude = {
        description: demandAptDesc,
        value: demndValueApt,
        OapCode,
      };
      result.push(outputAptitude);
    }
    setAptitudeData(result);
  };

  return (
    <Box
      sx={{
        position: "relative",
        background: "white",
        padding: 1,
      }}
    >
      <Box
        borderRadius={"10px"}
        backgroundColor={"#F5F5F5"}
        marginBottom={"5px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          maxWidth={"55%"}
          sx={{ paddingTop: "10px", paddingX: "10px" }}
          className="hover:bg-white"
        >
          <Typography variant="p" component={"p"}>
            Employment Estimate
          </Typography>
          <Typography variant="p" component={"p"}>
            {EmployEstimate ? Number(EmployEstimate)?.toLocaleString("en-US"): '--'}
          </Typography>
        </Stack>
      </Box>
      <Box
        borderRadius={"10px"}
        backgroundColor={"#F5F5F5"}
        marginBottom={"5px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ paddingTop: "10px", paddingX: "10px", maxWidth: "55%" }}
          className="hover:bg-white"
        >
          <Typography variant="p" component={"p"}>
            SVP
          </Typography>
          <Typography variant="p" component={"p"}>
            {tableDatabyDotCode?.SVP}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ paddingTop: "10px", paddingX: "10px", maxWidth: "55%" }}
          className="hover:bg-white"
        >
          <Typography variant="p" component={"p"}>
            Physical Demand
          </Typography>
          <Typography variant="p" component={"p"}>
            {tableDatabyDotCode?.Strength}
          </Typography>
        </Stack>
      </Box>
      <Box
        borderRadius={"10px"}
        backgroundColor={"#F5F5F5"}
        marginBottom={"5px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            borderBottom: "1px solid #666672",
            padding: "10px",
          }}
        >
          <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
          >
            GED:
          </Typography>
        </Stack>
        {DotData?.map((data, index) => (
          <Stack
            key={index}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ paddingTop: "10px", paddingX: "10px", maxWidth: "55%" }}
            className="hover:bg-white"
          >
            <Typography variant="p" component={"p"}>
              <GetDefination
                {...{
                  dbCurrentVersion,
                  TypeName: "GED",
                  Description: data?.name,
                  Code: data?.name[0] + data?.value,
                }}
              >
                {data?.name}
              </GetDefination>
            </Typography>
            <Typography variant="p" component={"p"}>
              {data?.value}
            </Typography>
          </Stack>
        ))}
      </Box>
      <Box
        borderRadius={"10px"}
        backgroundColor={"#F5F5F5"}
        marginBottom={"5px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            // borderBottom: "1px solid #666672",
            padding: "10px",
          }}
        >
          <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
          >
            Physical Demands:
          </Typography>
          {/* <Typography variant="p" component={"p"}>
            {tableDatabyDotCode?.PhysicalDemands}
          </Typography> */}
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            borderBottom: "1px solid #666672",
          }}
        ></Stack>
        {PhysicalDemandData?.map((data, index) => (
          <Stack
            key={index}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ paddingTop: "10px", paddingX: "10px", maxWidth: "55%" }}
            className="hover:bg-white"
          >
            <Typography variant="p" component={"p"}>
              <GetDefination
                {...{
                  dbCurrentVersion,
                  TypeName: "Physical Demands",
                  Description: data?.name,
                  Code: data?.value,
                }}
              >
                {data.name}
              </GetDefination>
            </Typography>
            <Typography variant="p" component={"p"}>
              {data.value}
            </Typography>
          </Stack>
        ))}
      </Box>
      <Box
        borderRadius={"10px"}
        backgroundColor={"#F5F5F5"}
        marginBottom={"5px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            borderBottom: "1px solid #666672",
            padding: "10px",
          }}
        >
          <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
          >
            Environmental:
          </Typography>
        </Stack>
        {enviromentalData?.map(
          (data, index) =>
            data.name !== undefined &&
            data.value !== undefined && (
              <Stack
                key={index}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ paddingTop: "10px", paddingX: "10px", maxWidth: "55%" }}
                className="hover:bg-white"
              >
                <Typography variant="p" component={"p"}>
                  <GetDefination
                    {...{
                      dbCurrentVersion,
                      TypeName: "Environmental",
                      Description: data.name,
                      Code: data.value,
                    }}
                  >
                    {data.name}
                  </GetDefination>
                </Typography>
                <Typography variant="p" component={"p"}>
                {data.value.startsWith("Noise Intensity Level")
                    ? data.value.match(/\d+$/)[0] // Extracts the number at the end
                    : data.value}
                </Typography>
              </Stack>
            )
        )}
      </Box>
      <Box
        borderRadius={"10px"}
        backgroundColor={"#F5F5F5"}
        marginBottom={"5px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            borderBottom: "1px solid #666672",
            padding: "10px",
          }}
        >
          <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
          >
            Temperaments:
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            paddingTop: "10px",
            paddingX: "10px",
            paddingBottom: "5px",
            maxWidth: "55%",
          }}
        >
          <Typography variant="p" component={"p"} className="flex flex-col">
            {Array.from(
              tableDatabyDotCode?.Temper ? tableDatabyDotCode?.Temper : ""
            )?.map((data, index) => (
              <span key={index} className="w-full">
                <div className="w-full">
                  <GetDefination
                    {...{
                      dbCurrentVersion,
                      TypeName: "Temperaments",
                      Description: "NULL",
                      Code: data,
                    }}
                  >
                    {data}
                  </GetDefination>
                </div>
              </span>
            ))}
          </Typography>
        </Stack>
      </Box>
      <Box
        borderRadius={"10px"}
        backgroundColor={"#F5F5F5"}
        marginBottom={"5px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            // borderBottom: "1px solid #666672",
            padding: "10px",
            maxWidth: "55%",
          }}
        >
          <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
            width={"60%"}
          >
            Aptitudes:
          </Typography>

          {/* <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
          >
            DOT
          </Typography> */}
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            borderBottom: "1px solid #666672",
          }}
        ></Stack>

        {aptitudeData?.map((data, index) => (
          <Stack
            key={index}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              paddingTop: "10px",
              paddingX: "10px",
              paddingBottom: "5px",
              maxWidth: "55%",
            }}
            className="hover:bg-white"
          >
            <Typography variant="p" component={"p"}>
              <GetDefination
                {...{
                  dbCurrentVersion,
                  TypeName: "Aptitudes",
                  Description: data.description,
                  Code: "",
                }}
              >
                {data.description}
              </GetDefination>
            </Typography>
            <Typography variant="p" component={"p"}>
              {data.value}
            </Typography>
          </Stack>
        ))}
      </Box>
      {/* </Typography> */}
    </Box>
  );
};
