// src/Pages/TransferableSkill/TransferableComponents.jsx
import React from 'react';
import { Stack, Box, Typography, ImageList, ImageListItem } from '@mui/material';
import GetDefinationByDotCode from '../../utils/getDefinationByDotCode';

const TransferableComponents = ({ selectedWorkHistory, selectedDotData, openComponent, dbCurrentVersion,filteredData }) => {
    return (
    <Stack padding={1}>
      {openComponent && (
        <ImageList variant="masonry" cols={2} gap={8} sx={{ mt: 1 }}>
          {selectedWorkHistory.map((item, index) => {
            if (item?.WorkFields === selectedDotData?.WorkFields) {
              return (
                <ImageListItem key={index}>
                  <Stack padding={"10px"} borderRadius={"10px"} backgroundColor={"#F5F5F5"}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ borderBottom: "1px solid #666672", paddingBottom: "3px" }}>
                      <Typography variant="p" component={"p"}>Work Field</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>DOT Title</Typography>
                      <Typography variant="p" component={"p"}>{item?.Title}</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>DOT Code</Typography>
                      {/* <Typography variant="p" component={"p"}>{item?.DotCodeFormated}</Typography> */}
                      {/* <Typography variant="p" component={"p"}>{item?.DotCodeFormated}</Typography> */}
                      <GetDefinationByDotCode
                {...{
                  dbCurrentVersion,
                  // Description: item?.name,
                  TypeName: "Work Fields",
                  Code: item?.WorkFields,
                }}
              >
                {item?.DotCodeFormated}
              </GetDefinationByDotCode>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>Work Field Code</Typography>
                      <Typography variant="p" component={"p"}>{item?.WorkFields?.toString()?.replace(/(\d{3})(?=\d)/, "$1\n")}</Typography>
                    </Stack>
                  </Stack>
                </ImageListItem>
              );
            }
            return null;
          })}
          {selectedWorkHistory.map((item, index) => {
            if (item?.GoeCode === selectedDotData?.GoeCode) {
              return (
                <ImageListItem key={index}>
                  <Stack padding={"10px"} borderRadius={"10px"} backgroundColor={"#F5F5F5"}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ borderBottom: "1px solid #666672", paddingBottom: "3px" }}>
                      <Typography variant="p" component={"p"}>GOE</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>DOT Title</Typography>
                      <Typography variant="p" component={"p"}>{item?.Title}</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>DOT Code</Typography>
                      {/* <Typography variant="p" component={"p"}>{item?.DotCodeFormated}</Typography> */}
                      <GetDefinationByDotCode
                {...{
                  dbCurrentVersion,
                  // Description: item?.name,
                  TypeName: "GOE",
                  Code: item?.GoeCode?.toString().padStart(6, '0').replace(/(\d{2})(\d{2})(\d{2})/, '$1.$2.$3'),
                }}
              >{item?.DotCodeFormated}</GetDefinationByDotCode>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>GOE Code</Typography>
                      <Typography variant="p" component={"p"}>{item?.GoeCode?.toString().padStart(6, '0').replace(/(\d{2})(\d{2})(\d{2})/, '$1.$2.$3')}</Typography>
                    </Stack>
                  </Stack>
                </ImageListItem>
              );
            }
            return null;
          })}
          {selectedWorkHistory.map((item, index) => {
            if (item?.MPSMS === selectedDotData?.MPSMS) {
              return (
                <ImageListItem key={index}>
                  <Stack padding={"10px"} borderRadius={"10px"} backgroundColor={"#F5F5F5"}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ borderBottom: "1px solid #666672", paddingBottom: "3px" }}>
                      <Typography variant="p" component={"p"}>MPSMS</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>DOT Title</Typography>
                      <Typography variant="p" component={"p"}>{item?.Title}</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>DOT Code</Typography>
                      {/* <Typography variant="p" component={"p"}>{item?.DotCodeFormated}</Typography> */}
                      <GetDefinationByDotCode
                {...{
                  dbCurrentVersion,
                  // Description: item?.name,
                  TypeName: "MPSMS",
                  Code: item?.MPSMS,
                }}
              >{item?.DotCodeFormated}</GetDefinationByDotCode>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>MPSMS Code</Typography>
                      <Typography variant="p" component={"p"}>{item?.MPSMS}</Typography>
                    </Stack>
                  </Stack>
                </ImageListItem>
              );
            }
            return null;
          })}
          {selectedWorkHistory.map((item, index) => {
            if (item["2018SocCode"]) {
              return (
                <ImageListItem key={index}>
                  <Stack padding={"10px"} borderRadius={"10px"} backgroundColor={"#F5F5F5"}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ borderBottom: "1px solid #666672", paddingBottom: "3px" }}>
                      <Typography variant="p" component={"p"}>SOC</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>DOT Title</Typography>
                      <Typography variant="p" component={"p"}>{item?.Title}</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>DOT Code</Typography>
                      {/* <Typography variant="p" component={"p"}>{item?.DotCodeFormated}</Typography> */}
                      <GetDefinationByDotCode
                {...{
                  dbCurrentVersion,
                  // Description: item?.name,
                  TypeName: "SOC",
                  Code: item?.SocCode,
                }}
              >{item?.DotCodeFormated}</GetDefinationByDotCode>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>SOC Code</Typography>
                      <Typography variant="p" component={"p"}>{item?.SocCode}</Typography>
                    </Stack>
                  </Stack>
                </ImageListItem>
              );
            }
            return null;
          })}
          {selectedWorkHistory.map((item, index) => {
            if (item?.CipCode === selectedDotData?.CipCode && selectedDotData?.CipCode !== undefined) {
              return (
                <ImageListItem key={index}>
                  <Stack padding={"10px"} borderRadius={"10px"} backgroundColor={"#F5F5F5"}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ borderBottom: "1px solid #666672", paddingBottom: "3px" }}>
                      <Typography variant="p" component={"p"}>CIP</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>DOT Title</Typography>
                      <Typography variant="p" component={"p"}>{item?.Title}</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>DOT Code</Typography>
                      {/* <Typography variant="p" component={"p"}>{item?.DotCodeFormated}</Typography> */}
                      <GetDefinationByDotCode
                {...{
                  dbCurrentVersion,
                  // Description: item?.name,
                  TypeName: "CIP",
                  Code: item?.CipCode,
                }}
              >{item?.DotCodeFormated}</GetDefinationByDotCode>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>CIP Code</Typography>
                      <Typography variant="p" component={"p"}>{item?.CipCode}</Typography>
                    </Stack>
                  </Stack>
                </ImageListItem>
              );
            }
            return null;
          })}
          {selectedWorkHistory.map((item, index) => {
            if (item?.SocCode === selectedDotData?.SocCode) {
              return (
                <ImageListItem key={index}>
                  <Stack padding={"10px"} borderRadius={"10px"} backgroundColor={"#F5F5F5"}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ borderBottom: "1px solid #666672", paddingBottom: "3px" }}>
                      <Typography variant="p" component={"p"}>SOC Code</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>DOT Title</Typography>
                      <Typography variant="p" component={"p"}>{item?.Title}</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>DOT Code</Typography>
                      {/* <Typography variant="p" component={"p"}>{item?.DotCodeFormated}</Typography> */}
                      <GetDefinationByDotCode
                {...{
                  dbCurrentVersion,
                  // Description: item?.name,
                  TypeName: "SOC Code",
                  Code: item?.SocCode,
                }}
              >{item?.DotCodeFormated}</GetDefinationByDotCode>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingTop: "10px" }}>
                      <Typography variant="p" component={"p"}>SOC Code</Typography>
                      <Typography variant="p" component={"p"}>{item?.SocCode}</Typography>
                    </Stack>
                  </Stack>
                </ImageListItem>
              );
            }
            return null;
          })}
        </ImageList>
      )}
    </Stack>
  );
};

export default TransferableComponents;