import * as React from "react";
import {
  AppBar,
  Container,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Button,
  Tooltip,
  MenuItem,
} from "@mui/material";
import LogoLight from "../assets/images/icons/transparent_logo.png";
import bg from "../assets/images/icons/navbar-bg.jpeg";
import SeetingsIcon from "../assets/images/icons/Settings.svg";
import BackBtnIcon from "../assets/images/icons/BackBtn.svg";
import { ReactComponent as WorkHistoryIcon } from "../assets/images/icons/work-history-icon.svg";
import { ReactComponent as HypotheticalAnalyzerIcon } from "../assets/images/icons/hypothetical-analyzer-icon.svg";
import { ReactComponent as TransferableSkillsIcon } from "../assets/images/icons/transferable-skills-icon.svg";
import { ReactComponent as HypotheticalAnalyzerSmIcon } from "../assets/images/icons/HypotheticalAnalyzerSmIcon.svg";
import { ReactComponent as Vector } from "../assets/images/icons/Vector.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import usePrimaryRouteHistory from "../hooks/usePrimaryRoutesHistory";
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const Navbar = () => {
  // const [anchorElUser, setAnchorElUser] = React.useState(null);

  const navigate = useNavigate();

  // to maintain the last visited route
  const { goBackToPrimaryRoute } = usePrimaryRouteHistory();
  const { pathname } = useLocation();

  const location = useLocation();
  let rowData = location.state || {};
  const selectedProfile = JSON.parse(localStorage.getItem("selectedProfile"));
  // rowData= JSON.parse(localStorage.getItem("row"));
  const firstName = selectedProfile?.firstname;
  const lastName = selectedProfile?.lastname;
  const dateOfHearing = selectedProfile?.dateofhearing;
  const onBack = () => {
    goBackToPrimaryRoute();
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: "0px 2px 115px 0px #00000014",
        background: "black",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover"
      }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Link to="/">
            <Typography
              variant="h6"
              sx={{
                pr: 2,
                borderRight: "0.5px solid #B0DAF3",
              }}
            >
              <img src={LogoLight} alt="VEToolkit" />
            </Typography>
          </Link>
          {location.pathname === "/" ? (
            <Box
              sx={{
                marginLeft: 2,
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
              }}
            >
              <Typography>All Profiles</Typography>
            </Box>
          ) : location.pathname === "/SOCGroupORSFilterTransferableSkill" ? (
            <Box
              sx={{
                marginLeft: 2,
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{ backgroundColor: "#E8E8EA4D", borderRadius: "5px" }}
                onClick={() => onBack()}
              >
                <img src={BackBtnIcon} alt="" />
              </IconButton>

              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    mx: 2,
                    color: "#FFFFFF",
                    lineHeight: "1",
                    fontSize: "18px",
                  }}
                >
                  {rowData["Title"]}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    mx: 2,
                    color: "#B0DAF3",
                    lineHeight: "1",
                    fontSize: "14px",
                  }}
                >
                  <Typography
                    variant="span"
                    sx={{
                      // mx: 1,
                      color: "#ffffff",
                    }}
                  >
                    SOC {rowData["2018SocCode"]}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          ) : location.pathname === "/SOCDOTGroupInfo" ? (
            <Box
              sx={{
                marginLeft: 2,
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{ backgroundColor: "#E8E8EA4D", borderRadius: "5px" }}
                onClick={() => onBack()}
              >
                <img src={BackBtnIcon} alt="" />
              </IconButton>

              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    mx: 2,
                    color: "#FFFFFF",
                    lineHeight: "1",
                    fontSize: "18px",
                  }}
                >
                  {rowData["Occupation"]}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    mx: 2,
                    color: "#B0DAF3",
                    lineHeight: "1",
                    fontSize: "14px",
                  }}
                >
                  <Typography
                    variant="span"
                    sx={{
                      // mx: 1,
                      color: "#ffffff",
                    }}
                  >
                    SOC{" "}
                    {rowData["Soc2018Code"]?.replace(/^(\d{2})(?=\d)/, "$1-")}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          ) : location.pathname === "/HypotheticalToolDOTExpandedView" ? (
            <Box
              sx={{
                marginLeft: 2,
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{ backgroundColor: "#E8E8EA4D", borderRadius: "5px" }}
                onClick={() => onBack()}
              >
                <img src={BackBtnIcon} alt="" />
              </IconButton>

              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    mx: 2,
                    color: "#FFFFFF",
                    lineHeight: "1",
                    fontSize: "18px",
                  }}
                >
                  DOT INFORMATION
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    mx: 2,
                    color: "#B0DAF3",
                    lineHeight: "1",
                    fontSize: "14px",
                  }}
                >
                  {rowData["Title"]}
                  <Typography
                    variant="span"
                    sx={{
                      mx: 1,
                      color: "#ffffff",
                    }}
                  >
                    {rowData["DotCodeFormated"]}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          ) : location.pathname === "/account" ||
            location.pathname === "/HypotheticalAnalyzerSetting" ||
            location.pathname === "/subsciptionhistory" ? (
            <Box
              sx={{
                marginLeft: 2,
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{ backgroundColor: "#E8E8EA4D", borderRadius: "5px" }}
                onClick={() => onBack()}
              >
                <img src={BackBtnIcon} alt="" />
              </IconButton>

              <Box
                sx={{
                  mx: 2,
                  color: "#FFFFFF",
                  lineHeight: "1",
                  fontSize: "18px",
                }}
              >
                <Typography>Settings</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "80%",
                }}
              >
                <Link to="/HypotheticalAnalyzerSetting">
                  <Button
                    sx={{
                      my: 2,
                      px: 2,
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#FFFFFF26",
                      },
                    }}
                    className={
                      pathname === "/HypotheticalAnalyzerSetting" &&
                      "NavBtnActive"
                    }
                    startIcon={<HypotheticalAnalyzerSmIcon />}
                  >
                    Hypothetical Analyzer Settings
                  </Button>
                </Link>
                <Link to="/account">
                  <Button
                    sx={{
                      my: 2,
                      px: 2,
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#FFFFFF26",
                      },
                    }}
                    startIcon={<Vector />}
                    className={pathname === "/account" && "NavBtnActive"}
                  >
                    Accounts
                  </Button>
                </Link>
              </Box>
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  marginLeft: 2,
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                }}
              >
                <IconButton
                  sx={{ backgroundColor: "#E8E8EA4D", borderRadius: "5px" }}
                  onClick={() => onBack()}
                >
                  <img src={BackBtnIcon} alt="" />
                </IconButton>

                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      mx: 2,
                      color: "#FFFFFF",
                      lineHeight: "1",
                      fontSize: "18px",
                    }}
                  >
                    {firstName} {lastName}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      mx: 2,
                      color: "#fff",
                      lineHeight: "1",
                      fontSize: "14px",
                    }}
                  >
                    Date of Hearing:
                    <Typography
                      variant="span"
                      sx={{
                        mx: 1,
                        color: "#ffffff",
                      }}
                    >
                      {new Date(dateOfHearing).toLocaleDateString("en-CA")}
                    </Typography>
                  </Typography>
                </Box>
                {!location.pathname.includes("dotDetail") && (
                  <>
                    <Link to="/WorkHistory">
                      <Button
                        sx={{
                          my: 2,
                          px: 2,
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#FFFFFF26",
                          },
                        }}
                        startIcon={<WorkHistoryIcon />}
                        className={
                          pathname === "/WorkHistory" ? "NavBtnActive" : ""
                        }
                      >
                        Work History
                      </Button>
                    </Link>
                    <Link to="/HypotheticalAnalyzer">
                      <Button
                        sx={{
                          my: 2,
                          px: 2,
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#FFFFFF26",
                          },
                        }}
                        startIcon={<HypotheticalAnalyzerIcon />}
                        className={
                          pathname === "/HypotheticalAnalyzer"
                            ? "NavBtnActive"
                            : ""
                        }
                      >
                        Hypothetical Analyzer
                      </Button>
                    </Link>
                    <Link to="/TransferableSkill">
                      <Button
                        sx={{
                          my: 2,
                          px: 2,
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#FFFFFF26",
                          },
                        }}
                        startIcon={<TransferableSkillsIcon />}
                        className={
                          pathname === "/TransferableSkill"
                            ? "NavBtnActive"
                            : ""
                        }
                      >
                        Transferable Skills
                      </Button>
                    </Link>{" "}
                  </>
                )}
              </Box>
            </>
          )}

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={() => navigate("/account")}
                sx={{ backgroundColor: "#E8E8EA4D", borderRadius: "5px" }}
              >
                <img src={SeetingsIcon} alt="" />
              </IconButton>
            </Tooltip>
            {/* <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleSetting(setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
