import {
  Box,
  Container,
  Stack,
  Grid,
  Button,
  Typography,
  SvgIcon,
  Tab,
  Tabs,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Select,
  MenuItem,
  ImageList,
  ImageListItem,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  ToggleButton,
  CircularProgress,
} from "@mui/material";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as Dropdown } from "../../assets/images/icons/dropdown.svg";
import { ReactComponent as EyeIcon } from "../../assets/images/icons/eye-icon.svg";
import { ReactComponent as EyeIconOff } from "../../assets/images/icons/eye-off-icon.svg";
import { ReactComponent as DescriptionIcon } from "../../assets/images/icons/description-icon.svg";
import { ReactComponent as RequirementsIcon } from "../../assets/images/icons/requirements-icon.svg";
import { ReactComponent as CodeIcon } from "../../assets/images/icons/code-lg-icon.svg";
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/right-arrow-icon-Large.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/images/icons/right-arrow-icon.svg";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TransferableSkillFilterModel from "./TransferableSkillFilterModel";
import { getAllDotfilters } from "../../services/getDOTFilters";
import { Requirements } from "../HypotheticalAnalyzer/helperComponents/requirements";
import { CodeTab } from "../HypotheticalAnalyzer/helperComponents/codeTab";
import { handleGetRequest } from "../../services/GetTemplate";
import { formatString } from "../HypotheticalAnalyzer/utils";
import CustomTableCell from "../../components/custom/table/ThemeTableCell";
import { decryptSocCode } from "../../utils/decryptSocCode";
import { handleGetEmploymentEstimateRequest } from "../../services/getEmployementEstimate";
import TransferableSkillCheckboxes from "./TransferableSkillCheckboxes";
import TransferableComponents from "./TransferableComponents";
import { postTransferrableSkill } from "../../services/postTransferrableSkill";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const TransferableSkill = () => {
  let dbName = "veToolkitDB";
  let dbCurrentVersion;
  let workHistoryData = undefined;
  const data = localStorage.getItem("workHistoryData");
  if (!!data && data !== "undefined")
    workHistoryData = JSON.parse(localStorage.getItem("workHistoryData"));

  const [open, setOpen] = useState(false);
  const [tableDatabyDotCode, setTableDatabyDotCode] = useState();
  const [transferableSkillTable, setTransferableSkillTable] = useState();
  const [DOTTitleViewDisplay, setDOTTitleViewDisplay] = useState(false);
  const [search, setSearch] = useState(false);
  const [filteredPassedData, setFilteredPassedData] = useState([]);
  // TODO: have to remove this key
  const [allDotData, setAllDotData] = useState(false);
  const [dotFiltersData, setDotFiltersData] = useState(false);
  const [selectedDotData, setSelectedDotData] = useState(null);
  const [selectedWorkHistory, setSelectedWorkHistory] = useState([]);
  const [checkedState, setCheckedState] = useState({
    MPSMS: false,
    "2018SocCode": false,
    WorkFields: false,
    GoeCode: false,
    data: false,
    people: false,
    things: false,
  });
  const [transferableSkillRadioBtn, setTransferableSkillRadioBtn] = useState([
    {
      value: "1",
      label: "Direct Search",
      checked: false,
    },
    {
      value: "2",
      label: "General Search",
      checked: false,
    },
    {
      value: "3",
      label: "Fair Search",
      checked: false,
    },
    {
      value: "4",
      label: "Potential Search",
      checked: false,
    },
  ]);
  const [filteredData, setFilteredData] = useState([]);
  // State to track selected state of each item
  const [selectedItems, setSelectedItems] = useState({});
  const [socCodeData, setSocCodeData] = useState();
  const [goeCodeData, setGoeCodeData] = useState();
  const [oapCodeData, setoapCodeData] = useState();
  const [oewsNationalM2022Data, setOewsNationalM2022Data] = useState();
  const [partialMatchResultsState, setPartialMatchResults] = useState();
  const [physicalDemandData, setPhysicalDemandData] = useState([]);
  const [environmentalDemandData, setEnvironmentalDemandData] = useState([]);
  const [cognitiveDemandData, setCognitiveDemandData] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [notes, setNotes] = useState("");
  const [goeDefinations, setGoeDefinations] = useState([]);
  const [selectValue, setSelectValue] = useState(0);
  const [oewsData, setOewsData] = useState([]);
  const [workHistory, setWorkHistory] = useState([]);
  const [loader, setLoader] = useState(false);
  const [openComponent, setOpenComponent] = useState(true);
  const [click, setClick] = useState(true);
  const [employEstimate, setEmployEstimate] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    fetch("OewsNationalM2022.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("the data of owess", data);
      setOewsData(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (
      !workHistory ||
      !workHistory.length ||
      !allDotData ||
      !allDotData.length === 0
    )
      return;

    // matching work history to get the full Dot master table
    const selectedFilteredDot = matchDotCodes(workHistory || [], allDotData);
    const columns = [
      "Title",
      "DotCodeFormated",
      "WorkFields",
      "GoeCode",
      "MPSMS",
      "Ged",
      "SocCode",
    ];
    if (selectedFilteredDot?.length) {
      const selectedFilteredDotData = selectedFilteredDot.map(
        (selectedDot, index) => {
          const selectedDotData = columns.reduce((acc, key) => {
            if (
              index > 0 &&
              selectedFilteredDot[index - 1][key] === selectedDot[key]
            ) {
              acc[key] = "";
            } else {
              acc[key] = selectedDot[key];
            }
            return acc;
          }, {});
          return selectedDotData;
        }
      );
      setSelectedWorkHistory(
        selectedFilteredDotData ? selectedFilteredDotData : []
      );
    }
  }, [workHistory, allDotData]);

  useEffect(() => {
    const getWorkHistoryData = async () => {
      const profileId = Number(localStorage.getItem("profileId"));
      const response = await handleGetRequest(
        `work-history?&profileId=${profileId}`
      );
      const apiResp = response?.data;
      if (!apiResp || !apiResp.length) setWorkHistory([]);
      setWorkHistory(response?.data);
    };
    getWorkHistoryData();
  }, []);

  useEffect(() => {
    fetch("GoeDefinations.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setGoeDefinations(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    const PhysicalDemandsData = JSON.parse(
      localStorage.getItem("PhysicalDemands")
    );
    if (!PhysicalDemandsData) {
      fetch("DotPhysicalDemands.json").then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setPhysicalDemandData(data);
        return;
      });
    } else {
      setPhysicalDemandData(PhysicalDemandsData);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const EnvironmentalDemandsData = JSON.parse(
      localStorage.getItem("EnvironmentalDemands")
    );
    if (!EnvironmentalDemandsData) {
      fetch("EnvironmentalDemands.json").then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setEnvironmentalDemandData(data);
        return;
      });
    } else {
      setEnvironmentalDemandData(EnvironmentalDemandsData);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const AptitudesData = JSON.parse(localStorage.getItem("Aptitudes"));
    if (!AptitudesData)
      fetch("aptitudes.json").then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setCognitiveDemandData(data);
        return;
      });
    else {
      setCognitiveDemandData(AptitudesData);
    }
  }, []); // eslint-disable-line

  const onSearchNaice = (item) => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["OewsNationalM2022"], "readonly");
      const objectStore = transaction.objectStore("OewsNationalM2022");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        const result = getData.result.find(
          (row) => item && row.OccCode.replace("-", "") == item["2018SocCode"]
        );
        setOewsNationalM2022Data(
          result ?? {
            OccCode:
              item &&
              item["2018SocCode"].slice(0, 2) +
                "-" +
                item["2018SocCode"].slice(2),
          }
        );
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OewsNationalM2022", {
        autoIncrement: true,
      });
    };
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const redirectTo = (link) => {
    const data = selectedDotData;
    // decrypting this to make sure the generic component SOCGroupInfo.jsx works
    data["2018SocCode"] = decryptSocCode(data["2018SocCode"]);
    localStorage.setItem("row", JSON.stringify(data));
    navigate(link, { state: selectedDotData });
  };

  const getEmployementEstimate = async (dotCodes) => {
    const data = await handleGetEmploymentEstimateRequest(
      JSON.stringify(dotCodes).replace("[", "").replace("]", "")
    );
    setEmployEstimate(data?.data?.data ?? []);
  };

  const handleSearchDotCode = (dotcodeFind) => {
    // Open the database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);
    request.onerror = function (event) {
      console.error("Database error: " + event.target.errorCode);
    };
    request.onsuccess = function (event) {
      const db = event.target.result;

      // Start a transaction and get the object store
      const transaction = db.transaction(["DOTMasterTable"], "readonly");

      const store = transaction.objectStore("DOTMasterTable");

      const index = store.index("DotCode");

      let partialMatchResults = [];

      // Open a cursor on the index
      index.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor) {
          const value = cursor.value;
          var dotCodeMatch = String(value.DotCodeFormated);
          value["2018SocCode"] = decryptSocCode(value["2018SocCode"]);

          // Check if the dot_code contains the substring 'part_of_dot_code'
          if (dotCodeMatch === dotcodeFind) {
            partialMatchResults.push(value);
          }
          cursor.continue();
        } else {
          setTableDatabyDotCode(partialMatchResults[0]);
          onSearchNaice(partialMatchResults[0]);
          setPartialMatchResults(partialMatchResults);
          matchedRecordsCodes(partialMatchResults);
          partialMatchResults = [];
        }
      };
    };
  };

  const matchedRecordsCodes = (partialMatchResults) => {
    if (!partialMatchResults || !partialMatchResults.length) return;

    const [socCode, goeCode, oapCode] = [
      partialMatchResults[0].SocCode,
      partialMatchResults[0].GoeCode,
      partialMatchResults[0].OapCode,
    ];

    let socCodeResult = [];
    let goeCodeResult = [];
    let oapCodeResult = [];

    const handleRequestSuccess =
      (storeName, matchCode, resultArray, setResultData) => (event) => {
        const db = event.target.result;
        const transaction = db.transaction([storeName], "readonly");
        const store = transaction.objectStore(storeName);

        store.openCursor().onsuccess = function (event) {
          const cursor = event.target.result;
          if (cursor) {
            const codeMatch = String(cursor.value.Code);
            if (codeMatch == matchCode) {
              const output = {
                type: `${storeName.split("Code")[0].toUpperCase()}-${
                  cursor.value.Type
                }`,
                code: codeMatch,
                description: cursor.value.Text,
              };
              resultArray.push(output);
            }
            cursor.continue();
          } else {
            setResultData(resultArray);
          }
        };
      };

    const openDatabase = (storeName, matchCode, resultArray, setResultData) => {
      const request = window.indexedDB.open(dbName, dbCurrentVersion);
      request.onerror = (event) => {
        console.error("Database error: " + event.target.errorCode);
      };
      request.onsuccess = handleRequestSuccess(
        storeName,
        matchCode,
        resultArray,
        setResultData
      );
    };

    openDatabase("OesCodeDefination", socCode, socCodeResult, setSocCodeData);
    openDatabase("OesCodeDefination", goeCode, goeCodeResult, setGoeCodeData);
    openDatabase("OapCodeDefinations", oapCode, oapCodeResult, setoapCodeData);
  };

  const getDotFiltersCounts = () => {
    if (!dotFiltersData || !dotFiltersData.count) return [];

    return dotFiltersData.dotFilters.map((_, index) => index + 1);
  };

  const getFilters = () => {
    if (!dotFiltersData || !dotFiltersData.count || !selectValue) return {};

    const data = dotFiltersData.dotFilters
      ? dotFiltersData.dotFilters[selectValue - 1]
      : {};
    return data;
  };

  useEffect(() => {
    const filters = getFilters();
    if (filters?.filters) {
      setAppliedFilters(filters.filters);
      setNotes(filters.notes);
    } else {
      setAppliedFilters({});
      setNotes("");
    }
  }, [selectValue]);

  useEffect(() => {
    let locallyfilters = JSON.parse(localStorage.getItem("appliedFilters"));
    if (locallyfilters) {
      setAppliedFilters(locallyfilters);
    }
    // Cleanup function to remove localStorage item when component unmounts
    // return () => {
    //   localStorage.removeItem("appliedFilters");
    //   console.log("appliedFilters removed from localStorage");
    // };
  }, [open]);

  useEffect(() => {
    const asyncFunc = async () => {
      const profileId = Number(localStorage.getItem("profileId"));
      const response = await getAllDotfilters(profileId);
      if (response?.data) {
        const { count } = response?.data;
        const filters = response?.data.orsFilters.map((value) => {
          return {
            filters: value.filters.DotFilters,
            results: value.filters.DotResults,
            id: value.id,
            notes: value.filters.notes,
          };
        });
        setDotFiltersData({ count, dotFilters: filters });
      }
    };
    asyncFunc();
  }, []);

  const transformSubmissionData = (
    data,
    physicalData,
    aptitudesData,
    environmentalData
  ) => {
    const newData = {};
    if (data?.Strength) {
      newData.Strength = data.Strength;
    }

    if (data) {
      physicalData?.forEach((item, index) => {
        newData[`Phys${index + 1}`] = data[item.Name] || "";
      });
    }

    aptitudesData?.forEach((item) => {
      // newData[`Apt${item.ShortCharacter.charAt(0).toUpperCase()}`] =
      //   data[item.Description] || "";
      // Check if item.Description is "Math", "Reasoning", or "Language"
      if (["Math", "Reasoning", "Language"]?.includes(item.Description)) {
        newData[`Ged${item.ShortCharacter.charAt(0).toUpperCase()}`] = data
          ? data[item.Description]
          : "";
      } else {
        newData[`Apt${item.ShortCharacter.charAt(0).toUpperCase()}`] = data
          ? data[item.Description]
          : "";
      }
    });

    if (data) {
      environmentalData?.forEach((item, index) => {
        newData[`Env${index + 1}`] = data[item.Description] || "";
      });
    }

    function isNotEmpty(value) {
      if (typeof value === "string") {
        return value.trim().length > 0; // Check for non-empty string after trimming spaces
      } else if (Array.isArray(value)) {
        return value.length > 0; // Check for non-empty array
      }
      return true; // For other types, you can decide the default behavior
    }

    const updatedData = {};
    Object.entries(newData).forEach(([key, value]) => {
      if (isNotEmpty(value)) {
        updatedData[key] = value;
      }
    });

    return updatedData;
  };

  const matchDotCodes = (array1, array2) => {
    return array2?.filter((item2) => {
      return array1?.some(
        (item1) =>
          item1.dotcode === item2.DotCodeFormated?.toString() ||
          item1.dotcode === item2.DotCode?.toString()
      );
    });
  };

  const showEmployeeEstimate = (row) => {
    const result = Object.keys(employEstimate)
      .filter((key) => key !== "resultCount") // Exclude 'total'
      .find((key) => {
        return employEstimate[key].dot == row.DotCode;
      });
    if (result) {
      return employEstimate[result].dotEstEmp;
    }

    return "-";
  };

  const applyCustomDOTFilter = (array1, array2) => {
    const listOfAllDOTs = array1?.map((dot) =>
      dot?.dotcode?.replace(".", "")?.replace("-", "")
    );
    let filteredResults = [...array2];
    if (checkedState?.data) {
      const firstCharacters = listOfAllDOTs?.map((item) =>
        item?.charAt(0)?.toString()
      );
      filteredResults = array2?.filter((item2) =>
        firstCharacters?.includes(item2.DotCodeFormated?.charAt(0))
      );
    }
    if (checkedState?.people) {
      const secondCharacters = listOfAllDOTs.map((item) => item.charAt(1));
      if (filteredResults && filteredResults?.length > 0) {
        filteredResults = filteredResults.filter((item2) =>
          secondCharacters?.includes(item2.DotCodeFormated?.charAt(1))
        );
      } else {
        filteredResults = array2.filter((item2) =>
          secondCharacters?.includes(item2.DotCodeFormated?.charAt(1))
        );
      }
    }
    if (checkedState?.things) {
      const thirdCharacters = listOfAllDOTs.map((item) => item.charAt(2));

      if (filteredResults && filteredResults?.length > 0) {
        filteredResults = filteredResults.filter((item2) =>
          thirdCharacters?.includes(item2.DotCodeFormated?.charAt(2))
        );
      } else {
        filteredResults = array2.filter((item2) =>
          thirdCharacters?.includes(item2.DotCodeFormated?.charAt(1))
        );
      }
    }
    return filteredResults;
  };

  const handleSearchLocally = ({ data, locallyAppliedFilters }) => {
    let transformedData;
    if (
      !locallyAppliedFilters ||
      !Object.keys(locallyAppliedFilters).length === 0
    ) {
      locallyAppliedFilters = appliedFilters;
    }

    transformedData = transformSubmissionData(
      locallyAppliedFilters,
      physicalDemandData,
      cognitiveDemandData,
      environmentalDemandData
    );

    const output = data.filter((item) => {
      return (
        (!locallyAppliedFilters?.SVP ||
          locallyAppliedFilters.SVP.includes(item?.SVP?.toString())) &&
        Object.keys(transformedData).every((key) => {
          return transformedData[key]?.includes(item[key]);
        })
      );
    });
    return output;
  };

  const getFiltersValuesToCompare = (selectedFilteredDot) => {
    const selectedOptions = Object.keys(checkedState).filter(
      (key) => checkedState[key]
    );
    // Get labels of checked items
    const selectedOptionss = transferableSkillRadioBtn
      .filter((item) => item.checked)
      .map((item) => item.label);
    if (selectedOptions.length === 0) return {};
    const filteredValue = {};
    selectedFilteredDot?.forEach((work) => {
      selectedOptions.forEach((option) => {
        if (filteredValue[option]) {
          filteredValue[option].push(work[option]);
        } else {
          filteredValue[option] = [work[option]];
        }
      });
    });
    return filteredValue;
  };

  const handleSearch = async ({ locallyAppliedFilters }) => {
    setOpen(false);
    setLoader(true);

    // // Create a lookup map from oewsData for quick access
    // let oewsDataMap = oewsData.reduce((map, item) => {
    //   map[item.OccCode] = item.TotalEmp;
    //   return map;
    // }, {});
    // // debugger
    // const isEmpty = (obj) => {
    //   for (let key in obj) {
    //     if (obj.hasOwnProperty(key)) {
    //       return false;
    //     }
    //   }
    //   return true;
    // };

    setTimeout(async () => {
      if (!workHistory || !workHistory.length) {
        setLoader(false);
        setFilteredData([]);
        setSearch(true);
        return;
      }

      // matching work history to get the full Dot master table
      // const selectedFilteredDot = matchDotCodes(workHistory || [], allDotData || []);
      // getting the values of selected filters from the work history
      // const filtersValues = getFiltersValuesToCompare(selectedFilteredDot);
      // const firstTenRecords = allDotData.slice(0, 100);
      // if (!locallyAppliedFilters && isEmpty(firstTenRecords)) {
      //   // Merge totalEmp into updatedData
      //   let updatedfirstTenRecords = filtersValues.map((item) => {
      //     let totalEmp = oewsDataMap[item["2018SocCode"]];
      //     return {
      //       ...item,
      //       totalEmp: totalEmp !== undefined ? totalEmp : null,
      //     };
      //   });
      //   setLoader(false);
      //   setFilteredData(updatedfirstTenRecords);
      //   setSearch(true);
      //   return;
      // }

      // applying the filters to get the dot values
      // let result = allDotData.filter((item) =>
      //   Object.keys(filtersValues).every((key) =>
      //     filtersValues[key].includes(item[key])
      //   )
      // );
      // console.log("workHistory", workHistory);
      // result = applyCustomDOTFilter(workHistory, result);
      // let updatedData = handleSearchLocally({
      //   data: result,
      //   locallyAppliedFilters,
      // });
      // Create a lookup map from oewsData for quick access
      // let oewsDataMap = oewsData.reduce((map, item) => {
      //   map[item.OccCode] = item.TotalEmp;
      //   return map;
      // }, {});
      // console.log("updated data", updatedData);
      // Merge totalEmp into updatedData
      // updatedData = updatedData.map((item) => {
      //   let totalEmp = oewsDataMap[item["2018SocCode"]];
      //   return { ...item, totalEmp: totalEmp !== undefined ? totalEmp : null };
      // });
      // // setLoader(false);
      // setFilteredData(updatedData);
      // const dotCodes = updatedData.map((result) => result.DotCode);
      // getEmployementEstimate(dotCodes);
      // setSearch(true);

      // Calling api of transferable-skills
      // console.log("transferable-skills", transferableSkillRadioBtn);
      // Convert DOT codes and create comma-separated string
      const dotCodesString = workHistory
        .map((item) => item.dotcode.replace(/[.-]/g, "")) // Remove dots and hyphens
        .join(","); // Join with commas

      // Result: "211462010,321321,3232122"
      let data = {
        type: transferableSkillRadioBtn.filter((item) => item?.checked)[0]
          ?.value,
        DOTS: dotCodesString,
      };
      const updatedNewData = transformSubmissionData(
        locallyAppliedFilters,
        physicalDemandData,
        cognitiveDemandData,
        environmentalDemandData
      );
      updatedNewData["SVP"] = locallyAppliedFilters?.SVP;
      updatedNewData["type"] = data?.type;
      updatedNewData["DOTS"] = data?.DOTS;

      if (updatedNewData) {
        let resp = await postTransferrableSkill(updatedNewData);

        // setFilteredData(resp?.data?.data?.slice(0, 100))
        setFilteredPassedData(resp?.data?.data);
        setLoader(false);
        setSearch(true);
        //  return console.log("resp of updated data is", resp);
      } else {
        console.log("Type not selected, skipping API call");
        setLoader(false);
      }
      // Calling api of transferable-skills
    }, [100]);
  };
  // getIndexDbData
  const [dotFormatedDot, setDotFormatedDot] = useState(null);
  const getIndexDbData = async (dotCode) => {
    // Create a lookup map from oewsData for quick access
    //  let locallyAppliedFilters = JSON.parse(localStorage.getItem("appliedFilters"));
    //  if(!locallyAppliedFilters){
    //    return
    //  }
    let oewsDataMap = oewsData.reduce((map, item) => {
      map[item.OccCode] = item.TotalEmp;
      return map;
    }, {});
    // debugger
    const isEmpty = (obj) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    };

    setTimeout(async () => {
      if (!workHistory || !workHistory.length) {
        setLoader(false);
        setFilteredData([]);
        setSearch(true);
        return;
      }

      // matching work history to get the full Dot master table
      const selectedFilteredDot = matchDotCodes(
        workHistory || [],
        allDotData || []
      );
      // getting the values of selected filters from the work history
      const filtersValues = getFiltersValuesToCompare(selectedFilteredDot);
      const firstTenRecords = allDotData.slice(0, 100);
      // if (!locallyAppliedFilters && isEmpty(firstTenRecords)) {
      if (isEmpty(firstTenRecords)) {
        // Merge totalEmp into updatedData
        let updatedfirstTenRecords = filtersValues.map((item) => {
          let totalEmp = oewsDataMap[item["2018SocCode"]];
          return {
            ...item,
            totalEmp: totalEmp !== undefined ? totalEmp : null,
          };
        });
        setLoader(false);
        setFilteredData(updatedfirstTenRecords);
        setSearch(true);
        return;
      }

      // applying the filters to get the dot values
      let result = allDotData.filter((item) =>
        Object.keys(filtersValues).every((key) =>
          filtersValues[key].includes(item[key])
        )
      );

      result = applyCustomDOTFilter(workHistory, result);
      // let updatedData = handleSearchLocally({
      //   data: result,
      //   locallyAppliedFilters,
      // });
      // Create a lookup map from oewsData for quick access
      // let oewsDataMap = oewsData.reduce((map, item) => {
      //   map[item.OccCode] = item.TotalEmp;
      //   return map;
      // }, {});
      // console.log("updated data", updatedData);
      // Merge totalEmp into updatedData
      // updatedData = updatedData.map((item) => {
      let updatedData = result.map((item) => {
        let totalEmp = oewsDataMap[item["2018SocCode"]];
        return { ...item, totalEmp: totalEmp !== undefined ? totalEmp : null };
      });
      // setLoader(false);
      setFilteredData(updatedData);
      const dotCodes = updatedData.map((result) => result.DotCode);
      getEmployementEstimate(dotCodes);
      setSearch(true);

      return updatedData;

      // Calling api of transferable-skills
      // console.log("transferable-skills", transferableSkillRadioBtn);
      // Convert DOT codes and create comma-separated string
      // const dotCodesString = workHistory
      //   .map((item) => item.dotcode.replace(/[.-]/g, "")) // Remove dots and hyphens
      //   .join(","); // Join with commas

      // console.log("formatted dot codes:", dotCodesString); // Result: "211462010,321321,3232122"
      // let data = {
      //   type: transferableSkillRadioBtn.filter((item) => item?.checked)[0]?.value,
      //   DOTS: dotCodesString,
      // };
      // const updatedNewData = transformSubmissionData(
      //   locallyAppliedFilters,
      //   physicalDemandData,
      //   cognitiveDemandData,
      //   environmentalDemandData
      // );
      // updatedNewData["SVP"] = locallyAppliedFilters?.SVP;
      // updatedNewData["type"] = data?.type;
      // updatedNewData["DOTS"] = data?.DOTS;
      // console.log("data in handleSearch", updatedNewData);
      // if (updatedNewData) {
      //   let resp = await postTransferrableSkill(updatedNewData);
      //   console.log("resp of updated data is", resp.data);
      //   // setFilteredData(resp?.data?.data?.slice(0, 100))
      //   setFilteredPassedData(resp?.data?.data?.slice(0, 100))
      //   setLoader(false);
      //     // setSearch(true);
      // //  return console.log("resp of updated data is", resp);
      // } else {
      //   console.log("Type not selected, skipping API call");
      //    setLoader(false);
      // }
      // Calling api of transferable-skills
    }, [100]);
  };
  // getIndexDbData
  // Define what happens when an item is selected

  // const handleSelect = (DotCodeFormated) => {
  //   // debugger
  //   setDOTTitleViewDisplay(true);
  //   console.log("dfkdlsfjdsl",DotCodeFormated,filteredData);
  //   const selectedData = filteredData.find(
  //     // (data) => data.DOTCODE === DotCodeFormated
  //     (data) => data.DotCodeFormated === DotCodeFormated
  //   );
  //   console.log("selectedDatajjj", selectedData);
  //   setSelectedDotData(selectedData);
  //   //    setWorkHistoryHeight(window.innerHeight-500)
  // };

  // const handleSelect = async (DotCodeFormated) => {
  //    await getIndexDbData(DotCodeFormated);
  //   // First find the selected data from filteredPassedData (API response data)
  //   const selectedData = filteredData.find((data) =>
  //     formatDotCode(data.DOTCODE) === DotCodeFormated
  //   );

  //   // If we found the data, set it immediately
  //   if (selectedData) {
  //     setSelectedDotData(selectedData);
  //     setDOTTitleViewDisplay(true);
  //   }

  //   // Then trigger the additional data loading
  //   handleSearchDotCode(DotCodeFormated);
  //   setDotFormatedDot(DotCodeFormated);

  //   // Load index DB data in background
  // };

  const handleSelect = async (DotCodeFormated) => {
    // Normalize the DotCodeFormated value
    const normalizedDotCode = DotCodeFormated.trim(); // Trim whitespace

    // Find the selected data
    const selectedData = filteredData.find((data) => {
      const normalizedDataDotCode = data.DotCodeFormated.trim(); // Trim whitespace

      return normalizedDataDotCode === normalizedDotCode; // Compare normalized values
    });

    if (selectedData) {
      setSelectedDotData(selectedData);
      setDOTTitleViewDisplay(true);
    }
    // setDotFormatedDot(DotCodeFormated);
    // await getIndexDbData(DotCodeFormated);
  };
  const handleDeselect = (itemId) => {
    // Additional deselect logic here
    //    setWorkHistoryHeight(window.innerHeight - 225);
    setDOTTitleViewDisplay(false);
    setSelectedDotData(null);
  };

  // Function to toggle select/deselect and call respective functions
  const handleToggleSelect = async (DotCodeFormated) => {
    setTimeout(async () => {
      await getIndexDbData(DotCodeFormated);
      handleSearchDotCode(DotCodeFormated);
      setSelectedItems((prevSelectedItems) => {
        const isSelected = !prevSelectedItems[DotCodeFormated];

        return { [DotCodeFormated]: isSelected };
      });
    }, 100);
  };
  //   // Use useEffect to ensure handleSelect runs after filteredData is updated
  useEffect(() => {
    if (filteredData.length > 0 && selectedItems) {
      const selectedDotCode = Object.keys(selectedItems).find(
        (key) => selectedItems[key]
      );
      if (selectedDotCode) {
        handleSelect(selectedDotCode);
      } else {
        handleDeselect(selectedDotCode);
      }
    }
  }, [filteredData]); // Runs when filteredData is updated
  // const handleToggleSelect = (DotCodeFormated) => {
  //   // debugger
  //   // debugger

  //   setTimeout(() => {
  //     handleSearchDotCode(DotCodeFormated);
  //     setSelectedItems((prevSelectedItems) => {
  //       const isSelected = !prevSelectedItems[DotCodeFormated];
  //       // Call handleSelect or handleDeselect based on the new state
  //       if (isSelected) {
  //         handleSelect(DotCodeFormated);

  //         // Deselect all other items
  //         return { [DotCodeFormated]: true };
  //       } else {
  //         handleDeselect(DotCodeFormated);
  //         return { [DotCodeFormated]: false };
  //       }
  //     });
  //   }, [100]);
  // };
  const [value, setValue] = useState(0);

  useEffect(() => {
    var openRequestver = window.indexedDB.open(dbName);

    openRequestver.onsuccess = function (event) {
      var dbver = event.target.result;
      var currentVersion = dbver.version;
      dbCurrentVersion = currentVersion;

      // Perform other operations as needed
    };

    openRequestver.onerror = function (event) {
      console.error("Error opening database:", event.target.error);
    };

    // Cleanup function
    return () => {
      openRequestver?.result?.close();
    };
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      const innerHeight = window.innerHeight;
      setTransferableSkillTable(innerHeight - 98);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [window.innerHeight]); // eslint-disable-line

  useEffect(() => {
    // Open IndexedDB database

    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["DOTMasterTable"], "readonly");
      const objectStore = transaction.objectStore("DOTMasterTable");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        setAllDotData(getData.result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("DOTMasterTable", {
        autoIncrement: true,
      });
    };
  }, []); // eslint-disable-line

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const savedData = localStorage.getItem("transferableSkillData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFilteredData(parsedData.filteredData || []);
      setSelectedItems(parsedData.selectedItems || {});
      setSelectedDotData(parsedData.selectedDotData || null);
      setDOTTitleViewDisplay(parsedData.DOTTitleViewDisplay || false);
      setValue(parsedData.value || 0);
      setCheckedState(parsedData.checkedState || {});
      setSelectValue(
        parsedData.selectValue !== undefined ? parsedData.selectValue : "0"
      );
      setSearch(true);
      // Clear the saved data after loading

      localStorage.removeItem("transferableSkillData");
    }
  }, []);
  const [sortOrder, setSortOrder] = useState("desc");
  const [selectedSortKey, setSelectedSortKey] = useState("");
  const strengthOrder = {
    S: 1,
    L: 2,
    M: 3,
    H: 4,
    V: 5,
  };

  // const sortResults = (sortKey) => {
  //   setLoader(true);
  //   let sortOrderLocal = "asc";
  //   if (sortKey === selectedSortKey) {
  //     sortOrderLocal = sortOrder === "asc" ? "desc" : "asc";
  //   }
  //   setSelectedSortKey(sortKey);
  //   switch (sortKey) {
  //     case "code":
  //       setFilteredData((prev) => {
  //         return sortOrderLocal === "asc"
  //           ? prev.sort((a, b) =>
  //               a.DotCodeFormated.localeCompare(b.DotCodeFormated)
  //             )
  //           : prev.sort((a, b) =>
  //               b.DotCodeFormated.localeCompare(a.DotCodeFormated)
  //             );
  //       });
  //       break;
  //     case "title":
  //       setFilteredData((prev) => {
  //         return sortOrderLocal === "asc"
  //           ? prev.sort((a, b) => a.Title.localeCompare(b.Title))
  //           : prev.sort((a, b) => b.Title.localeCompare(a.Title));
  //       });
  //       break;
  //     case "svp":
  //       setFilteredData((prev) => {
  //         return sortOrderLocal === "asc"
  //           ? prev.sort((a, b) => a.SVP - b.SVP)
  //           : prev.sort((a, b) => b.SVP - a.SVP);
  //       });
  //       break;
  //     case "physical":
  //       setFilteredData((prev) => {
  //         return sortOrderLocal === "asc"
  //           ? prev.sort(
  //               (a, b) =>
  //                 (strengthOrder[a.Strength] || 0) -
  //                 (strengthOrder[b.Strength] || 0)
  //             )
  //           : prev.sort(
  //               (a, b) =>
  //                 (strengthOrder[b.Strength] || 0) -
  //                 (strengthOrder[a.Strength] || 0)
  //             );
  //       });
  //       break;
  //     case "employmentEstimate":
  //       setFilteredData((prev) => {
  //         return sortOrderLocal === "asc"
  //           ? prev.sort((a, b) => {
  //               const aEstimate = showEmployeeEstimate(a);
  //               const bEstimate = showEmployeeEstimate(b);
  //               return (aEstimate || 0) - (bEstimate || 0);
  //             })
  //           : prev.sort((a, b) => {
  //               const aEstimate = showEmployeeEstimate(a);
  //               const bEstimate = showEmployeeEstimate(b);
  //               return (bEstimate || 0) - (aEstimate || 0);
  //             });
  //       });
  //       break;
  //     default:
  //       break;
  //   }
  //   setSortOrder(sortOrderLocal);
  //   setLoader(false);
  // };
  const sortResults = (sortKey) => {
    setLoader(true);
    let sortOrderLocal = "asc";
    if (sortKey === selectedSortKey) {
      sortOrderLocal = sortOrder === "asc" ? "desc" : "asc";
    }
    setSelectedSortKey(sortKey);

    switch (sortKey) {
      case "code":
      setFilteredPassedData((prev) => {
        // Log the data before sorting
        
        const sorted = [...prev].sort((a, b) => {
          const aCode = a.DOTCODE || ""; // Default to empty string if undefined
          const bCode = b.DOTCODE || ""; // Default to empty string if undefined

          // Use localeCompare for string comparison
          return sortOrderLocal === "asc"
            ? aCode.localeCompare(bCode, undefined, { numeric: true })
            : bCode.localeCompare(aCode, undefined, { numeric: true });
        });
        return sorted;
      });
      break;
    case "title":
      setFilteredPassedData((prev) => {
        const sorted = [...prev].sort((a, b) => {
          const aTitle = a.Title || ""; // Default to empty string if undefined
          const bTitle = b.Title || ""; // Default to empty string if undefined
          return sortOrderLocal === "asc"
            ? aTitle.localeCompare(bTitle)
            : bTitle.localeCompare(aTitle);
        });
        return sorted;
      });
      break;
      case "svp":
        setFilteredPassedData((prev) => {
          const sorted = [...prev].sort((a, b) => {
            const aValue = Number(a.SVP) || 0;
            const bValue = Number(b.SVP) || 0;
            return sortOrderLocal === "asc" ? aValue - bValue : bValue - aValue;
          });
          return sorted;
        });
        break;
      case "physical":
        setFilteredPassedData((prev) => {
          const sorted = [...prev].sort((a, b) => {
            const aStrength = strengthOrder[a.Strength] || 0;
            const bStrength = strengthOrder[b.Strength] || 0;
            return sortOrderLocal === "asc"
              ? aStrength - bStrength
              : bStrength - aStrength;
          });
          return sorted;
        });
        break;
      case "employmentEstimate":
        setFilteredPassedData((prev) => {
          const sorted = [...prev].sort((a, b) => {
            const aEstimate = Number(a.dotEstEmp) || 0;
            const bEstimate = Number(b.dotEstEmp) || 0;
            return sortOrderLocal === "asc"
              ? aEstimate - bEstimate
              : bEstimate - aEstimate;
          });
          return sorted;
        });
        break;
      default:
        break;
    }
    setSortOrder(sortOrderLocal);
    setLoader(false);
  };
  // for conveting dot code to its original formating
  const formatDotCode = (dotCode) => {
    if (!dotCode || typeof dotCode !== "string") return dotCode;
    return `${dotCode.slice(0, 3)}.${dotCode.slice(3, 6)}-${dotCode.slice(6)}`;
  };

  // Add these state variables at the top with other state declarations
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);

  // Add this pagination function
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredPassedData?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  // Add these pagination handler functions
  const handleNextPage = () => {
    if (indexOfLastRecord < filteredPassedData?.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <>
      <Container
        maxWidth={false}
        sx={{ paddingTop: "80px", paddingBottom: 2, px: { xs: "10px" } }}
      >
        <Grid container spacing={"10px"}>
          <Grid item xs={6} overflow={"auto"} maxHeight={"87vh"}>
            <Stack
              spacing={1}
              className="bg-white"
              padding={1}
              borderRadius={"10px"}
            >
              <Box>
                <Typography variant="h6" component={"h6"}>
                  Transferable Skill Matching Criteria
                </Typography>
              </Box>
              {/* <Box mx={10}> */}
              {/* First Row */}
              {/* <FormGroup row sx={{ margin: "0 10px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disableRipple
                            checked={checkedState.WorkFields}
                            name="WorkFields"
                            sx={{ padding: 0, paddingRight: "5px" }}
                            onClick={handleCheckboxChange}
                            disabled={loader}
                          />
                        }
                        label="Work Fields"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disableRipple
                            checked={checkedState.MPSMS}
                            name="MPSMS"
                            sx={{ padding: 0, paddingRight: "5px" }}
                            onClick={handleCheckboxChange}
                            disabled={loader}
                          />
                        }
                        label="MPSMS"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState["2018SocCode"]}
                            disableRipple
                            name="2018SocCode"
                            sx={{ padding: 0, paddingRight: "5px" }}
                            onClick={handleCheckboxChange}
                            disabled={loader}
                          />
                        }
                        label="SOC"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disableRipple
                            checked={checkedState.GoeCode}
                            name="GoeCode"
                            sx={{ padding: 0, paddingRight: "5px" }}
                            onClick={handleCheckboxChange}
                            disabled={loader}
                          />
                        }
                        label="GOE"
                      />
                    </Grid>
                  </Grid>
                </FormGroup>

                <FormGroup row sx={{ margin: "0 10px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disableRipple
                            checked={checkedState?.data}
                            name="data"
                            sx={{ padding: 0, paddingRight: "5px" }}
                            onClick={handleCheckboxChange}
                            disabled={loader}
                          />
                        }
                        label="Data"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disableRipple
                            checked={checkedState?.people}
                            name="people"
                            sx={{ padding: 0, paddingRight: "5px" }}
                            onClick={handleCheckboxChange}
                            disabled={loader}
                          />
                        }
                        label="People"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedState?.things}
                            disableRipple
                            name="things"
                            sx={{ padding: 0, paddingRight: "5px" }}
                            onClick={handleCheckboxChange}
                            disabled={loader}
                          />
                        }
                        label="Things"
                      />
                    </Grid>
                  </Grid>
                </FormGroup> */}
              <TransferableSkillCheckboxes
                checkedState={checkedState}
                handleCheckboxChange={handleCheckboxChange}
                transferableSkillRadioBtn={transferableSkillRadioBtn}
                loader={loader}
                setTransferableSkillRadioBtn={setTransferableSkillRadioBtn}
              />
              {/* </Box> */}
              <Box mt={2}>
                <Typography
                  variant="p"
                  component="p"
                  color={"text.grey"}
                  paddingLeft={"4px"}
                  paddingBottom={"5px"}
                >
                  Apply filter from Hypothetical
                </Typography>
                <Select
                  size="small"
                  variant="filled"
                  hiddenLabel
                  fullWidth
                  value={selectValue}
                  onChange={(e) => {
                    setSelectValue(e.target.value);
                    setClick(false);
                  }}
                >
                  <MenuItem value={0}>Select DOT Filters</MenuItem>
                  <MenuItem value="none">None</MenuItem>
                  {getDotFiltersCounts().map((id, index) => {
                    if (id !== undefined && id !== null) {
                      return (
                        <MenuItem key={id} value={id}>
                          #{index + 1} (DOT)
                        </MenuItem>
                      );
                    }
                    return null;
                  })}
                </Select>
              </Box>
              <Stack direction={"row"} spacing={2} mt={2}>
                <Box flexGrow={1}>
                  <Button
                    // disabled={
                    //   click && !Object.values(checkedState).includes(true)
                    // }
                    disabled={
                      click &&
                      !transferableSkillRadioBtn.some((btn) => btn.checked)
                    }
                    variant="outlined"
                    color="DetailsBtn"
                    fullWidth
                    endIcon={<RightArrowIcon />}
                    onClick={handleClickOpen}
                  >
                    Use RFC Filter
                  </Button>
                </Box>
                <Box flexGrow={1}>
                  <Button
                    // disabled={
                    //   click && !Object.values(checkedState).includes(true)
                    // }
                    disabled={
                      click &&
                      !transferableSkillRadioBtn.some((btn) => btn.checked)
                    }
                    variant="contained"
                    fullWidth
                    color="primary"
                    startIcon={<SearchIcon />}
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </Box>
              </Stack>
            </Stack>
            {DOTTitleViewDisplay && selectedDotData && (
              <Box
                className="bg-white"
                padding={1}
                borderRadius={"10px"}
                marginTop={"10px"}
                position={"relative"}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{ width: "100%" }}
                >
                  <Typography variant="h6">DOT Title View</Typography>
                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="none"
                      sx={{
                        "& .MuiTabs-flexContainer": {
                          justifyContent: "flex-center",
                          alignItems: "center",
                          gap: 2,
                        },
                      }}
                    >
                      <Tab
                        label="Description"
                        icon={<DescriptionIcon />}
                        iconPosition="start"
                        {...a11yProps(0)}
                        sx={{
                          color:
                            value === 0
                              ? "#ffffff !important"
                              : "#666672 !important",
                          backgroundColor: value === 0 ? "#0089D7" : "#F5F5F5",
                          minHeight: "20px",
                          paddingX: "10px",
                          paddingY: "8px",
                          alignItems: "end",
                        }}
                      />
                      <Tab
                        label="Requirements"
                        {...a11yProps(1)}
                        icon={<RequirementsIcon />}
                        iconPosition="start"
                        sx={{
                          color:
                            value === 1
                              ? "#ffffff !important"
                              : "#666672 !important",
                          backgroundColor: value === 1 ? "#0089D7" : "#F5F5F5",
                          minHeight: "20px",
                          paddingX: "10px",
                          paddingY: "8px",
                          alignItems: "end",
                        }}
                      />
                      <Tab
                        label="Codes"
                        icon={<CodeIcon />}
                        iconPosition="start"
                        {...a11yProps(2)}
                        sx={{
                          color:
                            value === 2
                              ? "#ffffff !important"
                              : "#666672 !important",
                          backgroundColor: value === 2 ? "#0089D7" : "#F5F5F5",
                          minHeight: "20px",
                          paddingX: "10px",
                          paddingY: "8px",
                          alignItems: "end",
                        }}
                      />
                      <a
                        href={`/dotDetail?dotCode=${selectedDotData?.DotCodeFormated}`}
                        // href={`/dotDetail?dotCode=${formatDotCode(selectedDotData?.DOTCODE)}`}
                        target="_blank"
                      >
                        <button
                          style={{
                            backgroundColor: "#F5F5F5",
                            minHeight: "20px",
                            padding: "10px 8px",
                            display: "flex",
                            alignItems: "end",
                            borderRadius: 10,
                            color: "#666672",
                            cursor: "alias",
                          }}
                        >
                          Link
                        </button>
                      </a>
                    </Tabs>
                  </Box>
                </Stack>

                <TabPanel value={value} index={0}>
                  <Box
                    sx={{
                      position: "relative",
                      maxHeight: `auto`,
                      overflowY: "scroll",
                      paddingBottom: "35px",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      my={1}
                      gap={1}
                      alignItems={"center"}
                    >
                      <Box flexGrow={1}>
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"primary"}
                        >
                          {selectedDotData.Title}
                        </Typography>
                      </Box>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"text.grey"}
                          sx={{ display: "flex" }}
                        >
                          <CodeIcon />
                          Code
                        </Typography>
                        <Typography
                          variant="h6"
                          component={"h6"}
                          fontSize={"0.875rem"}
                        >
                          {/* {formatDotCode(selectedDotData.DOTCODE)} */}
                          {selectedDotData.DotCodeFormated}
                        </Typography>
                      </Stack>
                    </Stack>

                    <Typography
                      variant="p"
                      component={"p"}
                      color={"#484957"}
                      paddingBottom={"10px"}
                    >
                      {formatString(
                        selectedDotData.DescriptionCombined ||
                          selectedDotData.description
                      )}
                    </Typography>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Stack direction={"row"} my={1} gap={1} alignItems={"center"}>
                    <Box flexGrow={1}>
                      <Typography variant="p" component={"p"} color={"primary"}>
                        {selectedDotData.Title}
                      </Typography>
                    </Box>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography
                        variant="p"
                        component={"p"}
                        color={"text.grey"}
                        sx={{ display: "flex" }}
                      >
                        <CodeIcon />
                        Code
                      </Typography>
                      <Typography
                        variant="h6"
                        component={"h6"}
                        fontSize={"0.875rem"}
                      >
                        {/* {formatDotCode(selectedDotData.DOTCODE)} */}
                        {selectedDotData.DotCodeFormated}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Requirements
                    {...{
                      tableDatabyDotCode,
                      partialMatchResultsState,
                    }}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Stack direction={"row"} my={1} gap={1} alignItems={"center"}>
                    <Box flexGrow={1}>
                      <Typography variant="p" component={"p"} color={"primary"}>
                        {selectedDotData.Title}
                      </Typography>
                    </Box>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography
                        variant="p"
                        component={"p"}
                        color={"text.grey"}
                        sx={{ display: "flex" }}
                      >
                        <CodeIcon />
                        Code
                      </Typography>
                      <Typography
                        variant="h6"
                        component={"h6"}
                        fontSize={"0.875rem"}
                      >
                        {/* {formatDotCode(selectedDotData.DOTCODE)} */}
                        {selectedDotData.DotCodeFormated}
                      </Typography>
                    </Stack>
                  </Stack>
                  <CodeTab
                    {...{
                      tableDatabyDotCode,
                      oapCodeData,
                      goeCodeData,
                      socCodeData,
                      rowData: selectedDotData,
                      oewsNationalM2022Data,
                    }}
                  />
                </TabPanel>
                <Box bottom={"10px"} right={"10px"} textAlign={"right"}>
                  <Button
                    variant="contained"
                    endIcon={<ArrowIcon />}
                    onClick={() => {
                      localStorage.setItem(
                        "transferableSkillData",
                        JSON.stringify({
                          filteredData: filteredData.slice(0, 100),
                          selectedItems,
                          selectedDotData,
                          DOTTitleViewDisplay,
                          value,
                          checkedState,
                          selectValue: selectValue.toString(),
                        })
                      );
                      redirectTo("/SOCGroupORSFilterTransferableSkill");
                    }}
                  >
                    ORS Evaluation
                  </Button>
                </Box>
              </Box>
            )}
            {/* <Stack
              spacing={1}
              className="bg-white"
              padding={1}
              borderRadius={"10px"}
              mt={"10px"}
            >
              <Stack padding={1}>
                <Box
                  sx={{
                    borderBottom: "1px solid #666672",
                    paddingBottom: "3px",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenComponent(!openComponent)}
                >
                  <Typography
                    variant="h6"
                    component={"h6"}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    Transferable Components{" "}
                    <Dropdown
                      width={"18px"}
                      height={"18px"}
                      style={{
                        transform: openComponent
                          ? "rotate(0)"
                          : "rotate(-90deg)",
                      }}
                    />
                  </Typography>
                </Box>
                <TransferableComponents
                  dbCurrentVersion={dbCurrentVersion}
                  selectedWorkHistory={selectedWorkHistory}
                  selectedDotData={selectedDotData}
                  openComponent={openComponent}
                  filteredData={filteredData}
                />
              </Stack>
            </Stack> */}
          </Grid>
          <Grid item xs={6}>
            <TableContainer
              component={Paper}
              sx={{
                paddingBottom: 0,
                paddingTop: 0,
                overflow: "auto",
                height: `${transferableSkillTable}px`,
                boxShadow: "none",
                paddingX: "10px",
                borderRadius: "10px",
              }}
            >
              {loader ? (
                <div
                  style={{
                    position: "relative",
                    height: "100vh",
                    width: "100%",
                  }}
                >
                  <Stack
                    sx={{
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 4,
                      backgroundColor: "#95959512",
                      borderRadius: "10px",
                    }}
                  >
                    <CircularProgress />
                  </Stack>
                </div>
              ) : (
                <>
                  <div className="mt-2">
                    <Box
                      sx={{ backgroundColor: "#F5F5F5" }}
                      borderRadius={"10px"}
                    >
                      <Stack padding={1}>
                        <Stack
                          direction={"row"}
                          width={"100%"}
                          justifyContent={"space-between"}
                          spacing={1}
                        >
                          <Typography
                            variant="p"
                            component={"p"}
                            color={"#666672"}
                          >
                            Total Results:
                          </Typography>
                          <Typography
                            variant="p"
                            component={"p"}
                            fontWeight={"bold"}
                          >
                            {/* {filteredData?.length || 0} */}
                            {filteredPassedData?.length || 0}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </div>
                  <Table
                    stickyHeader
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <CustomTableCell
                          align="left"
                          label="Code"
                          sortResultsKey="code"
                          sortOrder={sortOrder}
                          currentSortKey={selectedSortKey}
                          onSort={sortResults}
                          sortKey="code"
                        />
                        <CustomTableCell
                          align="left"
                          label="Title"
                          sortResultsKey="title"
                          sortOrder={sortOrder}
                          currentSortKey={selectedSortKey}
                          onSort={sortResults}
                          sortKey="title"
                        />
                        <CustomTableCell
                          align="left"
                          label="SVP"
                          sortResultsKey="svp"
                          sortOrder={sortOrder}
                          currentSortKey={selectedSortKey}
                          onSort={sortResults}
                          sortKey="svp"
                        />
                        <CustomTableCell
                          align="left"
                          label="Physical"
                          sortResultsKey="physical"
                          sortOrder={sortOrder}
                          currentSortKey={selectedSortKey}
                          onSort={sortResults}
                          sortKey="physical"
                        />
                        <CustomTableCell
                          align="left"
                          label="Employment Estimate"
                          sortResultsKey="employmentEstimate"
                          sortOrder={sortOrder}
                          currentSortKey={selectedSortKey}
                          onSort={sortResults}
                          sortKey="employmentEstimate"
                        />

                        <TableCell align="right">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {search &&
                        currentRecords &&
                        Array.isArray(currentRecords) &&
                        currentRecords.length > 0 &&
                        currentRecords?.map((row, index) => {
                          // filteredData &&
                          // Array.isArray(filteredData) && filteredData.length > 0 &&
                          // filteredData?.map((row, index) => {

                          if (!workHistoryData) return null;
                          const isMatched = workHistoryData.some(
                            (item) =>
                              item.dotcode === formatDotCode(row?.DOTCODE)
                          );
                          if (isMatched) return null;
                          return (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                "& td, & th": { paddingY: "4px !important" },
                                "&:hover": { backgroundColor: "#E6F3FB66" },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <span className="whitespace-nowrap">
                                  {row?.DotCodeFormated ||
                                    formatDotCode(row?.DOTCODE)}
                                  {/* {row?.DotCodeFormated} */}
                                </span>
                              </TableCell>
                              <TableCell align="left">{row.Title}</TableCell>
                              <TableCell align="left">{row.SVP}</TableCell>
                              <TableCell align="left">{row.Strength}</TableCell>
                              <TableCell align="left">
                                {/* {showEmployeeEstimate(row) &&
                                !isNaN(showEmployeeEstimate(row))
                                  ? Number(
                                      showEmployeeEstimate(row)
                                    ).toLocaleString("en-US")
                                  : "-"} */}
                                {row?.dotEstEmp ? Math.round(row?.dotEstEmp).toLocaleString("en-US") : "-"}
                              </TableCell>
                              <TableCell align="right">
                                <Stack
                                  direction={"row"}
                                  spacing={1}
                                  sx={{ justifyContent: "end" }}
                                  onClick={async () => {
                                    await getIndexDbData(
                                      formatDotCode(row["DOTCODE"])
                                    );
                                    setDotFormatedDot(
                                      formatDotCode(row["DOTCODE"])
                                    );
                                  }}
                                >
                                  <ToggleButton
                                    size="small"
                                    value="check"
                                    className={`${
                                      selectedItems[
                                        formatDotCode(row["DOTCODE"])
                                      ]
                                        ? "EyeIconOffButton"
                                        : "EyeIconButton"
                                    }`}
                                    sx={{ border: 0 }}
                                    selected={!!selectedItems[row["id"]]} // Use double negation to ensure boolean
                                    onChange={() => {
                                      handleToggleSelect(
                                        formatDotCode(row["DOTCODE"])
                                      );
                                    }}
                                  >
                                    {selectedItems[
                                      formatDotCode(row["DOTCODE"])
                                    ] ? (
                                      <SvgIcon
                                        component={EyeIconOff}
                                        inheritViewBox
                                      />
                                    ) : (
                                      <SvgIcon
                                        component={EyeIcon}
                                        inheritViewBox
                                      />
                                    )}
                                  </ToggleButton>
                                  {/* <ToggleButton
                                    size="small"
                                    value="check"
                                    className={`${
                                      selectedItems[row["DotCodeFormated"]]
                                        ? "EyeIconOffButton"
                                        : "EyeIconButton"
                                    }`}
                                    sx={{ border: 0 }}
                                    selected={!!selectedItems[row["id"]]} // Use double negation to ensure boolean
                                    onChange={() => {
                                      handleToggleSelect(
                                        row["DotCodeFormated"]
                                      );
                                    }}
                                  >
                                    {selectedItems[row["DotCodeFormated"]] ? (
                                      <SvgIcon
                                        component={EyeIconOff}
                                        inheritViewBox
                                      />
                                    ) : (
                                      <SvgIcon
                                        component={EyeIcon}
                                        inheritViewBox
                                      />
                                    )}
                                  </ToggleButton> */}
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                  {/* Add pagination controls */}
                 { search &&
                        currentRecords &&
                        Array.isArray(currentRecords) &&
                        currentRecords.length > 0 && <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "1rem",
                      borderTop: "1px solid #e0e0e0",
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Showing {indexOfFirstRecord + 1} to{" "}
                      {Math.min(
                        indexOfLastRecord,
                        filteredPassedData?.length || 0
                      )}{" "}
                      of {filteredPassedData?.length || 0} entries
                    </Typography>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Button
                        variant="outlined"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        size="small"
                      >
                        Previous
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handleNextPage}
                        disabled={
                          indexOfLastRecord >= (filteredPassedData?.length || 0)
                        }
                        size="small"
                      >
                        Next
                      </Button>
                    </Box>
                  </Box>}
                </>
              )}
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
      {open && (
        <TransferableSkillFilterModel
          open={open}
          getFilters={getFilters}
          handleClose={handleClose}
          handleSearch={handleSearch}
          appliedFilters={appliedFilters}
          notes={notes}
        />
      )}
    </>
  );
};

export default TransferableSkill;
