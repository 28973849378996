import {
  Box,
  Container,
  Stack,
  TextField,
  Grid,
  Button,
  IconButton,
  Typography,
  Tab,
  Tabs,
  Pagination,
  InputLabel,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/delet-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/icons/add-icon.svg";
import { ReactComponent as DuplicateIcon } from "../../assets/images/icons/duplicate-icon.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/images/icons/right-arrow-icon.svg";
import { useEffect, useRef, useState } from "react";
import {
  NCheck,
  OCheck,
  FCheck,
  CCheck,
  SCheck,
} from "../../Common/CustomCheckbox";
import { Link, useNavigate } from "react-router-dom";
import CustomSwitch from "../../Common/Switch/CustomSwitch";
import { useFormik } from "formik/dist";
import SortByDialog from "../HypotheticalAnalyzer/SortByDialog";
import AnalyzerFilters from "../HypotheticalAnalyzer/helperComponents/analyzerFilter";
import { onAddNewFilters } from "../HypotheticalAnalyzer/utils";
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import { handleGetRequest } from "../../services/GetTemplate";
import { useOrsSearchWorker } from "./../../hooks/useOrsSearchWorker";
import TopTabs from "./TopTabs";
import TabPanel from "./TabPanel";
import SummaryTab from "./SummaryTab";
import DemandTabPanel from "./DemandTabPanel";
import AllTabs from "./AllTabs";
import ORSInfoRow from "./ORSInfoRow";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HypotheticalToolSummaryORS = () => {
  let dbName = "veToolkitDB";
  let dbCurrentVersion;

  const localStorageNotes = localStorage.getItem("notes");
  const localStoragePaginatedValue = sessionStorage.getItem("paginatedValue");

  const [occupationsHeight, setOccupationsHeight] = useState();
  const [physicalTabHeight, setPhysicalTabHeight] = useState();
  const [value, setValue] = useState(0);
  const [actualOrsData, setActualOrsData] = useState([]);
  const [soc2018, setSoc2018] = useState([]);

  // data states
  const [orsPhysicalDemandData, setOrsPhysicalDemandData] = useState([]);
  const [orsCognitiveDemandData, setOrsCognitiveDemandData] = useState([]);
  const [orsEducationalDemandData, setOrsEducationalDemandData] = useState([]);
  const [orsEnviromentalDemandData, setOrsEnviromentalDemandData] = useState(
    []
  );
  const [orsFilterSummaryDemandData, setOrsFilterSummaryDemandData] = useState(
    []
  );
  const [orsFilterPhysicalDemandData, setOrsFilterPhysicalDemandData] =
    useState([]);
  const [orsFilterCognitiveDemandData, setOrsFilterCognitiveDemandData] =
    useState([]);
  const [orsFilterEducationalDemandData, setOrsFilterEducationalDemandData] =
    useState([]);
  const [orsFilterEnviromentalDemandData, setOrsFilterEnviromentalDemandData] =
    useState([]);
  const [oewsNationalM2022Data, setOewsNationalM2022Data] = useState([]);
  // to store the filtered Estimate Code
  const [selectedEstimateCode, setSelectedEstimateCode] = useState([]);
  // to store the result Estimate Code
  const [selectedFilteredOccupationsCode, setSelectedFilteredOccupationsCode] =
    useState(null);
  const [openSortByDialog, setOpenSortByDialog] = useState(false);
  const [searchFilters, setSearchFilters] = useState("");
  const [notes, setNotes] = useState(localStorageNotes || "");
  //filteredOccupations are use to show the results after searching and filtering occupations
  // on the basis of search text written in apply filters modal.
  const [filteredOccupations, setFilteredOccupations] = useState([]);
  //allfilteredOccupations are use to store the result of all filters
  const [allFilteredOccupations, setAllFilteredOccupations] = useState([]);
  const [orsFiltersData, setOrsFiltersData] = useState([]);
  const [counts, setCounts] = useState(0);
  const [showingFiltersId, setShowingFiltersId] = useState(null);
  const [currentPage, setCurrentPage] = useState(
    Number(localStoragePaginatedValue)
  );
  const [isLoading, setLoadingState] = useState(false);
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
  const [isDeleteloading, setIsDeleteloading] = useState(false);
  const [isAddNewloading, setIsAddNewloading] = useState(false);
  const [movePaginationToLast, setMovePaginationToLast] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
   // Add a new state to store the original summary data
const [originalSummaryData, setOriginalSummaryData] = useState([]);

  const textareaRef = useRef(null);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const { result, runOrsSearchWorker, runOrsSortingWorker } =
    useOrsSearchWorker();

    console.log("orsFilterEducationalDemandData",orsFilterSummaryDemandData)

  // sorting and filtering
  const [filtersSorting, setFiltersSorting] = useState({
    sorting: 1,
    filters: "",
    searchText: "",
  });

  const handleFocus = () => {
    // This function will focus the input field
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [notes]);

  useEffect(() => {}, [filteredOccupations]);

  useEffect(() => {
    localStorage.setItem("SearchRequest", "ORS");
    var openRequestver = window.indexedDB.open(dbName);

    openRequestver.onsuccess = function (event) {
      var dbver = event.target.result;
      var currentVersion = dbver.version;
      dbCurrentVersion = currentVersion;

      // Perform other operations as needed
    };

    openRequestver.onerror = function (event) {
      console.error("Error opening database:", event.target.error);
    };

    // Cleanup function
    return () => {
      openRequestver?.result?.close();
    };
  }, []);

  useEffect(() => {
    if (!selectedFilteredOccupationsCode || !Array.isArray(filteredOccupations))
      return;
    const items = filteredOccupations?.filter((item) =>
      selectedFilteredOccupationsCode.includes(item.Soc2018Code)
    );
    sessionStorage.setItem("ORSResults", JSON.stringify(items ? items : []));
  }, [selectedFilteredOccupationsCode]);

  useEffect(() => {
    const hypotheticalFilters = localStorage.getItem("hypotheticalFilters");
    if (hypotheticalFilters) {
      const data = JSON.parse(hypotheticalFilters);
      setOrsFiltersData(data);
      setCounts(data.count);
    }
  }, []);

  useEffect(() => {
    if (
      isFirstTimeLoading &&
      (!orsCognitiveDemandData ||
        !orsCognitiveDemandData.length ||
        !orsPhysicalDemandData ||
        !orsPhysicalDemandData.length ||
        !orsEducationalDemandData ||
        !orsEducationalDemandData.length ||
        !orsEnviromentalDemandData ||
        !orsEnviromentalDemandData.length ||
        !orsFilterSummaryDemandData ||
        !orsFilterSummaryDemandData.length)
    )
      return;
    setIsFirstTimeLoading(false);
  }, [
    orsCognitiveDemandData,
    orsPhysicalDemandData,
    orsEducationalDemandData,
    orsEnviromentalDemandData,
    orsFilterSummaryDemandData,
  ]);

  useEffect(() => {
    if (
      isFirstTimeLoading ||
      !actualOrsData.length ||
      !oewsNationalM2022Data.length
    )
      return;

    const ORSFiltersData = JSON.parse(sessionStorage.getItem("ORSFiltersData"));
    const localHypothicalAnalyzer = JSON.parse(
      localStorage.getItem("hypotheticalFilters")
    );
    if (ORSFiltersData && Object.keys(ORSFiltersData).length) {
      let appliedCodes = ORSFiltersData?.filters ? ORSFiltersData?.filters : [];
      // Check if "00064" exists and add "00065" if it does
      // if (appliedCodes.includes('00064')) {
      //   appliedCodes = [...appliedCodes, '00065'];
      // }
      renderedAppliedFilters({
        localFilters: ORSFiltersData,
        seletedEstimateCode: appliedCodes,
      });
      setSelectedEstimateCode(appliedCodes);
    } else if (localHypothicalAnalyzer) {
      const filters = localHypothicalAnalyzer.orsFilters[currentPage - 1];
      if (!filters) return;
      // Formting the data to match the ORSFiltersData
      const filtersData = {
        filters: filters.filters.OrsFilters,
        results: filters.results.OrsResults || [],
        id: filters.id,
      };
      const appliedCodes = filters.filters.OrsFilters
        ? filters.filters.OrsFilters
        : [];
      renderedAppliedFilters({
        localFilters: filtersData,
        seletedEstimateCode: appliedCodes,
      });
      setSelectedEstimateCode(appliedCodes);
    }
  }, [isFirstTimeLoading, actualOrsData, oewsNationalM2022Data]);

  useEffect(() => {
    const updateHeight = () => {
      const innerHeight = window.innerHeight;
      setOccupationsHeight(innerHeight - 173 - 47 - 30);
      setPhysicalTabHeight(innerHeight - 340);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [window.innerHeight]); // eslint-disable-line

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchFilters("");
    OnFiltersSearch("");
    // setIsFirstTimeLoading(true);
    handleFocus();
  };

  useEffect(() => {
    fetch("OrsPhysicalDemands.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOrsPhysicalDemandData(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    fetch("CofnitiveOrsDemands.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOrsCognitiveDemandData(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    fetch("OrsEducationalTrainingDemands.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOrsEducationalDemandData(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    fetch("OrsEnvironmentalDemands.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOrsEnviromentalDemandData(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["ORSMasterTable"], "readonly");
      const objectStore = transaction.objectStore("ORSMasterTable");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        setActualOrsData(getData.result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("ORSMasterTable", {
        autoIncrement: true,
      });
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (loadData) {
      setLoadData(false);
      getTableData();
    }
  }, [loadData]);

  useEffect(() => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["2010To2018Soc"], "readonly");
      const objectStore = transaction.objectStore("2010To2018Soc");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        setSoc2018(getData.result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("2010To2018Soc", {
        autoIncrement: true,
      });
    };

    // Cleanup function to close the database connection
    return () => {
      request.result?.close();
    };
  }, []); // eslint-disable-line

  const onSave = async () => {
    setIsAddNewloading(true);
    await onAddNewFilters();
    setLoadData(true);
    setIsAddNewloading(false);
  };

  const onAddnew = async () => {
    setIsAddNewloading(true);
    await onAddNewFilters();

    // sessionStorage.removeItem("paginatedValue");
    sessionStorage.removeItem("selectedFiltersId");

    sessionStorage.removeItem("DOTFiltersData");
    sessionStorage.removeItem("ORSFiltersData");
    localStorage.removeItem("notes");
    setNotes("");

    await onAddNewFilters(true);
    setDefaultFilters();
    setMovePaginationToLast(true);
    setLoadData(true);
    setIsAddNewloading(false);
  };

  const onDuplicate = async () => {
    localStorage.setItem("duplicate", true);
    localStorage.setItem("request", JSON.stringify("ORS"));
    setIsAddNewloading(true);
    await onAddNewFilters();

    sessionStorage.removeItem("paginatedValue");
    sessionStorage.removeItem("selectedFiltersId");
    localStorage.removeItem("hypotheticalFilters");

    await onAddNewFilters();
    setMovePaginationToLast(true);
    setLoadData(true);
    setIsAddNewloading(false);
  };

  const setDefaultFilters = () => {
    const updateState = (state) => {
      return state.map((button) => ({ ...button, isChecked: false }));
    };
    const updateSummary = (state) => {
      const newData = state.map((button) => {
        const subtitles = button.subtitles.map((subtitle) => {
          return { ...subtitle, isChecked: false };
        });
        return { ...button, subtitles };
      });
      return newData;
    };

    setOrsFilterSummaryDemandData((prevState) =>
      updateSummary(prevState, orsFilterSummaryDemandData)
    );
    setOrsFilterPhysicalDemandData((prevState) =>
      updateState(prevState, orsFilterPhysicalDemandData)
    );
    setOrsFilterCognitiveDemandData((prevState) =>
      updateState(prevState, orsFilterCognitiveDemandData)
    );
    setOrsFilterEducationalDemandData((prevState) =>
      updateState(prevState, orsFilterEducationalDemandData)
    );
    setOrsFilterEnviromentalDemandData((prevState) =>
      updateState(prevState, orsFilterEnviromentalDemandData)
    );
    setFilteredOccupations([]);
    setAllFilteredOccupations([]);
  };

  const getTableData = async () => {
    const hypotheticalFilters = localStorage.getItem("hypotheticalFilters");
    if (hypotheticalFilters) {
      const data = JSON.parse(hypotheticalFilters);
      setOrsFiltersData(data);
      setCounts(data.count);
    }

    const profileId = Number(localStorage.getItem("profileId"));
    const response = await handleGetRequest(`filter?profileId=${profileId}`);
    // have to map this values to showing data
    if (response?.data) {
      if (!response?.data?.count) {
        sessionStorage.removeItem("paginatedValue");
        sessionStorage.removeItem("selectedFiltersId");
      }
      localStorage.setItem(
        "hypotheticalFilters",
        JSON.stringify(response?.data)
      );
      setOrsFiltersData(response?.data);
      setCounts(response.data.count);

      if (movePaginationToLast || currentPage === 0) {
        const filters = response?.data?.orsFilters;
        setCurrentPage(filters.length);
        sessionStorage.setItem("paginatedValue", filters.length);
        sessionStorage.setItem(
          "selectedFiltersId",
          filters[filters.length - 1]?.id
        );
        if (movePaginationToLast) {
          localStorage.setItem(
            "notes",
            filters[filters.length - 1].filters.notes
          );
        }
      }
    }
    setMovePaginationToLast(false);
  };

  const onDelete = async () => {
    if (!orsFiltersData || !orsFiltersData.orsFilters) return;
    setIsDeleteloading(true);
    const asd = orsFiltersData.orsFilters[currentPage - 1];

    const profileId = Number(localStorage.getItem("profileId"));
    if (asd.id)
      await handleDeleteRequest(
        `filter?filterId=${asd.id}&profileId=${profileId}`
      );

    const updateState = (state) => {
      return state.map((button) => ({ ...button, isChecked: false }));
    };
    const updateSummary = (state) => {
      const newData = state.map((button) => {
        const subtitles = button.subtitles.map((subtitle) => {
          return { ...subtitle, isChecked: false };
        });
        return { ...button, subtitles };
      });
      return newData;
    };

    setOrsFilterSummaryDemandData((prevState) =>
      updateSummary(prevState, orsFilterSummaryDemandData)
    );
    setOrsFilterPhysicalDemandData((prevState) =>
      updateState(prevState, orsFilterPhysicalDemandData)
    );
    setOrsFilterCognitiveDemandData((prevState) =>
      updateState(prevState, orsFilterCognitiveDemandData)
    );
    setOrsFilterEducationalDemandData((prevState) =>
      updateState(prevState, orsFilterEducationalDemandData)
    );
    setOrsFilterEnviromentalDemandData((prevState) =>
      updateState(prevState, orsFilterEnviromentalDemandData)
    );
    setFilteredOccupations([]);
    setAllFilteredOccupations([]);
    onChangePagination({}, 1);
    await getTableData();
    setIsDeleteloading(false);
  };

  useEffect(() => {
    fetch("HypotheticalSummeryCodes.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const formattedSummaryData = formateSummaryData(data);
      setOrsFilterSummaryDemandData(formattedSummaryData);
      return;
    });
  }, [ ]); // eslint-disable-line

  useEffect(() => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["OewsNationalM2022"], "readonly");
      const objectStore = transaction.objectStore("OewsNationalM2022");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        setOewsNationalM2022Data(getData.result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OewsNationalM2022", {
        autoIncrement: true,
      });
    };
  }, []); // eslint-disable-line

  // Updating filters data
  useEffect(() => {
    setOrsFilterPhysicalDemandData(orsPhysicalDemandData);
  }, [orsPhysicalDemandData]);
  useEffect(() => {
    setOrsFilterCognitiveDemandData(orsCognitiveDemandData);
  }, [orsCognitiveDemandData]);
  useEffect(() => {
    setOrsFilterEducationalDemandData(orsEducationalDemandData);
  }, [orsEducationalDemandData]);
  useEffect(() => {
    setOrsFilterEnviromentalDemandData(orsEnviromentalDemandData);
  }, [orsEnviromentalDemandData]);


  useEffect(() => {
    /**
     * On filterSorting (contains filters, search, and sorting order) changes
     * filter all the data
     */
    setLoadingState(true);
    const data = {
      sorting: true,
      data: allFilteredOccupations,
      filtersSorting,
      oewsNationalM2022Data,
    };
    runOrsSortingWorker(data);
  }, [filtersSorting]);

  const onViewDetailsClick = (item) => {
    navigate("/SOCDOTGroupInfo", { state: item });
  };

  const formateSummaryData = (data) => {
    const uniqueTitles = [
      ...new Set(data.map((item) => item.HypotheticalOrsSummerySection)),
    ];

    // Group RenameTitleTo by HypotheticalOrsSummerySection
    const groupedData = uniqueTitles.map((title) => ({
      HypotheticalOrsSummerySection: title,
      selectedEstimateCode: null,
      subtitles: data
        .filter((item) => item.HypotheticalOrsSummerySection === title)
        .map(({ RenameTitleTo, EstimateCode }) => {
          return {
            RenameTitleTo,
            EstimateCode,
            // svp 1 & 2 has 2 estimateCode so first one should be checked true by default
            isChecked: EstimateCode === "00065" ? true : false,
          };
        }),
    }));
    return groupedData;
  };

  function getFirstCharOfLastWord(str) {
    let words = str.split(" ");
    let lastWord = words[words.length - 1];
    return lastWord.charAt(0);
  }

  // Function to render radio button based on value
  const getCheckBox = (value, name, subtitle) => {
    switch (value) {
      case "N":
        return (
          <NCheck
            sx={{ paddingY: "2px", paddingX: "5px" }}
            name={name}
            value={value || ""}
            checked={selectedEstimateCode?.includes(subtitle.EstimateCode)}
            onChange={() => handleRadioButtonChange(subtitle.EstimateCode)}
          />
        );
      case "S":
        return (
          <SCheck
            sx={{ paddingY: "2px", paddingX: "5px" }}
            name={name}
            value={value || ""}
            checked={selectedEstimateCode?.includes(subtitle.EstimateCode)}
            onChange={() => handleRadioButtonChange(subtitle.EstimateCode)}
          />
        );
      case "O":
        return (
          <OCheck
            sx={{ paddingY: "2px", paddingX: "5px" }}
            name={name}
            value={value || ""}
            checked={selectedEstimateCode?.includes(subtitle.EstimateCode)}
            onChange={() => handleRadioButtonChange(subtitle.EstimateCode)}
          />
        );
      case "F":
        return (
          <FCheck
            sx={{ paddingY: "2px", paddingX: "5px" }}
            name={name}
            value={value || ""}
            checked={selectedEstimateCode?.includes(subtitle.EstimateCode)}
            onChange={() => handleRadioButtonChange(subtitle.EstimateCode)}
          />
        );
      case "C":
        return (
          <CCheck
            sx={{ paddingY: "2px", paddingX: "5px" }}
            name={name}
            value={value || ""}
            checked={selectedEstimateCode?.includes(subtitle.EstimateCode)}
            onChange={() => handleRadioButtonChange(subtitle.EstimateCode)}
          />
        );
      default:
        return null;
    }
  };

  function findTotalEmp(searchString, data) {
    let totalEmp = 0;
    data?.forEach((item) => {
      const searchStringWithDash =
        searchString.slice(0, 2) + "-" + searchString.slice(2);
      if (item.OccCode.includes(searchStringWithDash)) {
        totalEmp += Number(item.TotalEmp);
      }
    });
    return totalEmp;
  }

  const onSaveResultInLocalStorage = (filters, results, value) => {
    let obj = {
      filters,
      results: {},
    };

    sessionStorage.removeItem("ORSFiltersData");
    sessionStorage.setItem("ORSFiltersData", JSON.stringify(obj));
    if (value) {
      sessionStorage.setItem("paginatedValue", value);
      setCurrentPage(value);
      const data = orsFiltersData.orsFilters[value - 1];
      obj = {
        filters: data?.filters?.DotFilters,
        results: data?.results?.DotResults,
      };

      sessionStorage.setItem("DOTFiltersData", JSON.stringify(obj));
    }
  };

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    if (result?.isSorting) {
      delete result.isSorting;
      setFilteredOccupations(result.result);
    } else {
      delete result.isSorting;
      setAllFilteredOccupations(result.result);
      setFilteredOccupations(result.result);
    }
    setLoadingState(false);
  }, [result]);

  const searchLocally = ({ seletedEstimateCode = null, saveData = false }) => {
    setLoadingState(true);
    //  debugger
    let filteredObjects;
    let selectedEstimateCodeLocal = selectedEstimateCode;
    if (seletedEstimateCode) {
      selectedEstimateCodeLocal = seletedEstimateCode;
    }

    if (!selectedEstimateCodeLocal || !selectedEstimateCodeLocal.length) {
      // if no filter is selected
      filteredObjects = [];
    } else {
      // filteredObjects = actualOrsData.filter((obj) =>
      //   selectedEstimateCodeLocal.includes(obj.EstimateCode)
      // );
    }

    const preSelectedResults = JSON.parse(sessionStorage.getItem("ORSResults"));

    if (preSelectedResults && preSelectedResults.length) {
      const Soc2018Code = preSelectedResults.map((item) => item.Soc2018Code);
      setSelectedFilteredOccupationsCode(Soc2018Code);
    }

    const estimateCodeWithSvpCase = [...selectedEstimateCodeLocal];
    if (
      estimateCodeWithSvpCase.includes("00064") &&
      !estimateCodeWithSvpCase.includes("00065")
    ) {
      estimateCodeWithSvpCase.push("00065");
    }
    const data = {
      dataset: actualOrsData,
      desiredEstimateCodes: estimateCodeWithSvpCase,
      oewsNationalM2022Data,
      preSelectedResults,
    };
    runOrsSearchWorker(data);
    // going to save the filters and results in local storage
    onSaveResultInLocalStorage(selectedEstimateCodeLocal, filteredObjects);
    if (saveData) {
      onSave();
    }
  };

  const onSearch = ({ seletedEstimateCode, saveData = false }) => {
    setDisabledSaveButton(true);
    searchLocally({ seletedEstimateCode, saveData });
  };

  function filterByDefinition(array, searchText) {
    // Convert search text to lowercase for case insensitive search
    const lowerCaseSearchText = searchText.toLowerCase();

    // Filter the array
    let filteredArray = array.filter((item) => {
      // Ensure the item has a definition key and it's a string
      if (item.Defination && typeof item.Defination === "string") {
        // Convert the Defination to lowercase and check if it includes the search text
        return item.Defination.toLowerCase().includes(lowerCaseSearchText);
      }
      return false;
    });
    filteredArray = filteredArray.map((button) => {
      if (selectedEstimateCode.includes(button.EstimateCode)) {
        button.isChecked = true;
      }
      return button;
    });
    return filteredArray;
  }

 

// When fetching the initial summary data, store it in both states
useEffect(() => {
  fetch("HypotheticalSummeryCodes.json").then(async (response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const formattedSummaryData = formateSummaryData(data);
    setOrsFilterSummaryDemandData(formattedSummaryData);
    setOriginalSummaryData(formattedSummaryData); // Store original data
    return;
  });
}, []); // eslint-disable-line
  const OnFiltersSearch = (text) => {
    // filtering data from All physical
    const phyFilteredData = filterByDefinition(orsPhysicalDemandData, text);
    const cogFilteredData = filterByDefinition(orsCognitiveDemandData, text);
    const envFilteredData = filterByDefinition(orsEnviromentalDemandData, text);
    const eduFilteredData = filterByDefinition(orsEducationalDemandData, text);

     // For summary data, use the original data for filtering
  // For summary data, use the original data for filtering
  const summaryFilteredData = text 
    ? originalSummaryData.filter(section => {
        // Check if section title matches search text
        const sectionMatch = section.HypotheticalOrsSummerySection.toLowerCase().includes(text.toLowerCase());
        
        // Filter subtitles that match search text
        const matchingSubtitles = section.subtitles.filter(subtitle => 
          subtitle.RenameTitleTo.toLowerCase().includes(text.toLowerCase())
        );

        // Return true if either section title matches or there are matching subtitles
        return sectionMatch || matchingSubtitles.length > 0;
      }).map(section => {
        // Get current checked state from orsFilterSummaryDemandData
        const currentSection = orsFilterSummaryDemandData.find(
          s => s.HypotheticalOrsSummerySection === section.HypotheticalOrsSummerySection
        );

        return {
          ...section,
          // Only filter subtitles if section title doesn't match
          subtitles: section.HypotheticalOrsSummerySection.toLowerCase().includes(text.toLowerCase())
            ? section.subtitles.map(subtitle => {
                // Find matching subtitle in current data to preserve isChecked state
                const currentSubtitle = currentSection?.subtitles.find(
                  s => s.EstimateCode === subtitle.EstimateCode
                );
                return {
                  ...subtitle,
                  isChecked: currentSubtitle?.isChecked || false
                };
              })
            : section.subtitles.filter(subtitle => 
                subtitle.RenameTitleTo.toLowerCase().includes(text.toLowerCase())
              ).map(subtitle => {
                // Find matching subtitle in current data to preserve isChecked state
                const currentSubtitle = currentSection?.subtitles.find(
                  s => s.EstimateCode === subtitle.EstimateCode
                );
                return {
                  ...subtitle,
                  isChecked: currentSubtitle?.isChecked || false
                };
              })
        };
      })
    : originalSummaryData.map(section => {
        // Get current checked state from orsFilterSummaryDemandData
        const currentSection = orsFilterSummaryDemandData.find(
          s => s.HypotheticalOrsSummerySection === section.HypotheticalOrsSummerySection
        );
        
        return {
          ...section,
          subtitles: section.subtitles.map(subtitle => {
            // Find matching subtitle in current data to preserve isChecked state
            const currentSubtitle = currentSection?.subtitles.find(
              s => s.EstimateCode === subtitle.EstimateCode
            );
            return {
              ...subtitle,
              isChecked: currentSubtitle?.isChecked || false
            };
          })
        };
      });

  setOrsFilterSummaryDemandData(summaryFilteredData);
    setOrsFilterPhysicalDemandData(phyFilteredData);
    setOrsFilterCognitiveDemandData(cogFilteredData);
    setOrsFilterEnviromentalDemandData(envFilteredData);
    setOrsFilterEducationalDemandData(eduFilteredData);
    setSearchFilters(text);
  };

  console.log("originalSummaryData",originalSummaryData)

  const handleValueToggle = (value, toggle) => {
    const arr = selectedEstimateCode;
    if (toggle) {
      // Add value if it doesn't exist
      if (!arr.includes(value)) {
        arr.push(value);
      }
    } else {
      // Remove value if it exists
      const index = arr.indexOf(value);
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    setSelectedEstimateCode([...arr]);
    handleFocus();
  };

  const handleResultToggle = (item) => {
    let arr = selectedFilteredOccupationsCode;

    const value = item.Soc2018Code;

    if (!arr) {
      arr = [value];
    } else if (!item.isChecked) {
      // Add value if it doesn't exist
      if (!arr.includes(value)) {
        arr.push(value);
      }
    } else {
      // Remove value if it exists
      const index = arr.indexOf(value);
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    setSelectedFilteredOccupationsCode([...arr]);
    setFilteredOccupations((prevItems) => {
      const updatedState = prevItems.map((prevItem) =>
        prevItem.Id === item.Id
          ? { ...prevItem, isChecked: !prevItem.isChecked }
          : prevItem
      );
      const sortedState = updatedState.sort((a, b) => {
        if (a.isChecked === b.isChecked) {
          return 0;
        } else if (a.isChecked === true) {
          return -1;
        } else if (b.isChecked === true) {
          return 1;
        } else if (a.isChecked === false) {
          return -1;
        } else if (b.isChecked === false) {
          return 1;
        } else {
          return 0;
        }
      });
      // putFiltersResult(selectedEstimateCode, sortedState);'
      onSaveResultInLocalStorage(selectedEstimateCode, sortedState);
      return sortedState;
    });

    setAllFilteredOccupations((prevItems) => {
      const updatedState = prevItems.map((prevItem) =>
        prevItem.Id === item.Id
          ? { ...prevItem, isChecked: !prevItem.isChecked }
          : prevItem
      );
      const sortedState = updatedState.sort((a, b) => {
        if (a.isChecked === b.isChecked) {
          return 0;
        } else if (a.isChecked === true) {
          return -1;
        } else if (b.isChecked === true) {
          return 1;
        } else if (a.isChecked === false) {
          return -1;
        } else if (b.isChecked === false) {
          return 1;
        } else {
          return 0;
        }
      });
      return sortedState;
    });
    setDisabledSaveButton(false);
  };

  function toggleValueInArray(array, value) {
    const index = array.indexOf(value);
    if (index === -1) {
      // Value is not present, add it
      array.push(value);
    } else {
      // Value is present, remove it
      array.splice(index, 1);
    }
    return array;
  }

  const handleRadioButtonChange = (estimateCode) => {
    const updatedEsCode = toggleValueInArray(
      selectedEstimateCode,
      estimateCode
    );
    setSelectedEstimateCode([...updatedEsCode]);
    handleFocus();
  };

  const handleToggleChange = (EstimateCode, isChecked) => {
    const updateState = (state) => {
      return state.map((button) =>
        button.EstimateCode === EstimateCode ? { ...button, isChecked } : button
      );
    };
    const updateSummary = (state) => {
      const newData = state.map((button) => {
        const subtitles = button.subtitles.map((subtitle) => {
          return subtitle.EstimateCode === EstimateCode
            ? { ...subtitle, isChecked }
            : subtitle;
        });
        return { ...button, subtitles };
      });
      if (EstimateCode === "00064") {
        newData[0].subtitles[1].isChecked = isChecked;
      }
      return newData;
    };

    setOrsFilterSummaryDemandData((prevState) =>
      updateSummary(prevState, orsFilterSummaryDemandData)
    );
    setOrsFilterPhysicalDemandData((prevState) =>
      updateState(prevState, orsFilterPhysicalDemandData)
    );
    setOrsFilterCognitiveDemandData((prevState) =>
      updateState(prevState, orsFilterCognitiveDemandData)
    );
    setOrsFilterEducationalDemandData((prevState) =>
      updateState(prevState, orsFilterEducationalDemandData)
    );
    setOrsFilterEnviromentalDemandData((prevState) =>
      updateState(prevState, orsFilterEnviromentalDemandData)
    );

    handleValueToggle(EstimateCode, isChecked);
  };

  const onChangePagination = async (e, value) => {
    // if (!orsFiltersData || !orsFiltersData.orsFilters) return;
    setLoadingState(true);
    const asd = orsFiltersData.orsFilters[value - 1];
    const localFilters = {
      filters: asd?.filters?.OrsFilters,
      results: asd?.results?.OrsResults,
      id: asd?.id,
    };
    const seletedEstimateCode = asd?.filters?.OrsFilters
      ? [...asd?.filters?.OrsFilters]
      : [];
    setSelectedEstimateCode(seletedEstimateCode);
    handleFocus();
    const note = asd.filters.notes;
    setNotes(note);
    localStorage.setItem("notes", note);
    let results = asd?.results?.OrsResults;

    if (results) {
      results = results.map((data) => {
        const totalEmp = findTotalEmp(data.Soc2018Code, oewsNationalM2022Data);
        return {
          ...data,
          totalEmp,
        };
      });
    }
    sessionStorage.setItem(
      "ORSResults",
      JSON.stringify(results ? results : [])
    );
    setFilteredOccupations(results ? results : []);
    setAllFilteredOccupations(results ? results : []);

    renderedAppliedFilters({ value, localFilters, seletedEstimateCode });
    sessionStorage.setItem("selectedFiltersId", asd?.id);
  };

  const renderedAppliedFilters = ({
    value,
    localFilters,
    seletedEstimateCode,
  }) => {
    const filteredData = localFilters || orsFiltersData.orsFilters[value - 1];
    if (!filteredData) {
      setDefaultFilters();
      return;
    }

    setShowingFiltersId(filteredData.id);

    const updateState = (state) => {
      return state.map((button) => {
        return filteredData.filters?.includes(button.EstimateCode)
          ? { ...button, isChecked: true }
          : { ...button, isChecked: false };
      });
    };
    const updateSummary = (state) => {
      const newData = state.map((button) => {
        const subtitles = button.subtitles.map((subtitle) => {
          return filteredData.filters?.includes(subtitle.EstimateCode)
            ? { ...subtitle, isChecked: true }
            : { ...subtitle, isChecked: false };
        });
        return { ...button, subtitles };
      });
      if (filteredData.filters?.includes("00064")) {
        newData[0].subtitles[1].isChecked = false;
      }
      return newData;
    };

    setOrsFilterSummaryDemandData((prevState) =>
      updateSummary(prevState, orsFilterSummaryDemandData)
    );

    setOrsFilterPhysicalDemandData((prevState) =>
      updateState(prevState, orsFilterPhysicalDemandData)
    );
    setOrsFilterCognitiveDemandData((prevState) =>
      updateState(prevState, orsFilterCognitiveDemandData)
    );
    setOrsFilterEducationalDemandData((prevState) =>
      updateState(prevState, orsFilterEducationalDemandData)
    );
    setOrsFilterEnviromentalDemandData((prevState) =>
      updateState(prevState, orsFilterEnviromentalDemandData)
    );
    setFilteredOccupations(filteredData.results);
    setAllFilteredOccupations(filteredData.results);
    onSaveResultInLocalStorage(
      localFilters.filters,
      filteredData.results,
      value
    );
    onSearch({ seletedEstimateCode });
  };

  const handleCloseSortByDialog = () => {
    setOpenSortByDialog(false);
  };

  const [isFocused, setIsFocused] = useState(true);

  const PaginatedResults = () => (
    <Pagination
      count={counts || 0}
      color="primary"
      page={Number(currentPage)}
      onChange={onChangePagination}
    />
  );

  function getFirstCharOfLastWord(str) {
    let words = str.split(" ");
    let lastWord = words[words.length - 1];
    return lastWord.charAt(0);
  }

  const formik = useFormik({
    initialValues: {
      svp: false,
      noDriving: false,
      humidity: false,
      fastPace: false,
      sedentary: false,
      wetness: false,
      hearing: false,
      light: false,
      movingMachinery: false,
      quietNoise: false,
      vision: false,
      medium: false,
      srt: false,
      farVision: false,
      heavy: false,
      extremeHeat: false,
      prodPace: false,
      hazardousContaminants: false,
      noPublic: false,
      noFarVision: false,
      ladderRope: false,
      reachBilateral: "",
      overheadBilateral: "",
      handling: "",
      fingering: "",
      feeling: "",
      footControls: "",
      rampStairs: "",
      balance: "",
      stoop: "",
      crouch: "",
      kneel: "",
      crawl: "",
    },
    onSubmit: async (data) => {},
  });

  return (
    <>
      <Container
        maxWidth={false}
        sx={{ paddingTop: "80px", paddingBottom: 2, px: { xs: "10px" } }}
      >
        {actualOrsData && actualOrsData.length ? (
          <Grid container spacing={"10px"}>
            <Grid item xs={9}>
              <Box className="bg-white" padding={2} borderRadius={"10px"}>
                <form onSubmit={formik.handleSubmit}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    sx={{ width: "100%" }}
                  >
                    <Typography variant="h6">Filter</Typography>
                    <Stack
                      direction={"row"}
                      gap={1}
                      className="flex flex-wrap ml-1"
                    >
                      <div className="flex gap-1">
                        {isFocused ? (
                          <TextField
                            inputRef={inputRef}
                            hiddenLabel
                            size="small"
                            variant="filled"
                            placeholder="Search"
                            autoFocus
                            value={searchFilters}
                            className="w-40"
                            onChange={(e) => OnFiltersSearch(e.target.value)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  {searchFilters === "" ? (
                                    <SearchIcon />
                                  ) : (
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setSearchFilters("");
                                        OnFiltersSearch("");
                                      }}
                                    >
                                      X
                                    </div>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        ) : (
                          <Box>
                            <IconButton
                              className="EyeIconButton"
                              sx={{
                                padding: "12px",
                                backgroundColor: "#F5F5F5 !important",
                              }}
                              onClick={() => setIsFocused(true)}
                            >
                              <SearchIcon />
                            </IconButton>
                          </Box>
                        )}
                        {/* top tabs */}
                        <TopTabs
                          value={value}
                          handleChange={handleChange}
                          a11yProps={a11yProps}
                        />
                      </div>
                    </Stack>
                  </Stack>
                  {/* Summary Tab */}

                  <SummaryTab
                    orsFilterSummaryDemandData={orsFilterSummaryDemandData}
                    onSearch={onSearch}
                    notes={notes}
                    setNotes={setNotes}
                    getCheckBox={getCheckBox}
                    getFirstCharOfLastWord={getFirstCharOfLastWord}
                    onAddnew={onAddnew}
                    onDuplicate={onDuplicate}
                    onDelete={onDelete}
                    isAddNewloading={isAddNewloading}
                    isDeleteloading={isDeleteloading}
                    physicalTabHeight={physicalTabHeight}
                    handleToggleChange={handleToggleChange}
                    PaginatedResults={PaginatedResults}
                    textareaRef={textareaRef}
                    value={value}
                    index={0}
                  />

                  {/* <TabPanel value={value} index={1}>
                    <Box
                      sx={{
                        height: `${physicalTabHeight}px`,
                        overflow: "auto",
                      }}
                    >
                      <Grid
                        container
                        rowSpacing={"8px"}
                        columnSpacing={"15px"}
                        sx={{
                          "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                            borderRight: "1px solid #666672",
                            paddingRight: "15px",
                          },
                        }}
                      >
                        {orsFilterPhysicalDemandData.map((data, index) => (
                          <Grid item xs={6} key={index}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                "&:hover": { backgroundColor: "#E6F3FB66" },
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                {data.Defination}
                              </Typography>
                              <CustomSwitch
                                name="choiceOfSitting1"
                                id="choiceOfSitting1"
                                value={!!data.isChecked}
                                checked={!!data.isChecked}
                                onChange={(e) =>
                                  handleToggleChange(
                                    data.EstimateCode,
                                    !data.isChecked
                                  )
                                }
                              />
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Stack
                      direction={"row"}
                      spacing={"10px"}
                      alignItems={"flex-end"}
                    >
                      <Box flexGrow={1}>
                        <textarea
                          ref={textareaRef}
                          value={notes}
                          onChange={(e) => {
                            localStorage.setItem("notes", e.target.value);
                            setNotes(e.target.value);
                          }}
                          style={{
                            padding: "10px 12px 8px",
                            backgroundColor: "rgb(229, 231, 235)",
                            width: "100%",
                          }}
                          placeholder="Notes"
                        />
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => onSearch({ saveData: true })}
                          startIcon={<SearchIcon />}
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent={"space-between"}
                      className="HypotheticalPagination"
                      mt={"10px"}
                      sx={{
                        borderTop: "1px solid #666672",
                        paddingTop: "10px",
                      }}
                    >
                      {PaginatedResults()}
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={onDelete}
                        >
                          {isDeleteloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "red" }}
                            />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<DuplicateIcon />}
                          onClick={onDuplicate}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25 }}
                            />
                          ) : (
                            "Duplicate"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={onAddnew}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "white" }}
                            />
                          ) : (
                            "Add New"
                          )}
                        </Button>
                      </Stack>
                    </Stack>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Box
                      sx={{
                        height: `${physicalTabHeight}px`,
                        overflow: "auto",
                      }}
                    >
                      <Grid
                        container
                        rowSpacing={"8px"}
                        columnSpacing={"15px"}
                        sx={{
                          "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                            borderRight: "1px solid #666672",
                            paddingRight: "15px",
                          },
                        }}
                      >
                        {orsFilterCognitiveDemandData.map((data, index) => (
                          <Grid item xs={6} key={index}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                "&:hover": { backgroundColor: "#E6F3FB66" },
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                {data.Defination}
                              </Typography>
                              <CustomSwitch
                                name="choiceOfSitting5"
                                id="choiceOfSitting5"
                                value={!!data.isChecked}
                                checked={!!data.isChecked}
                                onChange={(e) =>
                                  handleToggleChange(
                                    data.EstimateCode,
                                    !data.isChecked
                                  )
                                }
                              />
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Stack
                      direction={"row"}
                      spacing={"10px"}
                      alignItems={"flex-end"}
                    >
                      <Box flexGrow={1}>
                        <textarea
                          ref={textareaRef}
                          value={notes}
                          onChange={(e) => {
                            localStorage.setItem("notes", e.target.value);
                            setNotes(e.target.value);
                          }}
                          style={{
                            padding: "10px 12px 8px",
                            backgroundColor: "rgb(229, 231, 235)",
                            width: "100%",
                          }}
                          placeholder="Notes"
                        />
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => onSearch({ saveData: true })}
                          startIcon={<SearchIcon />}
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent={"space-between"}
                      className="HypotheticalPagination"
                      mt={"10px"}
                      sx={{
                        borderTop: "1px solid #666672",
                        paddingTop: "10px",
                      }}
                    >
                      {PaginatedResults()}
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={onDelete}
                        >
                          {isDeleteloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "red" }}
                            />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<DuplicateIcon />}
                          onClick={onDuplicate}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25 }}
                            />
                          ) : (
                            "Duplicate"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={onAddnew}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "white" }}
                            />
                          ) : (
                            "Add New"
                          )}
                        </Button>
                      </Stack>
                    </Stack>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <Box
                      sx={{
                        height: `${physicalTabHeight}px`,
                        overflow: "auto",
                      }}
                    >
                      <Grid
                        container
                        rowSpacing={"8px"}
                        columnSpacing={"15px"}
                        sx={{
                          "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                            borderRight: "1px solid #666672",
                            paddingRight: "15px",
                          },
                        }}
                      >
                        {orsFilterEducationalDemandData.map((data, index) => (
                          <Grid item xs={6} key={index}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                "&:hover": { backgroundColor: "#E6F3FB66" },
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                {data.Defination}
                              </Typography>
                              <CustomSwitch
                                name="choiceOfSitting5"
                                id="choiceOfSitting5"
                                value={!!data.isChecked}
                                checked={!!data.isChecked}
                                onChange={(e) =>
                                  handleToggleChange(
                                    data.EstimateCode,
                                    !data.isChecked
                                  )
                                }
                              />
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Stack
                      direction={"row"}
                      spacing={"10px"}
                      alignItems={"flex-end"}
                    >
                      <Box flexGrow={1}>
                        <textarea
                          ref={textareaRef}
                          value={notes}
                          onChange={(e) => {
                            localStorage.setItem("notes", e.target.value);
                            setNotes(e.target.value);
                          }}
                          style={{
                            padding: "10px 12px 8px",
                            backgroundColor: "rgb(229, 231, 235)",
                            width: "100%",
                          }}
                          placeholder="Notes"
                        />
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => onSearch({ saveData: true })}
                          startIcon={<SearchIcon />}
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent={"space-between"}
                      className="HypotheticalPagination"
                      mt={"10px"}
                      sx={{
                        borderTop: "1px solid #666672",
                        paddingTop: "10px",
                      }}
                    >
                      {PaginatedResults()}
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={onDelete}
                        >
                          {isDeleteloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "red" }}
                            />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<DuplicateIcon />}
                          onClick={onDuplicate}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25 }}
                            />
                          ) : (
                            "Duplicate"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={onAddnew}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "white" }}
                            />
                          ) : (
                            "Add New"
                          )}
                        </Button>
                      </Stack>
                    </Stack>
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <Box
                      sx={{
                        height: `${physicalTabHeight}px`,
                        overflow: "auto",
                      }}
                    >
                      <Grid
                        container
                        rowSpacing={"8px"}
                        columnSpacing={"15px"}
                        sx={{
                          "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                            borderRight: "1px solid #666672",
                            paddingRight: "15px",
                          },
                        }}
                      >
                        {orsFilterEnviromentalDemandData.map((data, index) => (
                          <Grid item xs={6} key={index}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                "&:hover": { backgroundColor: "#E6F3FB66" },
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                {data.Defination}
                              </Typography>
                              <CustomSwitch
                                name="choiceOfSitting5"
                                id="choiceOfSitting5"
                                value={!!data.isChecked}
                                checked={!!data.isChecked}
                                onChange={(e) =>
                                  handleToggleChange(
                                    data.EstimateCode,
                                    !data.isChecked
                                  )
                                }
                              />
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Stack
                      direction={"row"}
                      spacing={"10px"}
                      alignItems={"flex-end"}
                    >
                      <Box flexGrow={1}>
                        <textarea
                          ref={textareaRef}
                          value={notes}
                          onChange={(e) => {
                            localStorage.setItem("notes", e.target.value);
                            setNotes(e.target.value);
                          }}
                          style={{
                            padding: "10px 12px 8px",
                            backgroundColor: "rgb(229, 231, 235)",
                            width: "100%",
                          }}
                          placeholder="Notes"
                        />
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => onSearch({ saveData: true })}
                          startIcon={<SearchIcon />}
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent={"space-between"}
                      className="HypotheticalPagination"
                      mt={"10px"}
                      sx={{
                        borderTop: "1px solid #666672",
                        paddingTop: "10px",
                      }}
                    >
                      {PaginatedResults()}
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={onDelete}
                        >
                          {isDeleteloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "red" }}
                            />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<DuplicateIcon />}
                          onClick={onDuplicate}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25 }}
                            />
                          ) : (
                            "Duplicate"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={onAddnew}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "white" }}
                            />
                          ) : (
                            "Add New"
                          )}
                        </Button>
                      </Stack>
                    </Stack>
                  </TabPanel> */}
                  {/* Physical Tab */}
                  <DemandTabPanel
                    value={value}
                    index={1}
                    data={orsFilterPhysicalDemandData}
                    onToggleChange={handleToggleChange}
                    notes={notes}
                    setNotes={setNotes}
                    onSearch={onSearch}
                    isDeleteloading={isDeleteloading}
                    onDelete={onDelete}
                    isAddNewloading={isAddNewloading}
                    onDuplicate={onDuplicate}
                    onAddnew={onAddnew}
                    textareaRef={textareaRef}
                    physicalTabHeight={physicalTabHeight}
                    PaginatedResults={PaginatedResults}
                  />
                  {/* Cognitive Tab */}
                  <DemandTabPanel
                    value={value}
                    index={2}
                    data={orsFilterCognitiveDemandData}
                    onToggleChange={handleToggleChange}
                    notes={notes}
                    setNotes={setNotes}
                    onSearch={onSearch}
                    isDeleteloading={isDeleteloading}
                    onDelete={onDelete}
                    isAddNewloading={isAddNewloading}
                    onDuplicate={onDuplicate}
                    onAddnew={onAddnew}
                    textareaRef={textareaRef}
                    physicalTabHeight={physicalTabHeight}
                    PaginatedResults={PaginatedResults}
                  />
                  {/* Educational Tab */}
                  <DemandTabPanel
                    value={value}
                    index={3}
                    data={orsFilterEducationalDemandData}
                    onToggleChange={handleToggleChange}
                    notes={notes}
                    setNotes={setNotes}
                    onSearch={onSearch}
                    isDeleteloading={isDeleteloading}
                    onDelete={onDelete}
                    isAddNewloading={isAddNewloading}
                    onDuplicate={onDuplicate}
                    onAddnew={onAddnew}
                    textareaRef={textareaRef}
                    physicalTabHeight={physicalTabHeight}
                    PaginatedResults={PaginatedResults}
                  />
                  {/* Enviromental Tab */}
                  <DemandTabPanel
                    value={value}
                    index={4}
                    data={orsFilterEnviromentalDemandData}
                    onToggleChange={handleToggleChange}
                    notes={notes}
                    setNotes={setNotes}
                    onSearch={onSearch}
                    isDeleteloading={isDeleteloading}
                    onDelete={onDelete}
                    isAddNewloading={isAddNewloading}
                    onDuplicate={onDuplicate}
                    onAddnew={onAddnew}
                    textareaRef={textareaRef}
                    physicalTabHeight={physicalTabHeight}
                    PaginatedResults={PaginatedResults}
                  />
                  {/* <TabPanel value={value} index={5}>
                    <Box
                      sx={{
                        height: `${physicalTabHeight}px`,
                        overflow: "auto",
                      }}
                    >
                      <Grid
                        container
                        rowSpacing={"8px"}
                        columnSpacing={"15px"}
                        sx={{
                          "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                            borderRight: "1px solid #666672",
                            paddingRight: "15px",
                          },
                        }}
                      >
                        {[
                          ...orsFilterPhysicalDemandData,
                          ...orsFilterCognitiveDemandData,
                          ...orsFilterEducationalDemandData,
                          ...orsFilterEnviromentalDemandData,
                        ].map((data, index) => (
                          <Grid item xs={6} key={index}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                "&:hover": { backgroundColor: "#E6F3FB66" },
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                {data.Defination}
                              </Typography>
                              <CustomSwitch
                                name="choiceOfSitting5"
                                id="choiceOfSitting5"
                                value={!!data.isChecked}
                                checked={!!data.isChecked}
                                onChange={(e) =>
                                  handleToggleChange(
                                    data.EstimateCode,
                                    !data.isChecked
                                  )
                                }
                              />
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Stack
                      direction={"row"}
                      spacing={"10px"}
                      alignItems={"flex-end"}
                    >
                      <Box flexGrow={1}>
                        <textarea
                          ref={textareaRef}
                          value={notes}
                          onChange={(e) => {
                            localStorage.setItem("notes", e.target.value);
                            setNotes(e.target.value);
                          }}
                          style={{
                            padding: "10px 12px 8px",
                            backgroundColor: "rgb(229, 231, 235)",
                            width: "100%",
                          }}
                          placeholder="Notes"
                        />
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => onSearch({ saveData: true })}
                          startIcon={<SearchIcon />}
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent={"space-between"}
                      className="HypotheticalPagination"
                      mt={"10px"}
                      sx={{
                        borderTop: "1px solid #666672",
                        paddingTop: "10px",
                      }}
                    >
                      {PaginatedResults()}
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={onDelete}
                        >
                          {isDeleteloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "red" }}
                            />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<DuplicateIcon />}
                          onClick={onDuplicate}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25 }}
                            />
                          ) : (
                            "Duplicate"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={onAddnew}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "white" }}
                            />
                          ) : (
                            "Add New"
                          )}
                        </Button>
                      </Stack>
                    </Stack>
                  </TabPanel> */}
                  {/* All Tab */}
                  <AllTabs
                    value={value}
                    handleToggleChange={handleToggleChange}
                    notes={notes}
                    setNotes={setNotes}
                    textareaRef={textareaRef}
                    physicalTabHeight={physicalTabHeight}
                    orsFilterPhysicalDemandData={orsFilterPhysicalDemandData}
                    orsFilterCognitiveDemandData={orsFilterCognitiveDemandData}
                    orsFilterEducationalDemandData={
                      orsFilterEducationalDemandData
                    }
                    orsFilterEnviromentalDemandData={
                      orsFilterEnviromentalDemandData
                    }
                    onSearch={onSearch}
                    isDeleteloading={isDeleteloading}
                    onDelete={onDelete}
                    isAddNewloading={isAddNewloading}
                    onDuplicate={onDuplicate}
                    onAddnew={onAddnew}
                    PaginatedResults={PaginatedResults}
                    index={5}
                  />
                </form>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="bg-white" padding={2} borderRadius={"10px"}>
                <Stack direction={"row"} spacing={2}>
                  <Box flexGrow={1}>
                    <Link to="/HypotheticalAnalyzer">
                      <Button variant="contained" fullWidth color="greyPrimary">
                        DOT
                      </Button>
                    </Link>
                  </Box>
                  <Box flexGrow={1}>
                    <Button variant="contained" fullWidth color="primary">
                      ORS
                    </Button>
                  </Box>
                  <AnalyzerFilters
                    {...{ filtersSorting, setFiltersSorting, OrsFilters: true }}
                  />
                </Stack>
                <Box sx={{ my: "15px" }}>
                  <Typography variant="h6">Filtered Occupations</Typography>
                </Box>

                <Box
                  style={{
                    height: `${occupationsHeight}px`,
                    overflow: "auto",
                    position: "relative",
                    paddingRight: "4px",
                  }}
                  sx={{
                    "&::-webkit-scrollbar": {
                      width: "14px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      minHeight: "35px",
                    },
                  }}
                >
                  {isLoading ? (
                    <Stack
                      sx={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 4,
                        backgroundColor: "#95959512",
                        borderRadius: "10px",
                      }}
                    >
                      <CircularProgress />
                    </Stack>
                  ) : (
                    !!filteredOccupations &&
                    (!filteredOccupations.length ? (
                      <div>No data</div>
                    ) : (
                      filteredOccupations.map((item, index) => {
                        return (
                          <Box
                            key={item.Id}
                            sx={{
                              padding: "12px",
                              backgroundColor: "#F5F5F5",
                              marginTop: "10px",
                              borderRadius: "10px",
                              width: "100%",
                            }}
                            className=""
                          >
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Stack
                                direction={"row"}
                                alignItems={"start"}
                                sx={{
                                  overflow: "hidden",
                                }}
                              >
                                <Box>
                                  <input
                                    type="checkbox"
                                    disableRipple
                                    size="small"
                                    id={`CheckBoxOwner${item.Id}`}
                                    sx={{ padding: 0 }}
                                    checked={item.isChecked}
                                    name={item.Id}
                                    onClick={() => {
                                      handleResultToggle(item);
                                    }}
                                  />
                                </Box>
                                <InputLabel
                                  htmlFor={`CheckBoxOwner-${index}`}
                                  sx={{
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                    width: "auto", // Ensure width is not restricted
                                    overflow: "visible", // Allow overflow to be visible
                                    whiteSpace: "normal", // Allow text to wrap normally
                                    textOverflow: "clip",
                                  }}
                                >
                                  <Typography
                                    variant="p"
                                    color={"text.primary"}
                                    sx={{
                                      width: "auto", // Ensure width is not restricted
                                      overflow: "visible", // Allow overflow to be visible
                                      whiteSpace: "normal", // Allow text to wrap normally
                                      textOverflow: "clip",
                                    }}
                                  >
                                    <b>{item.Occupation}</b>
                                  </Typography>
                                </InputLabel>
                              </Stack>
                            </Stack>

                            {/* <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Typography
                                variant="span"
                                component={"span"}
                                color={"text.grey"}
                                marginTop={"10px"}
                              >
                                SOC Code
                              </Typography>
                              <Typography
                                variant="span"
                                component={"span"}
                                marginTop={"10px"}
                              >
                                {item.Soc2018Code.slice(0, 2) +
                                  "-" +
                                  item.Soc2018Code.slice(2)}
                              </Typography>
                            </Stack>

                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Typography
                                variant="span"
                                component={"span"}
                                color={"text.grey"}
                                marginTop={"10px"}
                              >
                                Est. Employment
                              </Typography>
                              <Typography
                                variant="span"
                                component={"span"}
                                marginTop={"10px"}
                              >
                                {
                                  // have already divided the estimate by 100 in ORS search Hook
                                  Number(item.Estimate)?.toLocaleString(
                                    "en-US",
                                    {
                                      maximumFractionDigits: 0,
                                    }
                                  )
                                }
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Typography
                                variant="span"
                                component={"span"}
                                color={"text.grey"}
                                marginTop={"10px"}
                              >
                                Total Employment
                              </Typography>
                              <Typography
                                variant="span"
                                component={"span"}
                                marginTop={"10px"}
                              >
                                {item.totalEmp?.toLocaleString()}
                              </Typography>
                            </Stack> */}
                            <ORSInfoRow
                              label="SOC Code"
                              value={`${item.Soc2018Code.slice(
                                0,
                                2
                              )}-${item.Soc2018Code.slice(2)}`}
                            />

                            <ORSInfoRow
                              label="Est. Employment"
                              value={Number(item.Estimate)?.toLocaleString(
                                "en-US",
                                { maximumFractionDigits: 0 }
                              )}
                            />

                            <ORSInfoRow
                              label="Total Employment"
                              value={item.totalEmp?.toLocaleString()}
                            />
                            <Box mt={"10px"}>
                              <Button
                                variant="outlined"
                                size="small"
                                color="DetailsBtn"
                                fullWidth
                                onClick={() => onViewDetailsClick(item)}
                                endIcon={<RightArrowIcon />}
                              >
                                View Details
                              </Button>
                            </Box>
                          </Box>
                        );
                      })
                    ))
                  )}
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={onSave}
                    disabled={disabledSaveButton}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <div>loading data</div>
        )}
      </Container>

      {/* Sort By Dialog */}
      <SortByDialog
        openSortByDialog={openSortByDialog}
        handleCloseSortByDialog={handleCloseSortByDialog}
      />
    </>
  );
};

export default HypotheticalToolSummaryORS;
