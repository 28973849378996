import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
const BpIcon = styled("span")(() => ({
  borderRadius: "5px",
  width: 20,
  height: 20,
  backgroundImage: `url('data:image/svg+xml,<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" width="20" height="20" rx="4" fill="%23F0F0F0"/><path d="M8.816 14C8.744 14 8.684 13.976 8.636 13.928C8.596 13.872 8.576 13.808 8.576 13.736C8.576 13.696 8.58 13.664 8.588 13.64C8.604 13.616 8.616 13.596 8.624 13.58L10.46 11.024C10.26 11.072 10.044 11.096 9.812 11.096C9.364 11.088 8.964 11.008 8.612 10.856C8.26 10.704 7.96 10.5 7.712 10.244C7.464 9.98 7.276 9.684 7.148 9.356C7.02 9.028 6.956 8.684 6.956 8.324C6.956 7.972 7.016 7.628 7.136 7.292C7.264 6.956 7.452 6.652 7.7 6.38C7.956 6.108 8.272 5.892 8.648 5.732C9.024 5.564 9.468 5.48 9.98 5.48C10.484 5.48 10.924 5.56 11.3 5.72C11.684 5.872 12.004 6.084 12.26 6.356C12.524 6.62 12.716 6.92 12.836 7.256C12.964 7.592 13.028 7.94 13.028 8.3C13.028 8.628 12.98 8.928 12.884 9.2C12.788 9.472 12.672 9.716 12.536 9.932C12.408 10.148 12.28 10.348 12.152 10.532L9.848 13.76C9.816 13.8 9.772 13.852 9.716 13.916C9.66 13.972 9.576 14 9.464 14H8.816ZM9.98 10.124C10.316 10.124 10.624 10.052 10.904 9.908C11.192 9.756 11.42 9.548 11.588 9.284C11.756 9.012 11.84 8.688 11.84 8.312C11.84 7.936 11.756 7.616 11.588 7.352C11.42 7.08 11.192 6.872 10.904 6.728C10.624 6.576 10.316 6.5 9.98 6.5C9.652 6.5 9.348 6.576 9.068 6.728C8.788 6.872 8.564 7.08 8.396 7.352C8.228 7.616 8.144 7.936 8.144 8.312C8.144 8.688 8.228 9.012 8.396 9.284C8.564 9.548 8.788 9.756 9.068 9.908C9.348 10.052 9.652 10.124 9.98 10.124Z" fill="%23666672"/></svg>')`,
  "input:hover ~ &": {
    backgroundImage: `url('data:image/svg+xml,<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" width="20" height="20" rx="4" fill="%23F4FAFD"/><path d="M8.816 14C8.744 14 8.684 13.976 8.636 13.928C8.596 13.872 8.576 13.808 8.576 13.736C8.576 13.696 8.58 13.664 8.588 13.64C8.604 13.616 8.616 13.596 8.624 13.58L10.46 11.024C10.26 11.072 10.044 11.096 9.812 11.096C9.364 11.088 8.964 11.008 8.612 10.856C8.26 10.704 7.96 10.5 7.712 10.244C7.464 9.98 7.276 9.684 7.148 9.356C7.02 9.028 6.956 8.684 6.956 8.324C6.956 7.972 7.016 7.628 7.136 7.292C7.264 6.956 7.452 6.652 7.7 6.38C7.956 6.108 8.272 5.892 8.648 5.732C9.024 5.564 9.468 5.48 9.98 5.48C10.484 5.48 10.924 5.56 11.3 5.72C11.684 5.872 12.004 6.084 12.26 6.356C12.524 6.62 12.716 6.92 12.836 7.256C12.964 7.592 13.028 7.94 13.028 8.3C13.028 8.628 12.98 8.928 12.884 9.2C12.788 9.472 12.672 9.716 12.536 9.932C12.408 10.148 12.28 10.348 12.152 10.532L9.848 13.76C9.816 13.8 9.772 13.852 9.716 13.916C9.66 13.972 9.576 14 9.464 14H8.816ZM9.98 10.124C10.316 10.124 10.624 10.052 10.904 9.908C11.192 9.756 11.42 9.548 11.588 9.284C11.756 9.012 11.84 8.688 11.84 8.312C11.84 7.936 11.756 7.616 11.588 7.352C11.42 7.08 11.192 6.872 10.904 6.728C10.624 6.576 10.316 6.5 9.98 6.5C9.652 6.5 9.348 6.576 9.068 6.728C8.788 6.872 8.564 7.08 8.396 7.352C8.228 7.616 8.144 7.936 8.144 8.312C8.144 8.688 8.228 9.012 8.396 9.284C8.564 9.548 8.788 9.756 9.068 9.908C9.348 10.052 9.652 10.124 9.98 10.124Z" fill="%230089D7"/></svg>')`,
  },
  //   'input:disabled ~ &': {
  //     background:'rgba(206,217,224,.5)',
  //   },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
 "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `url('data:image/svg+xml,<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" width="20" height="20" rx="4" fill="%230089D7"/><path d="M8.816 14C8.744 14 8.684 13.976 8.636 13.928C8.596 13.872 8.576 13.808 8.576 13.736C8.576 13.696 8.58 13.664 8.588 13.64C8.604 13.616 8.616 13.596 8.624 13.58L10.46 11.024C10.26 11.072 10.044 11.096 9.812 11.096C9.364 11.088 8.964 11.008 8.612 10.856C8.26 10.704 7.96 10.5 7.712 10.244C7.464 9.98 7.276 9.684 7.148 9.356C7.02 9.028 6.956 8.684 6.956 8.324C6.956 7.972 7.016 7.628 7.136 7.292C7.264 6.956 7.452 6.652 7.7 6.38C7.956 6.108 8.272 5.892 8.648 5.732C9.024 5.564 9.468 5.48 9.98 5.48C10.484 5.48 10.924 5.56 11.3 5.72C11.684 5.872 12.004 6.084 12.26 6.356C12.524 6.62 12.716 6.92 12.836 7.256C12.964 7.592 13.028 7.94 13.028 8.3C13.028 8.628 12.98 8.928 12.884 9.2C12.788 9.472 12.672 9.716 12.536 9.932C12.408 10.148 12.28 10.348 12.152 10.532L9.848 13.76C9.816 13.8 9.772 13.852 9.716 13.916C9.66 13.972 9.576 14 9.464 14H8.816ZM9.98 10.124C10.316 10.124 10.624 10.052 10.904 9.908C11.192 9.756 11.42 9.548 11.588 9.284C11.756 9.012 11.84 8.688 11.84 8.312C11.84 7.936 11.756 7.616 11.588 7.352C11.42 7.08 11.192 6.872 10.904 6.728C10.624 6.576 10.316 6.5 9.98 6.5C9.652 6.5 9.348 6.576 9.068 6.728C8.788 6.872 8.564 7.08 8.396 7.352C8.228 7.616 8.144 7.936 8.144 8.312C8.144 8.688 8.228 9.012 8.396 9.284C8.564 9.548 8.788 9.756 9.068 9.908C9.348 10.052 9.652 10.124 9.98 10.124Z" fill="white"/></svg>')`,
    content: '""',
  },
});
const NineCheck = (props) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default NineCheck;
