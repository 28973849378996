import {
  CCheck,
  EightCheck,
  FCheck,
  FiveCheck,
  FourCheck,
  NCheck,
  OCheck,
  OneCheck,
  SevenCheck,
  SixCheck,
  ThreeCheck,
  TwOCheck,
  NineCheck,
} from "../../Common/CustomCheckbox";
import { handlePutRequest } from "../../services/PutTemplate";
import { postDOTfilters } from "../../services/postDOTFilters";
import { putDOTfilters } from "../../services/putDOTFilters";

export const sortAsc = (filteredArray, keyToSortBy) => {
  return filteredArray.sort((a, b) => {
    if (a[keyToSortBy] < b[keyToSortBy]) return -1;
    if (a[keyToSortBy] > b[keyToSortBy]) return 1;
    return 0;
  });
};

export const sortDesc = (filteredArray, keyToSortBy) => {
  return filteredArray.sort((a, b) => {
    if (a[keyToSortBy] < b[keyToSortBy]) return 1;
    if (a[keyToSortBy] > b[keyToSortBy]) return -1;
    return 0;
  });
};

export const joinArraysOnDotcode = (arr1, arr2) => {
  const map = new Map();
  arr1?.forEach((item) => {
    map.set(String(item.dotcode), { ...item });
  });

  arr2?.forEach((item) => {
    const isPresent = map.has(String(item.DotCode));
    if (isPresent) {
      map.set(String(item.DotCode), {
        ...map.get(String(item.DotCode)),
        ...item,
      });
    }
  });

  return Array.from(map.values());
};

export const getCheckBox = (value, name, formik) => {
  switch (value) {
    case "N":
      return (
        <NCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "O":
      return (
        <OCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "F":
      return (
        <FCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "C":
      return (
        <CCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    default:
      return null;
  }
};

// Function to render radio button based on value
export const getCheckBoxEnvironmental = (value, name, formik) => {
  switch (value) {
    case "N":
      return (
        <NCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          // onChange={ ()=> formik.setFieldValue(`${name} Env1`, value)}
          onChange={formik.handleChange}
        />
      ); //
    case "O":
      return (
        <OCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "F":
      return (
        <FCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "C":
      return (
        <CCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    default:
      return null;
  }
};

export const getCheckBoxEnvironmentalMultilevel = (value, name, formik) => {
  switch (value) {
    case "1":
      return (
        <OneCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "2":
      return (
        <TwOCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "3":
      return (
        <ThreeCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "4":
      return (
        <FourCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "5":
      return (
        <FiveCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "6":
      return (
        <SixCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "7":
      return (
        <SevenCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "8":
      return (
        <EightCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "9":
      return (
        <NineCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
    )
    default:
      return null;
  }
};

// Function to render radio button based on value
export const  getCheckBoxCognitive = (value, name, formik) => {
  switch (value) {
    case "1":
      return (
        <OneCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "2":
      return (
        <TwOCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "3":
      return (
        <ThreeCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "4":
      return (
        <FourCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "5":
      return (
        <FiveCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "6":
      return (
        <SixCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "7":
      return (
        <SevenCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "8":
      return (
        <EightCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value || ""}
          checked={formik.values[name]?.includes(value) || ""}
          onChange={formik.handleChange}
        />
      );
    case "9":
      return (
        <NineCheck
        sx={{ paddingY: "2px", paddingX: "5px" }}
        name={name}
        value={value || ""}
        checked={formik.values[name]?.includes(value) || ""}
        onChange={formik.handleChange}
        />
      );
    default:
      return null;
  }
};

export const transformSubmissionData = (
  data,
  physicalData,
  aptitudesData,
  environmentalData
) => {
  const newData = {};
  let filters = {};
  const hypotheticalFilters = localStorage.getItem("hypotheticalFilters");
  const currentPage = sessionStorage.getItem("paginatedValue");
  if (hypotheticalFilters) {
    filters = JSON.parse(hypotheticalFilters);
  }
  if (data?.Strength) {
    newData.Strength = data.Strength;
  } else {
    newData.Strength =
      filters?.orsFilters[currentPage - 1]?.filters?.DotFilters?.Strength;
  }
  physicalData?.forEach((item, index) => {
    newData[`Phys${index + 1}`] = data ? data[item.Name] : "";
  });

  aptitudesData?.forEach((item) => {
    newData[`Apt${item.ShortCharacter.charAt(0).toUpperCase()}`] = data
      ? data[item.Description]
      : "";
  });

  environmentalData?.forEach((item, index) => {
    newData[`Env${index + 1}`] = data ? data[item.Description] : "";
  });

  newData["SVP"] = data["SVP"] || "";

  const updatedData = {};
  Object.entries(newData).forEach(([key, value]) => {
    if (
      !(
        (typeof value === "string" && value === "") ||
        (Array.isArray(value) && value.length === 0)
      )
    ) {
      updatedData[key] = value;
    }
  });

  return updatedData;
};

export const physicalDemandLevels = [
  { label: "Sedentary", value: "S", id: "S" },
  { label: "Light", value: "L", id: "L" },
  { label: "Medium", value: "M", id: "M" },
  { label: "Heavy", value: "H", id: "H" },
  { label: "Very Heavy", value: "V", id: "V" },
];

export const transformDotFiltersToSave = (
  data,
  physicalData,
  aptitudesData,
  environmentalData
) => {
  if (!data) return {};

  const newData = {};
  if (data?.Strength) {
    newData.Strength = data.Strength;
  }
  physicalData?.forEach((item) => {
    const name = item.Name;
    const dataValue = data[name];

    if (!!dataValue) {
      newData[item.Name] = data[item.Name];
    }
  });

  aptitudesData?.forEach((item) => {
    if (!!data[item.Description])
      newData[item.Description] = data[item.Description] || "";
  });

  environmentalData?.forEach((item, index) => {
    if (!!data[item.Description]) {
      newData[item.Description] = data[item.Description];
    }
  });

  return newData;
};

export const onAddNewFilters = async (clearNotes = false) => {
  const localStorageDotFilters = JSON.parse(
    sessionStorage.getItem("DOTFiltersData")
  );
  const localStorageOrsFilters = JSON.parse(
    sessionStorage.getItem("ORSFiltersData")
  );
  const localStorageOrsResults = JSON.parse(
    sessionStorage.getItem("ORSResults")
  );

  const localStorageNotes = localStorage.getItem("notes");

  const environmentalDemandData = JSON.parse(
    localStorage.getItem("EnvironmentalDemands")
  );
  const physicalDemandData = JSON.parse(
    localStorage.getItem("PhysicalDemands")
  );
  const filterId = Number(sessionStorage.getItem("selectedFiltersId"));
  const cognitiveDemandData = JSON.parse(localStorage.getItem("Aptitudes"));
  const req = localStorage.getItem("SearchRequest");

  let DotFilters = {},
    DotResults = [],
    OrsFilters = [],
    OrsResults = [];
  if (localStorageDotFilters) {
    const { filters, results } = localStorageDotFilters;
    DotFilters = filters;
    DotResults = results;
  }
  if (localStorageOrsFilters) {
    const { filters, results } = localStorageOrsFilters;
    OrsFilters = filters;
    OrsResults = results;
  }

  const newData = transformDotFiltersToSave(
    DotFilters,
    physicalDemandData,
    cognitiveDemandData,
    environmentalDemandData
  );

  // remove empty values or empty arrays from newData
  Object.keys(newData).forEach((key) => {
    if (
      (typeof newData[key] === "string" && newData[key] === "") ||
      (Array.isArray(newData[key]) && newData[key].length === 0)
    ) {
      delete newData[key];
    }
  });
  const profileId = Number(localStorage.getItem("profileId"));

  let payload = {
    filters: {
      DotFilters: newData,
      OrsFilters,
      // notes: localStorageNotes ? localStorageNotes[localStorageNotes.length-1] || "" : "",
      notes: localStorageNotes || "",
    },
    results: {
      OrsResults: localStorageOrsResults,
      DotResults,
    },
    profileId,
  };
  if (filterId) {
    payload = { ...payload, filterId };
    const { profileId, ...updatedPayload } = payload;
    await putDOTfilters(updatedPayload);
  } else {
    await postDOTfilters(payload);
  }
  return payload;
};

function formatForRendering(data) {
  return (
    <div>
      <section>
        <p>{data["Description"]}</p>
      </section>
      {data["Description-Tasks"] ? (
        <section>
          <h2 className="font-bold text-base">Tasks</h2>
          <ol>
            {data["Description-Tasks"].map((task, index) => (
              <li key={index}>{task}</li>
            ))}
          </ol>
        </section>
      ) : null}
      {data["Description-Include"].length ? (
        <section>
          <h2 className="font-bold text-base">May Also Include</h2>
          <ol>
            {data["Description-Include"].map((include, index) => (
              <li key={index}>{include}</li>
            ))}
          </ol>
        </section>
      ) : null}
      {data["Description-Alt-Titles"].length ? (
        <section className="flex items-center gap-1">
          <h2 className="font-bold text-base">Alternate Titles:</h2>
          <span>{data["Description-Alt-Titles"][0]}</span>
        </section>
      ) : null}
      {data["Undefined-Related-Title"].length ? (
        <section>
          <h2 className="font-bold text-base">Undefined Related Title:</h2>
          <ol>
            {data["Undefined-Related-Title"].map((related, index) => (
              <li key={index}>{related}</li>
            ))}
          </ol>
        </section>
      ) : null}
    </div>
  );
}

export function formatString(input = "") {
  let description = "";
  let altTitles = [];
  let tasks = [];
  let includes = [];
  let undefinedRelatedTitle = [];

  let currentSection = null;
  let currentBuffer = "";

  for (let i = 0; i < input.length; i++) {
    let char = input[i];

    if (
      char === "~" ||
      char === "%" ||
      char === "*" ||
      char === "|" ||
      char === "^"
    ) {
      // Push the current buffer to the appropriate array
      if (currentSection === "Description") {
        description = currentBuffer.trim();
      } else if (currentSection === "AltTitles") {
        altTitles.push(currentBuffer);
      } else if (currentSection === "Tasks") {
        tasks.push(currentBuffer);
      } else if (currentSection === "Includes") {
        includes.push(currentBuffer);
      } else if (currentSection === "UndefinedRelatedTitle") {
        undefinedRelatedTitle.push(currentBuffer);
      }

      // Reset the current buffer and set the new section
      currentBuffer = "";
      if (char === "~") {
        currentSection = "Description";
      } else if (char === "%") {
        currentSection = "AltTitles";
      } else if (char === "*") {
        currentSection = "Tasks";
      } else if (char === "|") {
        currentSection = "Includes";
      } else if (char === "^") {
        currentSection = "UndefinedRelatedTitle";
      }
    } else {
      // Append the character to the current buffer
      currentBuffer += char;
    }
  }

  // Handle the last section after the loop ends
  if (currentSection === "Description") {
    description = currentBuffer.trim();
  } else if (currentSection === "AltTitles") {
    altTitles.push(currentBuffer);
  } else if (currentSection === "Tasks") {
    tasks.push(currentBuffer);
  } else if (currentSection === "Includes") {
    includes.push(currentBuffer);
  } else if (currentSection === "UndefinedRelatedTitle") {
    undefinedRelatedTitle.push(currentBuffer);
  }

  // Create an object with the extracted values
  let formattedData = {
    Description: description,
    "Description-Alt-Titles": altTitles,
    "Description-Tasks": tasks,
    "Description-Include": includes,
    "Undefined-Related-Title": undefinedRelatedTitle,
  };

  return formatForRendering(formattedData);
}

export function reverseString(str) {
  if (str.length === 1) return `0${str}`;
  const val = str.split("").reverse().join("");
  return val;
}
