import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { CustomTooltip } from "../components/custom";

const GetDefinationByDotCode = ({ Code, TypeName , children }) => {
  var dbName = "veToolkitDB";
  const [tooltip, setTooltip] = useState("");
  

  async function fetchLongDefinations(Code) {
    const request = window.indexedDB.open(dbName);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["LongDefinations"], "readonly");
      const objectStore = transaction.objectStore("LongDefinations");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        
        let data = "";
        if (getData.result) {
          if (TypeName) {
            data = getData.result.find((item) => {
              return (
                item.TypeName === TypeName  && item.Code === Code
              );
            });
          } else {
            data = getData.result.find((item) => {
              return (
                item.Code === Code &&
                item.TypeName === TypeName
              );
            });
          }
          
          setTooltip(data ? data.LongDefination : "No defination found");
        }
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };
  }

  async function findDefination(Code) {
    await fetchLongDefinations(Code);
  }

  return (
    <CustomTooltip title={tooltip} placement="right">
      <div
        onMouseEnter={() => findDefination(Code)}
        className="w-fit-content"
      >
        {children}
      </div>
    </CustomTooltip>
  );
};

export default GetDefinationByDotCode;
