import React from 'react'
import TabPanel from './TabPanel';
import { Box, Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/delet-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/icons/add-icon.svg";
import { ReactComponent as DuplicateIcon } from "../../assets/images/icons/duplicate-icon.svg";
import CustomSwitch from '../../Common/Switch/CustomSwitch';

const DemandTabPanel = ({ value, index, data, onToggleChange, notes, setNotes, onSearch, isDeleteloading, onDelete, isAddNewloading, onDuplicate, onAddnew, textareaRef, physicalTabHeight, PaginatedResults }) => {
 
  return (
    <TabPanel value={value} index={index}>
    <Box sx={{ height: `${physicalTabHeight}px`, overflow: "auto" }}>
      <Grid container rowSpacing={"8px"} columnSpacing={"15px"} sx={{ "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": { borderRight: "1px solid #666672", paddingRight: "15px" } }}>
        {data.map((item, index) => (
          <Grid item xs={6} key={index}>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{ "&:last-child td, &:last-child th": { border: 0 }, "&:hover": { backgroundColor: "#E6F3FB66" } }}>
              <Typography variant="p" component={"p"}>{item.Defination}</Typography>
              <CustomSwitch
                name={`choiceOfSitting${index}`}
                id={`choiceOfSitting${index}`}
                value={!!item.isChecked}
                checked={!!item.isChecked}
                onChange={(e) => onToggleChange(item.EstimateCode, !item.isChecked)}
              />
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
    <Stack direction={"row"} spacing={"10px"} alignItems={"flex-end"}>
      <Box flexGrow={1}>
        <textarea
          ref={textareaRef}
          value={notes}
          onChange={(e) => {
            localStorage.setItem("notes", e.target.value);
            setNotes(e.target.value);
          }}
          style={{ padding: "10px 12px 8px", backgroundColor: "rgb(229, 231, 235)", width: "100%" }}
          placeholder="Notes"
        />
      </Box>
      <Box>
        <Button variant="contained" size="large" onClick={() => onSearch({ saveData: true })} startIcon={<SearchIcon />}>
          Search
        </Button>
      </Box>
    </Stack>
    <Stack direction={"row"} spacing={1} justifyContent={"space-between"} className="HypotheticalPagination" mt={"10px"} sx={{ borderTop: "1px solid #666672", paddingTop: "10px" }}>
      {PaginatedResults()}
      <Stack direction={"row"} spacing={2}>
        <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={onDelete}>
          {isDeleteloading ? <CircularProgress style={{ width: 25, height: 25, color: "red" }} /> : "Delete"}
        </Button>
        <Button variant="outlined" startIcon={<DuplicateIcon />} onClick={onDuplicate}>
          {isAddNewloading ? <CircularProgress style={{ width: 25, height: 25 }} /> : "Duplicate"}
        </Button>
        <Button variant="contained" startIcon={<AddIcon />} onClick={onAddnew}>
          {isAddNewloading ? <CircularProgress style={{ width: 25, height: 25, color: "white" }} /> : "Add New"}
        </Button>
      </Stack>
    </Stack>
  </TabPanel>
  )
}

export default DemandTabPanel