import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "../../Common/Navbar";
import IndexDBDataManager1 from "../../components/IndexDBDataManager1";
import { CircularProgress, Stack } from "@mui/material";

const AppContainer = () => {
  const isLoading = useSelector((state) => state.utilitySlice.isLoading);
  const token = localStorage.getItem("token");
  const location = useLocation();
  
  // Check if the URL includes 'dotDetail'
  const isDotDetailPage = location.pathname.includes('dotDetail') || location.search.includes('dotDetail');

  if (!token) {
    window.location.reload();
  }

  return (
    <>
      {/* Only show Navbar if not on dotDetail page */}
      {!isDotDetailPage && <Navbar />}
      <IndexDBDataManager1 />
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <Stack
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: "center",
              alignItems: "center",
              zIndex: 4,
              backgroundColor: "#95959512",
              borderRadius: "10px",
            }}
          >
            <CircularProgress />
            Loading data, Please wait
          </Stack>
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default AppContainer;
