import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CustomSwitch from "../../Common/Switch/CustomSwitch";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/delet-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/icons/add-icon.svg";
import { ReactComponent as DuplicateIcon } from "../../assets/images/icons/duplicate-icon.svg";
import TabPanel from "./TabPanel";

const SummaryTab = ({
  orsFilterSummaryDemandData,
  getCheckBox,
  physicalTabHeight,
  handleToggleChange,
  PaginatedResults,
  getFirstCharOfLastWord,
  textareaRef,
  notes,
  setNotes,
  onSearch,
  onDelete,
  isDeleteloading,
  onAddnew,
  onDuplicate,
  isAddNewloading,
  value,
  index,
}) => {
  // Define category mappings
  const categoryMappings = {
    "Physical Exertion/Strength": [
      "Sedentary",
      "Light",
      "Medium",
      "Heavy",
      "Very Heavy",
    ],
    "Upper Body/Hand Function": [
      "Reach Bilateral",
      "Pushing or Pulling",
      "Choice of Sitting or Standing",
      "Handling",
      "Gross Manipulation",
      "Overhead Bilateral Reach",
      "Feeling",
      "Fingering",
    ],
    "Lower Body/Foot Function": [
      "Foot Controls",
      "Crouching",
      "Kneel",
      "Stooping",
      "Crawling",
      "Climbing Ladders, Ropes, or Scaffolds",
    ],
    "Workplace Restrictions": [
      "Ladder Rope/Scaffold",
      "Ramp/Stairs",
      "No Driving",
      "No Moving Machinery",
      "No Hazardous Materials",
      "No prod/pace Quota",
      "No Fast Pace",
      "No Public",
      "Balance",
    ],
    "Sensory/Perceptual Restrictions": [
      "No Hearing",
      "Vision",
      "No Far Vision",
    ],
    "Environmental Conditions": [
      "No Extreme Heat",
      "No Humidity",
      "No Wetness",
      "Quiet Noise",
      "No Extreme Cold",
      "No Heavy Vibrations",
      
    ],
  };

  const groupedData = orsFilterSummaryDemandData.reduce((acc, item) => {
    // Check each category mapping
    for (const [category, items] of Object.entries(categoryMappings)) {
      if (
        items.some(
          (mappedItem) =>
            item.HypotheticalOrsSummerySection === mappedItem || // Exact match
            mappedItem === item.HypotheticalOrsSummerySection // Exact match
        )
      ) {
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(item);
        return acc;
      }
    }

    // If item doesn't match any category, put it in Others or its own category
    if (
      item.HypotheticalOrsSummerySection === "SVP 1 & 2" ||
      item.HypotheticalOrsSummerySection === "SVP 3 & 4"
    ) {
      if (!acc["SVP"]) {
        acc["SVP"] = [];
      }
      acc["SVP"].push(item);
    } else {
      if (!acc["Others"]) {
        acc["Others"] = [];
      }
      acc["Others"].push(item);
    }

    return acc;
  }, {});

  return (
    <TabPanel value={value} index={index}>
      {/* <Box
        sx={{
          height: `${physicalTabHeight}px`,
          overflow: "auto",
        }}
      >
        <Grid
          container
          rowSpacing={"8px"}
          columnSpacing={"15px"}
          item
          sx={{
            "& > .MuiGrid-item:not(:nth-child(3n)) > .MuiStack-root": {
              borderRight: "1px solid #666672",
              paddingRight: "15px",
            },
          }}
        >
          {orsFilterSummaryDemandData.map((data, index) => (
            <Grid item xs={4} key={index}>=
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                  "&:hover": { backgroundColor: "#E6F3FB66" },
                }}
              >
                <Typography variant="p" component={"p"}>
                  {data.subtitles.length === 1 &&
                  data.subtitles[0].RenameTitleTo !== "NULL"
                    ? data.subtitles[0].RenameTitleTo
                    : data.HypotheticalOrsSummerySection}
                </Typography>
                <div>
                  {data.HypotheticalOrsSummerySection !== "SVP 1 & 2" &&
                  data.subtitles.length > 1 ? (
                    data.subtitles.map((subtitle) => {
                      return getCheckBox(
                        getFirstCharOfLastWord(subtitle.RenameTitleTo),
                        data.HypotheticalOrsSummerySection,
                        subtitle
                      );
                    })
                  ) : (
                    <CustomSwitch
                      name="choiceOfSitting1"
                      id="choiceOfSitting1"
                      value={!!data.subtitles[0].isChecked}
                      checked={!!data.subtitles[0].isChecked}
                      onChange={(e) =>
                        handleToggleChange(
                          data.subtitles[0].EstimateCode,
                          !data.subtitles[0].isChecked
                        )
                      }
                    />
                  )}
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Box> */}
      <Box sx={{ height: `${physicalTabHeight}px`, overflow: "auto" }}>
        <Grid container spacing={4}>
          {Object.entries(groupedData)
            .reduce((rows, [category, items], index) => {
              if (index % 2 === 0) {
                rows.push([]);
              }
              rows[rows.length - 1].push({ category, items, index });
              return rows;
            }, [])
            .map((row, rowIndex) => (
              <Grid container item xs={12} key={rowIndex} spacing={4}>
                {row.map(({ category, items, index }) => (
                  <Grid item xs={6} key={index}>
                    <Typography
                      variant="p"
                      component={"p"}
                      sx={{
                        fontWeight: "bold",
                        mb: 1,
                        borderBottom: "1px solid #66667233",
                        pb: 1,
                      }}
                    >
                      {`${index + 1}. ${category}`}
                    </Typography>
                    <Box sx={{ pl: 2 }}>
                      {console.log("items", items)}
                      {items.map((item, subIndex) => (
                        <Stack
                          key={subIndex}
                          direction="row"
                          justifyContent="space-between"
                          sx={{
                            py: 0.5,
                            "&:hover": { backgroundColor: "#E6F3FB66" },
                          }}
                        >
                          <Typography>
                            {item.subtitles.length === 1 &&
                            item.subtitles[0].RenameTitleTo !== "NULL"
                              ? item.subtitles[0].RenameTitleTo
                              : item.HypotheticalOrsSummerySection}
                          </Typography>
                          <div>
                            {item.HypotheticalOrsSummerySection !==
                              "SVP 1 & 2" &&
                            item.HypotheticalOrsSummerySection !==
                              "SVP 3 & 4" &&
                            item.subtitles.length > 1 ? (
                              item.subtitles.map((subtitle) =>
                                getCheckBox(
                                  getFirstCharOfLastWord(
                                    subtitle.RenameTitleTo
                                  ),
                                  item.HypotheticalOrsSummerySection,
                                  subtitle
                                )
                              )
                            ) : (
                              // <CustomSwitch
                              //   name="choiceOfSitting1"
                              //   id="choiceOfSitting1"
                              //   value={!!item.subtitles[0].isChecked}
                              //   checked={!!item.subtitles[0].isChecked}
                              //   onChange={(e) =>
                              //     handleToggleChange(
                              //       item.subtitles[0].EstimateCode,
                              //       !item.subtitles[0].isChecked
                              //     )
                              //   }
                              // />
                              <CustomSwitch
                                name="choiceOfSitting1"
                                id="choiceOfSitting1"
                                value={!!item.subtitles[0].isChecked}
                                checked={!!item.subtitles[0].isChecked}
                                onChange={(e) => {
                                  // If EstimateCode is 00064, toggle both 00064 and 00065
                                  if (
                                    item.subtitles[0].EstimateCode === "00064"
                                  ) {
                                    handleToggleChange(
                                      "00064",
                                      !item.subtitles[0].isChecked
                                    );
                                    handleToggleChange(
                                      "00065",
                                      !item.subtitles[0].isChecked
                                    );
                                  }
                                  // If EstimateCode is 00066, toggle both 00066 and 00067
                                  else if (
                                    item.subtitles[0].EstimateCode === "00066"
                                  ) {
                                    handleToggleChange(
                                      "00066",
                                      !item.subtitles[0].isChecked
                                    );
                                    handleToggleChange(
                                      "00067",
                                      !item.subtitles[0].isChecked
                                    );
                                  } else {
                                    handleToggleChange(
                                      item.subtitles[0].EstimateCode,
                                      !item.subtitles[0].isChecked
                                    );
                                  }
                                }}
                              />
                            )}
                          </div>
                        </Stack>
                      ))}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ))}
        </Grid>
      </Box>
      <Stack direction={"row"} spacing={"10px"} alignItems={"flex-end"}>
        <Box flexGrow={1}>
          <textarea
            ref={textareaRef}
            value={notes || ""}
            onChange={(e) => {
              localStorage.setItem("notes", e.target.value);
              setNotes(e.target.value);
            }}
            style={{
              padding: "10px 12px 8px",
              backgroundColor: "rgb(229, 231, 235)",
              width: "100%",
            }}
            placeholder="Notes"
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            size="large"
            onClick={() => onSearch({ saveData: true })}
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
        </Box>
      </Stack>
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={"space-between"}
        className="HypotheticalPagination"
        mt={"10px"}
        sx={{
          borderTop: "1px solid #666672",
          paddingTop: "10px",
        }}
      >
        {PaginatedResults()}
        <Stack direction={"row"} spacing={2}>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={onDelete}
          >
            {isDeleteloading ? (
              <CircularProgress
                style={{ width: 25, height: 25, color: "red" }}
              />
            ) : (
              "Delete"
            )}
          </Button>
          <Button
            variant="outlined"
            startIcon={<DuplicateIcon />}
            onClick={onDuplicate}
          >
            {isAddNewloading ? (
              <CircularProgress style={{ width: 25, height: 25 }} />
            ) : (
              "Duplicate"
            )}
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={onAddnew}
          >
            {isAddNewloading ? (
              <CircularProgress
                style={{ width: 25, height: 25, color: "white" }}
              />
            ) : (
              "Add New"
            )}
          </Button>
        </Stack>
      </Stack>
    </TabPanel>
  );
};

export default SummaryTab;
