import { Tab, Tabs } from '@mui/material'
import React from 'react'

const TopTabs = ({value, handleChange, a11yProps}) => {
  return (
    <Tabs
    value={value}
    onChange={handleChange}
    indicatorColor="none"
    sx={{
      "& .MuiTabs-flexContainer": {
        justifyContent: "flex-end",
        gap: 1,
      },
    }}
  >
    <Tab
      label="Summary"
      {...a11yProps(0)}
      sx={{
        color:
          value === 0
            ? "#ffffff !important"
            : "#666672 !important",
        backgroundColor:
          value === 0 ? "#0089D7" : "#FFFFFF",
        minHeight: "20px",
        minWidth: "16px",
        paddingX: "15px",
        paddingY: "10px",
        alignItems: "end",
        border:
          value === 0 ? "none" : "1px solid #B8B8BE",
      }}
    />
    <Tab
      label="Physical"
      {...a11yProps(1)}
      sx={{
        color:
          value === 1
            ? "#ffffff !important"
            : "#666672 !important",
        backgroundColor:
          value === 1 ? "#0089D7" : "#FFFFFF",
        minHeight: "20px",
        minWidth: "16px",
        paddingX: "15px",
        paddingY: "10px",
        alignItems: "end",
        border:
          value === 1 ? "none" : "1px solid #B8B8BE",
      }}
    />
    <Tab
      label="Cognitive"
      {...a11yProps(2)}
      sx={{
        color:
          value === 2
            ? "#ffffff !important"
            : "#666672 !important",
        backgroundColor:
          value === 2 ? "#0089D7" : "#FFFFFF",
        minHeight: "20px",
        minWidth: "16px",
        paddingX: "15px",
        paddingY: "10px",
        alignItems: "end",
        border:
          value === 2 ? "none" : "1px solid #B8B8BE",
      }}
    />
    <Tab
      label="Educational"
      {...a11yProps(3)}
      sx={{
        color:
          value === 3
            ? "#ffffff !important"
            : "#666672 !important",
        backgroundColor:
          value === 3 ? "#0089D7" : "#FFFFFF",
        minHeight: "20px",
        minWidth: "16px",
        paddingX: "15px",
        paddingY: "10px",
        alignItems: "end",
        border:
          value === 3 ? "none" : "1px solid #B8B8BE",
      }}
    />
    <Tab
      label="Environmental"
      {...a11yProps(5)}
      sx={{
        color:
          value === 4
            ? "#ffffff !important"
            : "#666672 !important",
        backgroundColor:
          value === 4 ? "#0089D7" : "#FFFFFF",
        minHeight: "20px",
        minWidth: "16px",
        paddingX: "15px",
        paddingY: "10px",
        alignItems: "end",
        border:
          value === 4 ? "none" : "1px solid #B8B8BE",
      }}
    />
    <Tab
      label="All"
      {...a11yProps(5)}
      sx={{
        color:
          value === 5
            ? "#ffffff !important"
            : "#666672 !important",
        backgroundColor:
          value === 5 ? "#0089D7" : "#FFFFFF",
        minHeight: "20px",
        minWidth: "16px",
        paddingX: "12px",
        paddingY: "8px",
        alignItems: "end",
        border:
          value === 5 ? "none" : "1px solid #B8B8BE",
      }}
    />
  </Tabs>
  )
}

export default TopTabs