import {
  Box,
  Container,
  Stack,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Paper,
  IconButton,
  SvgIcon,
  Tab,
  Tabs,
  ToggleButton,
} from "@mui/material";
import { ReactComponent as EyeIcon } from "../../assets/images/icons/eye-icon.svg";
import { ReactComponent as EyeIconOff } from "../../assets/images/icons/eye-off-icon.svg";
import { ReactComponent as DescriptionIcon } from "../../assets/images/icons/description-icon.svg";
import { ReactComponent as RequirementsIcon } from "../../assets/images/icons/requirements-icon.svg";
import { ReactComponent as CodeIcon } from "../../assets/images/icons/code-lg-icon.svg";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { formatString } from "../HypotheticalAnalyzer/utils";
import { CodeTab } from "../HypotheticalAnalyzer/helperComponents/codeTab";
import GetDefination from "../../utils/getDefination";
import { handleGetEmploymentEstimateRequest } from "../../services/getEmployementEstimate";
import RequirementsTab from "../HypotheticalAnalyzer/helperComponents/requirementTab";
import { decryptSocCode } from "../../utils/decryptSocCode";
import CustomTableCell from "../../components/custom/table/ThemeTableCell";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function createData(DOT, Title, SVP, Strength, MeanOESEmp) {
  return { DOT, Title, SVP, Strength, MeanOESEmp };
}
const rows = [
  createData("100117010", "Cook Specialty", 5, "M", "11,000"),
  createData("002261010", "Cook Specialty", 5, "M", "11,000"),
  createData("003061014", "Cook Specialty", 5, "M", "11,000"),
  createData("003061018", "Cook Specialty", 5, "M", "11,000"),
  createData("003061022", "Cook Specialty", 5, "M", "11,000"),
];
const SOCDOTGroupInfo = () => {
  var dbName = "veToolkitDB";
  const localStorageOrsFilters = JSON.parse(
    sessionStorage.getItem("ORSFiltersData")
  );

  const [DOTTitleViewDisplay, setDOTTitleViewDisplay] = useState(false);
  // State to track selected state of each item
  const [selectedItems, setSelectedItems] = useState({});
  const [sortOrder, setSortOrder] = useState("desc");
  const [selectedSortKey, setSelectedSortKey] = useState("");
  // Define what happens when an item is selected
  const handleSelect = (itemId) => {
    setDOTTitleViewDisplay(true);
    //    setWorkHistoryHeight(window.innerHeight-500)
  };

  const location = useLocation();
  const rowData = location.state || {};

  const handleDeselect = (itemId) => {
    // Additional deselect logic here
    //    setWorkHistoryHeight(window.innerHeight - 225);
    setDOTTitleViewDisplay(false);
  };

  // Function to toggle select/deselect and call respective functions
  const handleToggleSelect = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      const isSelected = !prevSelectedItems[itemId];
      // Call handleSelect or handleDeselect based on the new state
      handleSearchDotCode(itemId); // Added by Faisal
      if (isSelected) {
        handleSelect(itemId);
        // Deselect all other items
        return { [itemId]: true };
      } else {
        handleDeselect(itemId);
        return { [itemId]: false };
      }
    });
  };
  const isAnyItemSelected = Object.values(selectedItems).some((value) => value);
  const [value, setValue] = useState(0);
  const [employEstimate, setEmployEstimate] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Code by Faisal

  var dbCurrentVersion;

  useEffect(() => {
    var openRequestver = window.indexedDB.open(dbName);

    openRequestver.onsuccess = function (event) {
      var dbver = event.target.result;
      var currentVersion = dbver.version;
      dbCurrentVersion = currentVersion;

      // Perform other operations as needed
    };

    openRequestver.onerror = function (event) {
      console.error("Error opening database:", event.target.error);
    };

    // Cleanup function
    return () => {
      openRequestver?.result?.close();
    };
  }, []);

  const [tableDatabyDotCode, setTableDatabyDotCode] = useState();
  const [PhysicalDemandData, setPhysicalDemandData] = useState();
  const [enviromentalData, setEnviromentalData] = useState();
  const [DotData, setDotData] = useState();
  const [filteredDotForSoc, setFilteredDotForSoc] = useState();
  const [aptitudeData, setAptitudeData] = useState();
  const [socCodeData, setSocCodeData] = useState();
  const [goeCodeData, setGoeCodeData] = useState();
  const [oapCodeData, setoapCodeData] = useState();
  const [soc2018Definations, setSoc2018Definations] = useState();
  const [oewsNationalM2022Data, setOewsNationalM2022Data] = useState([]);
  const [orsFilterSummaryDemandData, setOrsFilterSummaryDemandData] = useState(
    []
  );
  const [orsFilterPhysicalDemandData, setOrsFilterPhysicalDemandData] =
    useState([]);
  const [orsFilterCognitiveDemandData, setOrsFilterCognitiveDemandData] =
    useState([]);
  const [orsFilterEducationalDemandData, setOrsFilterEducationalDemandData] =
    useState([]);
  const [orsFilterEnviromentalDemandData, setOrsFilterEnviromentalDemandData] =
    useState([]);
  const [CalEst, setCalEst] = useState([]);
  const [overallValue, setOverallValue] = useState(null);
  const [orsData, setOrsData] = useState(null);
  const [majorGroupDescription, setMajorGroupDescription] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const [allSvps, setAllSvps] = useState([]);

  const strengthOrder = {
    S: 1,
    L: 2,
    M: 3,
    H: 4,
    V: 5,
  };
  const sortResults = (sortKey) => {
    let sortOrderLocal = "asc";
    if (sortKey === selectedSortKey) {
      sortOrderLocal = sortOrder === "asc" ? "desc" : "asc";
    }
    setSelectedSortKey(sortKey);
    switch (sortKey) {
      case "code":
        setFilteredDotForSoc((prev) => {
          return sortOrderLocal === "asc"
            ? prev.sort((a, b) =>
                a.DotCodeFormated.localeCompare(b.DotCodeFormated)
              )
            : prev.sort((a, b) =>
                b.DotCodeFormated.localeCompare(a.DotCodeFormated)
              );
        });
        break;
      case "employmentEstimate":
        setFilteredDotForSoc((prev) => {
          return sortOrderLocal === "asc"
            ? prev.sort((a, b) => a.empEst - b.empEst)
            : prev.sort((a, b) => b.empEst - a.empEst);
        });
        break;
      case "svp":
        setFilteredDotForSoc((prev) => {
          return sortOrderLocal === "asc"
            ? prev.sort((a, b) => a.SVP - b.SVP)
            : prev.sort((a, b) => b.SVP - a.SVP);
        });
        break;
        case "physical":
          setFilteredDotForSoc((prev) => {
            const sorted = [...prev].sort((a, b) => {
              const aStrength = strengthOrder[a.Strength] || 0;
              const bStrength = strengthOrder[b.Strength] || 0;
              return sortOrderLocal === "asc"
                ? aStrength - bStrength
                : bStrength - aStrength;
            });
            return sorted;
          });
          break;
      // case "physical":
      //   setFilteredDotForSoc((prev) => {
      //     return sortOrderLocal === "asc"
      //       ? prev.sort((a, b) => a.Strength.localeCompare(b.Strength))
      //       : prev.sort((a, b) => b.Strength.localeCompare(a.Strength));
      //   });
      //   break;
      default:
        break;
    }
    setSortOrder(sortOrderLocal);
  };

  const getEmployementEstimate = async (dotCodes) => {
    // debugger
    const data = await handleGetEmploymentEstimateRequest(
      JSON.stringify(dotCodes).replaceAll("[", "").replace("]", "")
    );
    setEmployEstimate(data?.data?.data ?? []);
    return data?.data?.data ?? [];
  };

  useEffect(() => {
    setCalEst([]);
    fetch("GeneratedOrsDataWithDash.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const socCode = rowData["Soc2018Code"]?.replace("-", "");
      let formattedData = data.filter((d) => {
        return d.Soc2018Code === socCode;
      });
      setOrsData(formattedData);
      return;
    });
  }, []);

  useEffect(() => {
    const toFindSoc = rowData?.Soc2018Code;
    let isMajorGroup = false;
    let majorGroupLabels = [];
    if (toFindSoc.endsWith("0000")) {
      isMajorGroup = true;
    }

    fetch("Soc2918Definations.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (isMajorGroup) {
        const majorGroup = toFindSoc.slice(0, 2);
        const filteredMajorGroup = data.filter((row) => {
          const soc = row.SocCode?.replace("-", "");
          return soc.startsWith(majorGroup);
        });
                const majorGroupLabels = filteredMajorGroup.map((item) => ({
          SOC_Code: item.SocCode,
          title: item.SocTitle,
        }));
        setMajorGroupDescription(majorGroupLabels);
      }

      const filteredSoc = data.find((row) => {
        const soc = row.SocCode?.replace("-", "");
        return soc === rowData.Soc2018Code;
      });
      setSoc2018Definations(filteredSoc);
      // return;
    });
  }, []);

  const formateSummaryData = (data) => {
    const uniqueTitles = [
      ...new Set(data.map((item) => item.HypotheticalOrsSummerySection)),
    ];

    // Group RenameTitleTo by HypotheticalOrsSummerySection
    const groupedData = uniqueTitles.map((title) => ({
      HypotheticalOrsSummerySection: title,
      subtitles: data
        .filter((item) => item.HypotheticalOrsSummerySection === title)
        .map(({ RenameTitleTo, EstimateCode }) => {
          return {
            RenameTitleTo,
            EstimateCode,
          };
        }),
    }));
    return groupedData;
  };

  // Updating filters data
  const getOrsPhysicalDemands = (summaryEstimate) => {
    fetch("OrsPhysicalDemands.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let data = await response.json();
      data = data.filter(
        (d) =>
          !summaryEstimate.includes(d.EstimateCode) &&
          localStorageOrsFilters?.filters?.includes(d.EstimateCode)
      );
      setOrsFilterPhysicalDemandData(data);
      return;
    });
  };

  const getCofnitiveOrsDemands = (summaryEstimate) => {
    fetch("CofnitiveOrsDemands.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let data = await response.json();
      data = data.filter(
        (d) =>
          !summaryEstimate.includes(d.EstimateCode) &&
          localStorageOrsFilters?.filters?.includes(d.EstimateCode)
      );
      setOrsFilterCognitiveDemandData(data);
      return;
    });
  };

  const getOrsEducationalTrainingDemands = (summaryEstimate) => {
    fetch("OrsEducationalTrainingDemands.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let data = await response.json();
      data = data.filter(
        (d) =>
          !summaryEstimate.includes(d.EstimateCode) &&
          localStorageOrsFilters?.filters?.includes(d.EstimateCode)
      );
      
      // Extract estimate codes and store them in allSvps
      const estimateCodes = data?.map((subtitle) => subtitle.EstimateCode);

      // Check for specific estimate codes and append them to allSvps
      const specificEstimateCodes = [
        "00068",
        "00069",
        "00070",
        "00071",
        "00072",
      ];
      const newSvps = estimateCodes.filter(
        (code) =>
          specificEstimateCodes.includes(code) && !allSvps.includes(code)
      );

      // Update allSvps by appending new estimate codes
      setAllSvps((prevSvps) => [...prevSvps, ...newSvps]);
      setOrsFilterEducationalDemandData(data);
      return;
    });
  };

  const getOrsEnvironmentalDemands = (summaryEstimate) => {
    fetch("OrsEnvironmentalDemands.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let data = await response.json();
      data = data.filter(
        (d) =>
          !summaryEstimate.includes(d.EstimateCode) &&
          localStorageOrsFilters?.filters?.includes(d.EstimateCode)
      );
      setOrsFilterEnviromentalDemandData(data);
      return;
    });
  };

  useEffect(() => {
    fetch("HypotheticalSummeryCodes.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let data = await response.json();
      data = data.filter((d) =>
        localStorageOrsFilters?.filters?.includes(d.EstimateCode)
      );
      data = formateSummaryData(data);
     
      // Extract estimate codes and store them in allSvps
      const estimateCodes = Array.from(
        new Set(
          data?.flatMap(
            (filter) =>
              filter.subtitles
                .map((subtitle) => subtitle.EstimateCode)
                .filter((code) =>
                  ["00064", "00065", "00067", "00068"].includes(code)
                ) // Only include specific codes
          )
        )
      );

      // Update allSvps by appending new estimate codes
      setAllSvps((prevSvps) => [...prevSvps, ...estimateCodes]);
      setOrsFilterSummaryDemandData(data);
      const summaryEstimate = data
        .map((filter) => {
          return filter.subtitles.map((subtitle) => {
            return subtitle.EstimateCode;
          });
        })
        .flat();
      getOrsPhysicalDemands(summaryEstimate);
      getCofnitiveOrsDemands(summaryEstimate);
      getOrsEducationalTrainingDemands(summaryEstimate);
      getOrsEnvironmentalDemands(summaryEstimate);
      return;
    });
  }, []); // eslint-disable-line

  const fetchDataAndStore = (storeName, localStorageKey) => {
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = function (event) {
      console.error("Database error: " + event.target.errorCode);
    };

    request.onsuccess = function (event) {
      const db = event.target.result;

      const transaction = db.transaction([storeName], "readonly");
      const store = transaction.objectStore(storeName);

      const allRecords = [];

      store.openCursor().onsuccess = function (event) {
        const cursor = event.target.result;
        if (cursor) {
          allRecords.push(cursor.value);
          cursor.continue();
        } else {
          if (localStorageKey) {
            localStorage.setItem(localStorageKey, JSON.stringify(allRecords));
          }
        }
      };
    };
  };

  const [dbAllDataForEstEmp, setDbAllDataForEstEmp] = useState([]);

  useEffect(() => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["OewsNationalM2022"], "readonly");
      const objectStore = transaction.objectStore("OewsNationalM2022");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        
        setDbAllDataForEstEmp(getData?.result);
        const idToMatch = rowData["Soc2018Code"];
        const filteredOews = getData.result?.find((value) => {
          return value.OccCode.replace("-", "") === idToMatch;
        });

        setOewsNationalM2022Data(filteredOews);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OewsNationalM2022", {
        autoIncrement: true,
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // get list estimated codes
  const getListEstimatedEmployment = (soc_code) => {
    
    let soc_code_without_dash = soc_code.replace("-", ""); // Remove the dash
    
    const filteredOews = dbAllDataForEstEmp?.find((value) => {
      return value.OccCode.replace("-", "") === soc_code_without_dash;
    });
    
    // setOewsNationalM2022Data(filteredOews);
  };
  // get list estimated codes

  useEffect(() => {
    const storeMappings = [
      { storeName: "DotPhysicalDemands", localStorageKey: "PhysicalDemands" },
      {
        storeName: "EnvironmentalDemands",
        localStorageKey: "EnvironmentalDemands",
      },
      {
        storeName: "EnvironmentalDemandLevel",
        localStorageKey: "EnvironmentalDemandLevel",
      },
      { storeName: "DotGeds", localStorageKey: "DotGeds" },
      { storeName: "DotGedsLevels", localStorageKey: "DotGedsLevels" },
      { storeName: "Aptitudes", localStorageKey: "Aptitudes" },
    ];

    storeMappings.forEach(({ storeName, localStorageKey }) => {
      fetchDataAndStore(storeName, localStorageKey);
    });
  }, []);

  useEffect(() => {
    handleSearchDotCodeAgainstSocCode(rowData?.Soc2018Code);
  }, []);

  // useEffect(() => {
  //   majorGroupDescription?.length>0 && handleSearchDotCodeAgainstSocCode(rowData?.Soc2018Code);
  // }, [majorGroupDescription]);

  var PhysicalDemandsData = JSON.parse(localStorage.getItem("PhysicalDemands"));
  var EnvironmentalDemandsData = JSON.parse(
    localStorage.getItem("EnvironmentalDemands")
  );
  var EnvironmentalDemandLevelData = JSON.parse(
    localStorage.getItem("EnvironmentalDemandLevel")
  );
  var DotGedsData = JSON.parse(localStorage.getItem("DotGeds"));

  var DotGedsLevelsData = JSON.parse(localStorage.getItem("DotGedsLevels"));
  var AptitudesData = JSON.parse(localStorage.getItem("Aptitudes"));

  const handleSearchDotCodeAgainstSocCode = (socCodeFind) => {
    // Open the database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);
    request.onerror = function (event) {
      console.error("Database error: " + event.target.errorCode);
    };
    request.onsuccess = function (event) {
      const db = event.target.result;

      // Start a transaction and get the object store
      const transaction = db.transaction(["DOTMasterTable"], "readonly");

      const store = transaction.objectStore("DOTMasterTable");
      const index = store.index("DotCode");

      var partialMatchResults = [];

      // Open a cursor on the index
      index.openCursor().onsuccess = async function (event) {
        var cursor = event.target.result;
        if (cursor) {
          var socCodeMatch = decryptSocCode(
            String(cursor.value["2018SocCode"])
          );
          socCodeMatch = socCodeMatch.replace("-", "");

          if (socCodeMatch === socCodeFind) {
            partialMatchResults.push(cursor.value);
          } else if (socCodeFind.endsWith("0000")) {
            const majorGroup = socCodeFind.slice(0, 2);
            if (socCodeMatch.startsWith(majorGroup)) {
              partialMatchResults.push(cursor.value);
            }
          }
          cursor.continue();
        } else {
          const dotCodes = partialMatchResults.map((result) => result.DotCode);
          const empEstResult = await getEmployementEstimate(dotCodes);
          partialMatchResults = partialMatchResults.map((result) => {
            return {
              ...result,
              empEst: showEmployeeEstimate(result, empEstResult),
            };
          });
          setFilteredDotForSoc(partialMatchResults);
          setSearchResult(empEstResult);
        }
      };
    };
  };

  const handleSearchDotCode = (dotcodeFind) => {
    // Open the database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);
    request.onerror = function (event) {
      console.error("Database error: " + event.target.errorCode);
    };
    request.onsuccess = function (event) {
      const db = event.target.result;

      // Start a transaction and get the object store
      const transaction = db.transaction(["DOTMasterTable"], "readonly");

      const store = transaction.objectStore("DOTMasterTable");

      const index = store.index("DotCode");

      var partialMatchResults = [];

      // Open a cursor on the index
      index.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor) {
          var dotCodeMatch = String(cursor.value.DotCode);

          // Check if the dot_code contains the substring 'part_of_dot_code'
          if (dotCodeMatch.includes(dotcodeFind)) {
            partialMatchResults.push(cursor.value);
          }
          cursor.continue();
        } else {
          setTableDatabyDotCode(partialMatchResults[0]);
          matchedRecordsPhysical(partialMatchResults);
          matchedRecordsEnvironmental(partialMatchResults);
          matchedRecordsGEDs(partialMatchResults);
          matchedRecordsAptitudes(partialMatchResults);
          matchedRecordsCodes(partialMatchResults);
          partialMatchResults = [];
        }
      };
    };
  };

  const matchedRecordsPhysical = (partialMatchResults) => {
    var resultPhys = [];
    for (var i = 0; i < PhysicalDemandsData.length; i++) {
      var CombinePhysSlug = `Phys${+(i + 1)}`;
      var PhysDemandValue = partialMatchResults[0][CombinePhysSlug];
      var outputPhys = {
        name: PhysicalDemandsData[i]?.Name,
        value: PhysDemandValue,
      };
      resultPhys.push(outputPhys);
    }
    setPhysicalDemandData(resultPhys);
  };

  const matchedRecordsEnvironmental = (partialMatchResults) => {
    var result = [];
    for (var i = 0; i < EnvironmentalDemandsData.length; i++) {
      if (EnvironmentalDemandsData[i].IsMultiLevel) {
        var CombineEnvSlug = `Env${i + 1}`;
        var envDemandValue = partialMatchResults[0][CombineEnvSlug] - 1;

        var envDemandLevels = EnvironmentalDemandLevelData.filter(
          (row) => row.EnvironmentalDemandId === EnvironmentalDemandsData[i].Id
        );
        var output = {
          name: EnvironmentalDemandsData[i]?.Description,
          value: envDemandLevels[envDemandValue]?.Description,
        };
        result.push(output);
      } else {
        var CombineEnvSlug = `Env${+(i + 1)}`;
        var demandValue = partialMatchResults[0][CombineEnvSlug];

        var output = {
          name: EnvironmentalDemandsData[i]?.Description,
          value: demandValue,
        };
        result.push(output);
      }
    }
    setEnviromentalData(result);
  };

  const matchedRecordsGEDs = (partialMatchResults) => {
    var result = [];
    for (var i = 0; i < DotGedsData.length; i++) {
      if (i == 0) {
        // GedG mean reasoning
        var reasoningLevel = partialMatchResults[0].GedG;
        var outputGEDs = {
          name: DotGedsData[0]?.Name,
          value: reasoningLevel,
        };
        result.push(outputGEDs);
      } else if (i == 1) {
        // GedE mean math
        var mathLevel = partialMatchResults[0].GedE;
        var outputGEDs = {
          name: DotGedsData[1]?.Name,
          value: mathLevel,
        };
        result.push(outputGEDs);
      } else if (i == 2) {
        // GedD mean Language
        var languageLevel = partialMatchResults[0].GedD;
        var outputGEDs = {
          name: DotGedsData[2]?.Name,
          value: languageLevel,
        };
        result.push(outputGEDs);
      } else {
        break;
      }
    }
    setDotData(result);
  };

  const [product, setProduct] = useState(1); // State to store the product of array values

  const addUniqueEstimate = (estimate) => {
    if (!CalEst.includes(estimate)) {
      setCalEst((prevCalEst) => {
        const uniqueValues = new Set([...prevCalEst, estimate]);
        const uniqueArray = Array.from(uniqueValues);
        // Calculate the product of the unique array values
        const productResult = uniqueArray.reduce((acc, val) => acc * val, 1);
        setProduct(productResult); // Update the product state
        return uniqueArray;
      });
    }
  };

  const showEmployeeEstimate = (row, empEstResult) => {
    //  debugger
    const result = Object.keys(empEstResult)
      .filter((key) => key !== "resultCount") // Exclude 'total'
      .find((key) => {
        return (
          empEstResult[key].dot == row?.DotCode ||
          empEstResult[key].soc == row?.SOC_Code?.replace("-", "")
        );
      });
    if (result) {
      return empEstResult[result].dotEstEmp;
    }

    return "-";
  };
  

  useEffect(() => {
    if (oewsNationalM2022Data && product !== 1) {
      const calculatedValue = oewsNationalM2022Data.TotalEmp * product;
      setOverallValue(
        calculatedValue.toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      );
    }
  }, [oewsNationalM2022Data, product]);

  const getEstimate = (estimateCode) => {
    if (!orsData) return;

    let estimate, filteredData;
    if (estimateCode === "00064") {
      filteredData = orsData.find((ors) => {
        return (
          ors.EstimateCode === estimateCode &&
          ors.Soc2018Code.replace("-", "") ===
            rowData["Soc2018Code"].replace("-", "")
        );
      });
      estimate = Number(
        filteredData.Estimate.replace("<", "").replace(">", "")
      );

      const extraEst = orsData.find((ors) => {
        return (
          ors.EstimateCode === "00065" &&
          ors.Soc2018Code.replace("-", "") ===
            rowData["Soc2018Code"].replace("-", "")
        );
      });
      estimate += Number(extraEst.Estimate.replace("<", "").replace(">", ""));

      estimate = 100 - estimate;
    } else {
      filteredData = orsData.find((ors) => {
        return (
          ors.EstimateCode === estimateCode &&
          ors.Soc2018Code.replace("-", "") ===
            rowData["Soc2018Code"].replace("-", "")
        );
      });
      estimate =
        100 - Number(filteredData.Estimate.replace("<", "").replace(">", ""));
    }

    if (filteredData) return estimate;
    return "-";
  };

  const sumEstimates = (data, value) => {
    data?.forEach((filter) => (value += getEstimate(filter.EstimateCode)));
    return value;
  };

  const getErordedJobs = (estimateCode) => {
    if (
      !orsData &&
      !orsFilterPhysicalDemandData.length &&
      !orsFilterCognitiveDemandData.length &&
      !orsFilterEducationalDemandData.length &&
      !orsFilterEnviromentalDemandData.length
    )
      return;
    let value = 0;
    orsFilterSummaryDemandData?.forEach((filter) =>
      filter.subtitles?.forEach(
        (subtitle) => (value += getEstimate(subtitle.EstimateCode))
      )
    );
    value = sumEstimates(orsFilterPhysicalDemandData, value);
    value = sumEstimates(orsFilterCognitiveDemandData, value);
    value = sumEstimates(orsFilterEducationalDemandData, value);
    value = sumEstimates(orsFilterEnviromentalDemandData, value);

    const Normalizer = 1 / value;

    const filterPercentage = getEstimate(estimateCode);
    if (filterPercentage !== "-") {
      // Calculate the eroded percentage
      // Total Erosion = Total Group Employment - Estimated Employment
      const totalErosion =
        (oewsNationalM2022Data?.TotalEmp || 0) - rowData?.Estimate;

      // Erosion by Specific filter = Total Erosion * Filter Percentage * Normalizer
      const erodedJobs = totalErosion * Normalizer * filterPercentage;
      return Math.round(erodedJobs).toLocaleString("en-US");
    } else {
      return "-";
    }
  };

  const matchedRecordsAptitudes = (partialMatchResults) => {
    var result = [];
    for (var i = 0; i < AptitudesData.length; i++) {
      var demandAptChar = AptitudesData[i].ShortCharacter;
      var demandAptDesc = AptitudesData[i].Description;
      var combinedSlugApt = `Apt${demandAptChar}`;
      var demndValueApt = partialMatchResults[0][combinedSlugApt];
      var OapCode = partialMatchResults[0].OapCode;

      var outputAptitude = {
        description: demandAptDesc,
        value: demndValueApt,
        OapCode,
      };
      result.push(outputAptitude);
    }
    setAptitudeData(result);
  };

  const matchedRecordsCodes = (partialMatchResults) => {
    const [socCode, goeCode, oapCode] = [
      partialMatchResults[0].SocCode,
      partialMatchResults[0].GoeCode,
      partialMatchResults[0].OapCode,
    ];

    let socCodeResult = [];
    let goeCodeResult = [];
    let oapCodeResult = [];

    const handleRequestSuccess =
      (storeName, matchCode, resultArray, setResultData) => (event) => {
        const db = event.target.result;
        const transaction = db.transaction([storeName], "readonly");
        const store = transaction.objectStore(storeName);

        store.openCursor().onsuccess = function (event) {
          const cursor = event.target.result;
          if (cursor) {
            const codeMatch = String(cursor.value.Code);
            if (codeMatch == matchCode) {
              const output = {
                type: `${storeName.split("Code")[0].toUpperCase()}-${
                  cursor.value.Type
                }`,
                code: codeMatch,
                description: cursor.value.Text,
              };
              resultArray.push(output);
            }
            cursor.continue();
          } else {
            setResultData(resultArray);
          }
        };
      };

    const openDatabase = (storeName, matchCode, resultArray, setResultData) => {
      const request = window.indexedDB.open(dbName, dbCurrentVersion);
      request.onerror = (event) => {
        console.error("Database error: " + event.target.errorCode);
      };
      request.onsuccess = handleRequestSuccess(
        storeName,
        matchCode,
        resultArray,
        setResultData
      );
    };

    openDatabase("OesCodeDefination", socCode, socCodeResult, setSocCodeData);
    openDatabase("OesCodeDefination", goeCode, goeCodeResult, setGoeCodeData);
    openDatabase("OapCodeDefinations", oapCode, oapCodeResult, setoapCodeData);
  };
  
  const [erosionData, setErosionData] = useState({}); // New state to store erosion and jobs eroded

  // const getEstimateForSVPs = (estimateCodes) => {
  //   let totalEstimate = 0;
  //   let totalJobsEroded = 0;

  //   estimateCodes.forEach((code) => {
  //     const filteredData = orsData?.find((ors) => {
  //       return (
  //         ors.EstimateCode === code &&
  //         ors.Soc2018Code.replace("-", "") ===
  //           rowData["Soc2018Code"].replace("-", "")
  //       );
  //     });

  //     if (filteredData) {
  //       const estimate = 100 - Number(filteredData.Estimate.replace("<", "").replace(">", ""));
  //       totalEstimate += estimate;

  //       // Calculate jobs eroded using the same logic as getErordedJobs
  //       const jobsEroded = getErordedJobs(code);
  //       totalJobsEroded +=
  //         jobsEroded !== "-" ? parseInt(jobsEroded.replace(/,/g, ""), 10) : 0; // Convert to number
  //     }
  //   });

  //   setErosionData({ erosion: totalEstimate, jobsEroded: totalJobsEroded });
  // };

  // updated
  const getEstimateForSVPs = (estimateCodes) => {
    let totalEstimate = 0;
    let totalJobsEroded = 0;
  
    if (estimateCodes.length === 1) {
      // If there's only one estimate code, return its normal estimate
      const singleCode = estimateCodes[0];
      const filteredData = orsData?.find((ors) => {
        return (
          ors.EstimateCode === singleCode &&
          ors.Soc2018Code.replace("-", "") ===
            rowData["Soc2018Code"].replace("-", "")
        );
      });
  
      if (filteredData) {
        totalEstimate = 100 - Number(filteredData.Estimate.replace("<", "").replace(">", ""));
        totalJobsEroded = getErordedJobs(singleCode);
        totalJobsEroded = totalJobsEroded !== "-" ? parseInt(totalJobsEroded.replace(/,/g, ""), 10) : 0;
      }
    } else {
      // If multiple estimate codes exist, sum their estimates
      estimateCodes.forEach((code) => {
        const filteredData = orsData?.find((ors) => {
          return (
            ors?.EstimateCode === code &&
            ors?.Soc2018Code?.replace("-", "") ===
              rowData["Soc2018Code"]?.replace("-", "")
          );
        });
  
        if (filteredData) {
          totalEstimate += 100 - Number(filteredData.Estimate.replace("<", "")?.replace(">", ""));
        }
  
        // Calculate jobs eroded using the same logic as getErordedJobs
        const jobsEroded = getErordedJobs(code);
        totalJobsEroded += jobsEroded !== "-" ? parseInt(jobsEroded?.replace(/,/g, ""), 10) : 0;
      });
    }
  
    setErosionData({ erosion: totalEstimate, jobsEroded: totalJobsEroded });
  };
  
  // updated

  // Call this function where appropriate, e.g., after fetching data or in a useEffect
  // const getEstimateForSVPs = (estimateCodes) => {
  //   let totalEstimate = 0;
  //   let totalJobsEroded = 0;
  //   let appliedFiltersSum = 0; // To calculate the normalizer

  //   estimateCodes.forEach((code) => {
  //     const filteredData = orsData?.find((ors) => {
  //       return (
  //         ors.EstimateCode === code &&
  //         ors.Soc2018Code.replace("-", "") ===
  //           rowData["Soc2018Code"].replace("-", "")
  //       );
  //     });

  //     if (filteredData) {
  //       const filterPercentage =
  //         100 - Number(filteredData.Estimate.replace("<", "").replace(">", ""));
  //       const appliedFilter = filterPercentage / 100; // Convert to decimal
  //       appliedFiltersSum += 1 - appliedFilter; // Sum for normalizer

  //       totalEstimate += filterPercentage;

  //       // Calculate jobs eroded using the new formula
  //       const totalErosion =
  //         (oewsNationalM2022Data?.TotalEmp || 0) - rowData?.Estimate; // Total Erosion calculation
  //       totalJobsEroded += Math.round(
  //         totalErosion * (1 - appliedFilter) * (1 / appliedFiltersSum)
  //       ); // Round to nearest whole number
  //     }
  //   });

  //   const normalizer = 1 / appliedFiltersSum; // Calculate normalizer
  //   setErosionData({ erosion: totalEstimate, jobsEroded: totalJobsEroded });
  // };

  useEffect(() => {
    getEstimateForSVPs(allSvps);
  }, [orsData, rowData, allSvps]);
  console.log("filteredDotForSoc", filteredDotForSoc);

// for displaying on the basis of specific svps and strength 
// Add these mapping objects at the top of your component
const svpMapping = {
  '00064': '1',
  '00065': '2',
  '00066': '3',
  '00067': '4',
  '00068': '5',
  '00069': '6',
  '00070': '7',
  '00071': '8',
  '00072': '9'
};

const strengthMapping = {
  '00661': 'S',
  '00662': 'L',
  '00663': 'M',
  '00664': 'H',
  '00665': 'V'
};

// Get the filters and create filtered data
const filters = JSON.parse(sessionStorage.getItem("ORSFiltersData"))?.filters || [];

// Get selected SVP and Strength values
const selectedSVP = filters
  .filter(code => svpMapping[code])
  .map(code => svpMapping[code]);

const selectedStrength = filters
  .filter(code => strengthMapping[code])
  .map(code => strengthMapping[code]);
console.log("selectedStrength", selectedStrength, "selectedSVP", selectedSVP);
// Filter the data
// Convert SVP values to strings when filtering
const filteredData = filteredDotForSoc?.filter(row => {
  // Convert row.SVP to string for comparison
  const rowSVP = String(row.SVP);
  
  // If both SVP and Strength filters are present
  if (selectedSVP.length > 0 && selectedStrength.length > 0) {
    return selectedSVP.includes(rowSVP) && selectedStrength.includes(row.Strength);
  }
  // If only SVP filters are present
  else if (selectedSVP.length > 0) {
    return selectedSVP.includes(rowSVP);
  }
  // If only Strength filters are present
  else if (selectedStrength.length > 0) {
    return selectedStrength.includes(row.Strength);
  }
  // If no filters are present, show all records
  return true;
});

// Add some debugging to verify
console.log("Filtered rows:", filteredData?.map(row => ({
  SVP: row.SVP,
  matched: selectedSVP.includes(String(row.SVP))
})));


  
  return (
    <>
      <Container
        maxWidth={false}
        sx={{ paddingTop: "80px", paddingBottom: 2, px: { xs: "10px" } }}
      >
        <Grid container spacing={"10px"}>
          <Grid item xs={6}>
            <Stack
              spacing={1}
              className="bg-white"
              padding={1}
              borderRadius={"10px"}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                padding={0}
              >
                <Box>
                  <Typography variant="h6" component={"h6"}>
                    {soc2018Definations?.SocTitle}
                  </Typography>
                </Box>
              </Stack>
              <Box>
                <Stack padding={1} sx={{ borderBottom: "1px solid #666672" }}>
                  <Box
                    sx={{
                      paddingBottom: "3px",
                    }}
                  >
                    <Typography variant="h6" component={"h6"}>
                      Applied Filter
                    </Typography>
                  </Box>
                  <TableContainer
                    component={Paper}
                    sx={{
                      backgroundColor: "transparent",
                      paddingBottom: 0,
                      paddingTop: 0,
                      boxShadow: "none",
                      marginTop: "10px",
                    }}
                  >
                    <Table sx={{ minWidth: 550 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Filter</TableCell>
                          {/* <TableCell className="!text-end">Erosion %</TableCell> */}
                          <TableCell className="!text-end">
                            Jobs Eroded
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {erosionData && (
                          <TableRow
                            className="hover:bg-gray-200"
                            sx={{
                              "& td, & th": {
                                border: 0,
                                padding: "4px !important",
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              SVP
                            </TableCell>
                            {/* <TableCell align="right">
                              {`${parseFloat(erosionData.erosion)?.toFixed(1)}%`}{" "}
                            </TableCell> */}
                            <TableCell align="right">
                              {erosionData.jobsEroded?.toLocaleString()}
                            </TableCell>
                          </TableRow>
                        )}
                        {orsFilterSummaryDemandData?.map((filter) =>
                          filter.subtitles?.map((subtitle) => {
                            // Check if the EstimateCode is not one of the specified codes
                            if (
                              !["00064", "00065", "00066", "00067"].includes(
                                subtitle.EstimateCode
                              )
                            ) {
                              return (
                                <TableRow
                                  className="hover:bg-gray-200"
                                  sx={{
                                    "& td, & th": {
                                      border: 0,
                                      padding: "4px !important",
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {subtitle.RenameTitleTo !== "NULL"
                                      ? "SVP"
                                      : filter.HypotheticalOrsSummerySection}
                                  </TableCell>
                                  {/* <TableCell align="right">
                                    {`${parseFloat(
                                      getEstimate(
                                        subtitle?.EstimateCode
                                      )?.toFixed(1)
                                    )}%`}
                                  </TableCell> */}
                                  <TableCell align="right">
                                    {getErordedJobs(subtitle.EstimateCode)}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                            return null; // Return null for excluded EstimateCodes
                          })
                        )}
                        {orsFilterPhysicalDemandData?.map((filter) => {
                          return (
                            <TableRow
                              className="hover:bg-gray-200"
                              sx={{
                                "& td, & th": {
                                  border: 0,
                                  padding: "4px !important",
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ maxWidth: 100 }}
                              >
                                <div className="flex flex-wrap">
                                  {filter.Defination}
                                </div>
                              </TableCell>
                              {/* <TableCell align="right">
                                {`${parseFloat(
                                  getEstimate(filter.EstimateCode)?.toFixed(1)
                                )}%`}
                              </TableCell> */}
                              <TableCell align="right">
                                {getErordedJobs(filter.EstimateCode)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {orsFilterCognitiveDemandData?.map((filter) => {
                          return (
                            <TableRow
                              className="hover:bg-gray-200"
                              sx={{
                                hover: {
                                  backgroundColor: "#F5F5F5",
                                },
                                "& td, & th": {
                                  border: 0,
                                  padding: "4px !important",
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ maxWidth: 100 }}
                              >
                                <div className="flex flex-wrap">
                                  {filter.Defination}
                                </div>
                              </TableCell>
                              {/* <TableCell align="right">
                                {`${parseFloat(
                                  getEstimate(filter.EstimateCode)?.toFixed(1)
                                )}%`}
                              </TableCell> */}
                              <TableCell align="right">
                                {getErordedJobs(filter.EstimateCode)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {orsFilterEducationalDemandData?.map((filter) => {
                          // Check if the EstimateCode is not one of the specified codes
                          if (
                            ![
                              "00068",
                              "00069",
                              "00070",
                              "00071",
                              "00072",
                            ].includes(filter.EstimateCode)
                          ) {
                            return (
                              <TableRow
                                className="hover:bg-gray-200"
                                sx={{
                                  hover: {
                                    backgroundColor: "#F5F5F5",
                                  },
                                  "& td, & th": {
                                    border: 0,
                                    padding: "4px !important",
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ maxWidth: 100 }}
                                >
                                  <div className="flex flex-wrap">
                                    {filter.Defination}
                                  </div>
                                </TableCell>
                                {/* <TableCell align="right">
                                  {`${parseFloat(
                                    getEstimate(filter.EstimateCode)?.toFixed(1)
                                  )}%`}
                                </TableCell> */}
                                <TableCell align="right">
                                  {getErordedJobs(filter.EstimateCode)}
                                </TableCell>
                              </TableRow>
                            );
                          }
                          return null; // Return null for excluded EstimateCodes
                        })}
                        {orsFilterEnviromentalDemandData?.map((filter) => {
                          return (
                            <TableRow
                              className="hover:bg-gray-200"
                              sx={{
                                hover: {
                                  backgroundColor: "#F5F5F5",
                                },
                                "& td, & th": {
                                  border: 0,
                                  padding: "4px !important",
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ maxWidth: 100 }}
                              >
                                <div className="flex flex-wrap">
                                  {filter.Defination}
                                </div>
                              </TableCell>
                              {/* <TableCell align="right">
                                {`${parseFloat(
                                  getEstimate(filter.EstimateCode)?.toFixed(1)
                                )}%`}
                              </TableCell> */}
                              <TableCell align="right">
                                {getErordedJobs(filter.EstimateCode)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </Box>
              <Box sx={{ backgroundColor: "#F5F5F5" }} borderRadius={"10px"}>
                <Stack direction={"row"} padding={1}>
                  <Box textAlign={"center"} flexGrow={1}>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      {rowData?.Estimate?.toLocaleString("en-US", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) || "-"}
                      {/* {(overallValue && overallValue) || "-"} */}
                    </Typography>
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Est. Employment
                    </Typography>
                  </Box>
                  <Box textAlign={"center"} flexGrow={1}>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      {oewsNationalM2022Data?.TotalEmp?.toLocaleString(
                        "en-US"
                      ) || "-"}
                    </Typography>
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Total Group Employment
                    </Typography>
                  </Box>
                  <Box textAlign={"center"} flexGrow={1}>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      {oewsNationalM2022Data?.H_Mean?.toLocaleString("en-US") ||
                        "-"}
                    </Typography>
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Hourly Mean Wage
                    </Typography>
                  </Box>
                  <Box textAlign={"center"} flexGrow={1}>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      {oewsNationalM2022Data?.A_Mean?.toLocaleString("en-US") ||
                        "-"}
                    </Typography>
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Annual Mean Wage
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              {DOTTitleViewDisplay && (
                <Box
                  className="bg-white"
                  padding={1}
                  borderRadius={"10px"}
                  marginTop={"10px"}
                  position={"relative"}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{ width: "100%" }}
                  >
                    <Typography variant="h6">DOT Title View</Typography>
                    <Box>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="none"
                        sx={{
                          "& .MuiTabs-flexContainer": {
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: 2,
                          },
                        }}
                      >
                        <Tab
                          label="Description"
                          icon={<DescriptionIcon />}
                          iconPosition="start"
                          {...a11yProps(0)}
                          sx={{
                            color:
                              value === 0
                                ? "#ffffff !important"
                                : "#666672 !important",
                            backgroundColor:
                              value === 0 ? "#0089D7" : "#F5F5F5",
                            minHeight: "20px",
                            paddingX: "10px",
                            paddingY: "8px",
                            alignItems: "end",
                          }}
                        />
                        <Tab
                          label="Requirements"
                          {...a11yProps(1)}
                          icon={<RequirementsIcon />}
                          iconPosition="start"
                          sx={{
                            color:
                              value === 1
                                ? "#ffffff !important"
                                : "#666672 !important",
                            backgroundColor:
                              value === 1 ? "#0089D7" : "#F5F5F5",
                            minHeight: "20px",
                            paddingX: "10px",
                            paddingY: "8px",
                            alignItems: "end",
                          }}
                        />
                        <Tab
                          label="Codes"
                          icon={<CodeIcon />}
                          iconPosition="start"
                          {...a11yProps(2)}
                          sx={{
                            color:
                              value === 2
                                ? "#ffffff !important"
                                : "#666672 !important",
                            backgroundColor:
                              value === 2 ? "#0089D7" : "#F5F5F5",
                            minHeight: "20px",
                            paddingX: "10px",
                            paddingY: "8px",
                            alignItems: "end",
                          }}
                        />
                        <a
                          href={`/dotDetail?dotCode=${tableDatabyDotCode?.DotCodeFormated}`}
                          target="_blank"
                        >
                          <button
                            style={{
                              backgroundColor: "#F5F5F5",
                              minHeight: "20px",
                              padding: "10px 8px",
                              display: "flex",
                              alignItems: "end",
                              borderRadius: 10,
                              color: "#666672",
                              cursor: "alias",
                            }}
                          >
                            Link
                          </button>
                        </a>
                      </Tabs>
                    </Box>
                  </Stack>

                  <TabPanel value={value} index={0}>
                    <Box>
                      <Stack
                        direction={"row"}
                        my={1}
                        gap={1}
                        alignItems={"center"}
                      >
                        <Box flexGrow={1}>
                          <Typography
                            variant="p"
                            component={"p"}
                            color={"primary"}
                          >
                            {tableDatabyDotCode?.Title}
                          </Typography>
                        </Box>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                        >
                          <Typography
                            variant="p"
                            component={"p"}
                            color={"text.grey"}
                            sx={{ display: "flex" }}
                          >
                            <CodeIcon />
                            Code
                          </Typography>
                          <Typography
                            variant="h6"
                            component={"h6"}
                            fontSize={"0.875rem"}
                          >
                            {tableDatabyDotCode?.DotCodeFormated}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                        marginBottom={1}
                      >
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"text.grey"}
                          sx={{ display: "flex" }}
                        >
                          {/* TODO: I have to show the titles of each SOC if it is Major group */}
                          {formatString(
                            tableDatabyDotCode?.DescriptionCombined || "-"
                          )}
                        </Typography>
                      </Stack>
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <RequirementsTab
                      rowData={tableDatabyDotCode}
                      DotData={DotData}
                      PhysicalDemandData={PhysicalDemandData}
                      dbCurrentVersion={dbCurrentVersion}
                      enviromentalData={enviromentalData}
                      tableDatabyDotCode={tableDatabyDotCode}
                      aptitudeData={aptitudeData}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Stack
                      direction={"row"}
                      my={1}
                      gap={1}
                      alignItems={"center"}
                    >
                      <Box flexGrow={1}>
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"primary"}
                        >
                          {tableDatabyDotCode?.Title}
                        </Typography>
                      </Box>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"text.grey"}
                          sx={{ display: "flex" }}
                        >
                          <CodeIcon />
                          Code
                        </Typography>
                        <Typography
                          variant="h6"
                          component={"h6"}
                          fontSize={"0.875rem"}
                        >
                          {tableDatabyDotCode?.DotCodeFormated}
                        </Typography>
                      </Stack>
                    </Stack>
                    <CodeTab
                      {...{
                        tableDatabyDotCode: filteredDotForSoc?.find(
                          (item, index) => {
                            const selectedDotCode = Number(
                              Object.keys(selectedItems)[0]
                            );
                            return Number(item.DotCode) === selectedDotCode;
                          }
                        ),
                        rowData: filteredDotForSoc?.find((item, index) => {
                          const selectedDotCode = Number(
                            Object.keys(selectedItems)[0]
                          );
                          return Number(item.DotCode) === selectedDotCode;
                        }),
                      }}
                    />
                  </TabPanel>
                </Box>
              )}
              <Box>
                <Stack padding={1} sx={{ borderTop: "1px solid #666672" }}>
                  <Box
                    sx={{
                      paddingBottom: "3px",
                    }}
                  >
                    <Typography variant="h6" component={"h6"}>
                      {majorGroupDescription != null ? "" : "Description"}
                    </Typography>
                  </Box>
                  <Box sx={{ paddingTop: "5px" }}>
                    <Typography variant="p" component={"p"}>
                      {majorGroupDescription != null ? (
                        <TableContainer
                          component={Paper}
                          sx={{
                            padding: 0,
                            boxShadow: "none",
                            borderRadius: "10px",
                            border: "1px solid #666672",
                          }}
                        >
                          <Table>
                            <TableHead>
                              <TableRow
                                sx={{ borderBottom: "1px solid #666672" }}
                              >
                                <TableCell sx={{ whiteSpace: "nowrap" }}>
                                  SOC Code
                                </TableCell>
                                <TableCell sx={{ paddingLeft: "12px" }}>
                                  Title
                                </TableCell>
                                <TableCell sx={{ paddingLeft: "12px" }}>
                                  {/* Employment# */}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {majorGroupDescription?.map((data, index) => (
                                <TableRow key={index}>
                                  <TableCell>{data?.SOC_Code}</TableCell>
                                  <TableCell sx={{ paddingLeft: "12px" }}>
                                    {data?.title}
                                  </TableCell>
                                  <TableCell sx={{ paddingLeft: "12px" }}>
                                    {/* {showEmployeeEstimateBySocCode(data)?.slice(
                                      0,
                                      7
                                    )} */}
                                    {/* {(() => {
                                      const estimate =
                                        showEmployeeEstimateBySocCode(data);
                                      return console.log("showEmployeeEstimateBySocCode",estimate );
                                    })()} */}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        // <ol>
                        //   {majorGroupDescription.map((item) => (
                        //     <li>{item}</li>
                        //   ))}
                        // </ol>
                        soc2018Definations?.SocDefination || "-"
                      )}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              {/* <Box sx={{ backgroundColor: "#F5F5F5" }} borderRadius={"10px"}>
                <Stack padding={1}>
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    justifyContent={"space-between"}
                    spacing={1}
                  >
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Total DOT Titles
                    </Typography>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      {filteredDotForSoc?.length || 0}
                    </Typography>
                  </Stack>
                </Stack>
              </Box> */}
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack
              spacing={1}
              className="bg-white"
              padding={1}
              borderRadius={"10px"}
            >
              <Box>
                <Typography variant="h6" component={"h6"}>
                  {soc2018Definations
                    ? soc2018Definations.SocTitle
                    : "SOC Title"}{" "}
                  in SOC{" "}
                  {rowData["Soc2018Code"].slice(0, 2) +
                    "-" +
                    rowData["Soc2018Code"].slice(2)}{" "}
                  {rowData["Title"]}
                </Typography>
              </Box>
              <Box sx={{ backgroundColor: "#F5F5F5" }} borderRadius={"10px"}>
                <Stack padding={1}>
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    justifyContent={"space-between"}
                    spacing={1}
                  >
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Total DOT Titles
                    </Typography>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      {filteredDotForSoc?.length || 0}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              <TableContainer
                component={Paper}
                sx={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  boxShadow: "none",
                }}
              >
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">DOT</TableCell>
                      <TableCell align="left">Title</TableCell>
                      <CustomTableCell
                        align="left"
                        sortResultsKey="svp"
                        sortOrder={sortOrder}
                        currentSortKey={selectedSortKey}
                        onSort={sortResults}
                        label="SVP"
                        sortKey="svp"
                      />
                      <CustomTableCell
                        align="left"
                        sortResultsKey="physical"
                        sortOrder={sortOrder}
                        currentSortKey={selectedSortKey}
                        onSort={sortResults}
                        label="Strength"
                        sortKey="physical"
                      />
                      <CustomTableCell
                        align="left"
                        sortResultsKey="employmentEstimate"
                        sortOrder={sortOrder}
                        currentSortKey={selectedSortKey}
                        onSort={sortResults}
                        label="Employment Estimate"
                        sortKey="employmentEstimate"
                      />
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData?.map(
                    // {filteredDotForSoc?.map(
                      (row) => (
                        
                        (
                          <TableRow
                            key={row.DotCodeFormated}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              "& td, & th": { paddingY: "4px !important" },
                              "&:hover": { backgroundColor: "#E6F3FB66" },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <span className="whitespace-nowrap">
                                {row.DotCodeFormated}
                              </span>
                            </TableCell>
                            <TableCell align="left">{row.Title}</TableCell>
                            <TableCell align="left">{row.SVP}</TableCell>
                            <TableCell align="left">{row.Strength}</TableCell>
                            <TableCell align="left">
                              {row.empEst
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </TableCell>
                            <TableCell align="right">
                              <ToggleButton
                                size="small"
                                value="check"
                                className={`${
                                  selectedItems[row.DotCode]
                                    ? "EyeIconOffButton"
                                    : "EyeIconButton"
                                }`}
                                sx={{ border: 0 }}
                                selected={!!selectedItems[row.DotCode]} // Use double negation to ensure boolean
                                onChange={() => handleToggleSelect(row.DotCode)}
                                // disabled={
                                //   isAnyItemSelected && !selectedItems[row.DOT]
                                // }
                              >
                                {selectedItems[row.DotCode] ? (
                                  <SvgIcon
                                    component={EyeIconOff}
                                    inheritViewBox
                                  />
                                ) : (
                                  <SvgIcon component={EyeIcon} inheritViewBox />
                                )}
                              </ToggleButton>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SOCDOTGroupInfo;
