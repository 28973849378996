import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { handleGetRequest } from "../../../services/GetTemplate";
import { reverseString } from "../utils";
import { decryptSocCode } from "../../../utils/decryptSocCode";

export function addCommaAfterThree(number) {
  if (!number) return "";
  const numberStr = number?.toString();
  return numberStr.replace(/(\d{3})(?=\d)/g, "$1,");
}

export function splitIntoTwo(number) {
  if (!number) return "";
  const strNumber = number.toString(); // Convert number to string

  const firstThree = strNumber.slice(0, 3);

  const lastThree = strNumber.slice(-3);
  return [Number(firstThree), Number(lastThree)];
}

export const CodeTab = ({ tableDatabyDotCode, rowData }) => {
  const [goeDefinations, setGoeDefinations] = useState([]);
  const [naicDefinations, setNaicDefinations] = useState([]);
  const [codeDefinations, setCodeDefinations] = useState([]);
  const [socOnetDefinitions, setSocOnetDefinitions] = useState([]);
  let dbName = "veToolkitDB";
  let dbCurrentVersion;
  const [selectedNetSoc, setSelectedNetSoc] = useState({});
  const [oewsNationalM2022Data, setOewsNationalM2022Data] = useState({});

  const onSearchNaice = (item) => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["OewsNationalM2022"], "readonly");
      const objectStore = transaction.objectStore("OewsNationalM2022");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        // console.log("getData.result ==>", getData.result);
        let socCode = item["2018SocCode"];
        if (item) {
          socCode = item["2018SocCode"];
        }

        const result = getData.result.find(
          (row) => row.OccCode.replace("-", "") == item["2018SocCode"]
        );
        setOewsNationalM2022Data(
          result ?? {
            OccCode:
              item["2018SocCode"].slice(0, 2) +
              "-" +
              item["2018SocCode"].slice(2),
          }
        );
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OewsNationalM2022", {
        autoIncrement: true,
      });
    };
  };

  const onSearchNet = (item) => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["OnnetSocConversions"], "readonly");
      const objectStore = transaction.objectStore("OnnetSocConversions");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data

        const result = getData.result.find(
          (row) => Number(row.DotCode) == Number(item?.DotCode)
        );
        setSelectedNetSoc(result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OnnetSocConversions", { autoIncrement: true });
    };
  };

  useEffect(() => {
    const updatedData = {
      ...rowData,
      "2018SocCode": decryptSocCode(rowData["2018SocCode"]),
    };
    onSearchNet(updatedData);
    onSearchNaice(updatedData);
  }, [rowData]);
  useEffect(() => {
    fetch("GoeDefinations.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setGoeDefinations(data);
      return;
    });
    fetch("socOnetDefinitions.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSocOnetDefinitions(data);
      return;
    });
    fetch("LongDefinations.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCodeDefinations(data);
      return;
    });
    fetch("niacDefinitionsV2.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setNaicDefinations(data);
      return;
    });
  }, []);
 console.log("MPSMS Code", tableDatabyDotCode.MPSMS);
  const getGoeTitle = (goeCode) => {
    const formattedGoeCode = "0" + goeCode;
    const filteredGoe = goeDefinations?.find((goe) => {
      return goe.SocCode === formattedGoeCode;
    });
    return filteredGoe ? filteredGoe.GoeTitle : "-";
  };
  const getOnetTitle = (OnnetCode) => {
    const formattedGoeCode = OnnetCode;
    const filteredOnet = socOnetDefinitions?.find((onet) => {
      return onet.ONET_SOC_C_8 === formattedGoeCode;
    });
    return filteredOnet ? filteredOnet.LayTitle_c_128 : "-";
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        borderRadius={"10px"}
        backgroundColor={"#F5F5F5"}
        marginBottom={"5px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          sx={{
            borderBottom: "1px solid #666672",
            padding: "10px",
          }}
        >
          <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
            width={"20%"}
          >
            Type
          </Typography>

          <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
            width={"20%"}
          >
            Code
          </Typography>
          <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
            width={"20%"}
          >
            Definitions
          </Typography>
          {/* <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
          >
            Description
          </Typography> */}
        </Stack>

        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box
            borderRadius={"10px"}
            backgroundColor={"#F5F5F5"}
            marginBottom={"5px"}
          >
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              sx={{
                paddingTop: "10px",
                paddingX: "10px",
                paddingBottom: "5px",
                backgroundColor: "#eaeaea",
              }}
              className="hover:bg-white"
            >
              <Typography variant="p" component={"p"} width={"20%"}>
                MPSMS
              </Typography>
              <Stack width={"80%"} direction={"column"} gap={"20px"}>
                {codeDefinations?.map((item, index) => {
                  return (
                    item?.Code === tableDatabyDotCode?.MPSMS && item?.TypeName === "MPSMS" &&
                    (() => {
                      const isItemPresent = item.LongDefination !== "NULL";
                      return isItemPresent ? (
                        <Stack direction={"row"}>
                          <Typography variant="p" component={"p"} width={"25%"}>
                            {item?.Code}
                          </Typography>
                          <Typography variant="p" component={"p"} width={"75%"}>
                            {item?.LongDefination}
                          </Typography>
                        </Stack>
                      ) : (
                        <></>
                      );
                    })()
                  );
                })}
              </Stack>

              {/* <Typography variant="p" component={"p"}>
                            ...
                          </Typography> */}
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              sx={{
                paddingTop: "10px",
                paddingX: "10px",
                paddingBottom: "5px",
              }}
              className="hover:bg-white"
            >
              <Typography variant="p" component={"p"} width={"20%"}>
                GOE
              </Typography>
              <Typography variant="p" component={"p"} width={"20%"}>
                {/* {rowData?.GoeCode} */}
                {rowData?.GoeCode &&
                  `${reverseString(
                    rowData.GoeCode.toString().slice(0, 2)
                  )}.${reverseString(
                    rowData.GoeCode.toString().slice(2, 4)
                  )}.${reverseString(rowData.GoeCode.toString().slice(4, 6))}`}
              </Typography>
              <Typography variant="p" component={"p"} width={"20%"}>
                {getGoeTitle(tableDatabyDotCode?.GoeCode).split(":")[1]?.trim()}
              </Typography>
              {/* <Typography variant="p" component={"p"}>
                            ...
                          </Typography> */}
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              sx={{
                paddingTop: "10px",
                paddingX: "10px",
                paddingBottom: "5px",
                backgroundColor: "#eaeaea",
              }}
              className="hover:bg-white"
            >
              <Typography variant="p" component={"p"} width={"20%"}>
                RIASEC
              </Typography>
              {/* <Typography variant="p" component={"p"} width={"20%"}>
                {rowData?.RIASEC}
              </Typography>
              <Typography
                className="flex flex-col gap-5"
                variant="p"
                component={"p"}
                width={"60%"}
              >
                {codeDefinations?.map((item, index) => {
                  return item?.Code === tableDatabyDotCode?.RIASEC ? (
                    <span key={index}>{item?.LongDefination}</span>
                  ) : (
                    <></>
                  );
                })}
              </Typography> */}
              <Box width={"80%"}>
                            {codeDefinations?.map((item, index) => {
                              // Convert RIASEC into an array
                              const riasecArray =
                                tableDatabyDotCode?.RIASEC?.split("") || [];
                              return (
                                riasecArray.includes(item?.Code) &&
                                item?.TypeName === "RIASEC" && (
                                  <Stack direction={"row"} mb={"5px"} >
                                    <Typography
                                      width={"25%"}
                                      key={index}
                                      variant="p"
                                      component={"p"}
                                      style={{ display: "block" }}
                                    >
                                      {item.Code}
                                    </Typography>

                                    <Typography
                                      className="flex flex-col gap-2"
                                      variant="p"
                                      component={"p"}
                                      width={"75%"}
                                    >
                                      <span key={index}>
                                        {item?.LongDefination}
                                      </span>
                                    </Typography>
                                  </Stack>
                                )
                              );
                            })}
                          </Box>
              {/* <Typography variant="p" component={"p"}>
                            ...
                          </Typography> */}
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              sx={{
                paddingTop: "10px",
                paddingX: "10px",
                paddingBottom: "5px",
              }}
              className="hover:bg-white"
            >
              <Typography variant="p" component={"p"} width={"20%"}>
                Work Fields
              </Typography>
              <Stack width={"80%"} direction={"column"} gap={"20px"}>
                {codeDefinations?.map((item, index) => {
                  return (
                    item.TypeName === "Work Fields" &&
                    (() => {
                      const isItemPresent = splitIntoTwo(
                        tableDatabyDotCode?.WorkFields
                      )?.includes(item?.Code);
                      return isItemPresent ? (
                        <Stack direction={"row"}>
                          <Typography variant="p" component={"p"} width={"25%"}>
                            {item?.Code}
                          </Typography>
                          <Typography variant="p" component={"p"} width={"75%"}>
                            {item?.LongDefination}
                          </Typography>
                        </Stack>
                      ) : (
                        <></>
                      );
                    })()
                  );
                })}
              </Stack>
              {/* <Typography variant="p" component={"p"}>
                            ...
                          </Typography> */}
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              sx={{
                paddingTop: "10px",
                paddingX: "10px",
                paddingBottom: "5px",
                backgroundColor: "#eaeaea",
              }}
              className="hover:bg-white"
            >
              <Typography variant="p" component={"p"} width={"20%"}>
                O*NET
              </Typography>
              <Typography variant="p" component={"p"} width={"20%"}>
                {/* {selectedNetSoc?.OnetSocCode} */}
                {selectedNetSoc?.OnetSocCode &&
                  `${selectedNetSoc?.OnetSocCode.toString().slice(
                    0,
                    2
                  )}-${selectedNetSoc?.OnetSocCode.toString().slice(
                    2,
                    6
                  )}.${selectedNetSoc?.OnetSocCode.toString().slice(6, 8)}`}
              </Typography>
              <Typography variant="p" component={"p"} width={"20%"}>
                {getOnetTitle(selectedNetSoc?.OnetSocCode)}
              </Typography>
              {/* <Typography variant="p" component={"p"}>
                            ...
                          </Typography> */}
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              sx={{
                paddingTop: "10px",
                paddingX: "10px",
                paddingBottom: "5px",
              }}
              className="hover:bg-white"
            >
              <Typography variant="p" component={"p"} width={"20%"}>
                SOC Code
              </Typography>
              <Typography variant="p" component={"p"} width={"20%"}>
                {oewsNationalM2022Data?.OccCode}
              </Typography>
              <Typography variant="p" component={"p"} width={"20%"}>
                {oewsNationalM2022Data?.OccTitle}
              </Typography>
            </Stack>
            {/* <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              sx={{
                paddingTop: "10px",
                paddingX: "10px",
                paddingBottom: "5px",
              }}
              className="hover:bg-white"
            >
              <Typography variant="p" component={"p"} width={"20%"}>
                NAICS
              </Typography>
              <Typography variant="p" component={"p"} width={"20%"}>
                {oewsNationalM2022Data?.Naics}
              </Typography>
              {naicDefinations?.map((item, index) => {
                return  item?.NAICS === oewsNationalM2022Data?.Naics ? (
                  <Typography
                    key={index}
                    variant="p"
                    component={"p"}
                    width={"20%"}
                  >
                    {item?.Title}
                  </Typography>
                ) : (
                  <></>
                );
              })}
            </Stack> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
