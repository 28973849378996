import { baseURL } from "../Config";
import axios from "axios";
import { toast } from "react-toastify";

export const handleGetEmploymentEstimateAllDataRequest = async (
  dots,
  url = "transferable-skills/estimated-emp-all-data",
  isShowToast = false
) => {
  
  try {
    const response = await axios.post(
      `${baseURL + url}`,
      { dots },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjE1LCJpYXQiOjE3MTU3OTU4MTksImV4cCI6MTcxODM4NzgxOX0.XIADMeQYE3KwKe0-l300PDuU_Gg3J2EMccCT4T43Ya8`,
        },
      }
    );
    // if (isShowToast) toast.success(response?.data?.message);

    return response.data;
  } catch (error) {
    return "--";
  }
};
