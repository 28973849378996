import React from 'react';
import {
  FormGroup,
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
  Radio,
  RadioGroup,
  FormControl,
} from "@mui/material";

const TransferableSkillCheckboxes = ({ checkedState, handleCheckboxChange, transferableSkillRadioBtn , loader,setTransferableSkillRadioBtn }) => {
  
   // Handler for radio button changes
  // Simplified handler that only deals with the value
  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
     // Update radio buttons checked state
     setTransferableSkillRadioBtn(prevButtons => 
      prevButtons.map(btn => ({
        ...btn,
        checked: btn.value === selectedValue
      }))
    );
    // handleCheckboxChange({
    //   target: {
    //     name: 'searchType', // You can use this in your API call
    //     value: selectedValue // This will be "1", "2", "3", or "4"
    //   }
    // });
  };
  return (
    <Box mx={10}>
        {/* Radio Button Group - Simplified */}
      <FormControl sx={{ margin: "0 10px", display:"block" }}>
        <RadioGroup
          
          name="searchType"
          onChange={handleRadioChange}
        >
          <Grid container spacing={2}>
            {transferableSkillRadioBtn.map((btn) => (
              <Grid item xs={3} key={btn.value}>
                <FormControlLabel
                  value={btn.value}
                  control={
                    <Radio
                      disabled={loader}
                      sx={{ padding: 0, paddingRight: "5px" }}
                    />
                  }
                  label={btn.label}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </FormControl>
      {/* First Row */}
      {/* <FormGroup row sx={{ margin: "0 10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  checked={checkedState.WorkFields}
                  name="WorkFields"
                  sx={{ padding: 0, paddingRight: "5px" }}
                  onClick={handleCheckboxChange}
                  disabled={loader}
                />
              }
              label="Work Fields"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  checked={checkedState.MPSMS}
                  name="MPSMS"
                  sx={{ padding: 0, paddingRight: "5px" }}
                  onClick={handleCheckboxChange}
                  disabled={loader}
                />
              }
              label="MPSMS"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedState["2018SocCode"]}
                  disableRipple
                  name="2018SocCode"
                  sx={{ padding: 0, paddingRight: "5px" }}
                  onClick={handleCheckboxChange}
                  disabled={loader}
                />
              }
              label="SOC"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  checked={checkedState.GoeCode}
                  name="GoeCode"
                  sx={{ padding: 0, paddingRight: "5px" }}
                  onClick={handleCheckboxChange}
                  disabled={loader}
                />
              }
              label="GOE"
            />
          </Grid>
        </Grid>
      </FormGroup>

      <FormGroup row sx={{ margin: "0 10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  checked={checkedState?.data}
                  name="data"
                  sx={{ padding: 0, paddingRight: "5px" }}
                  onClick={handleCheckboxChange}
                  disabled={loader}
                />
              }
              label="Data"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  checked={checkedState?.people}
                  name="people"
                  sx={{ padding: 0, paddingRight: "5px" }}
                  onClick={handleCheckboxChange}
                  disabled={loader}
                />
              }
              label="People"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedState?.things}
                  disableRipple
                  name="things"
                  sx={{ padding: 0, paddingRight: "5px" }}
                  onClick={handleCheckboxChange}
                  disabled={loader}
                />
              }
              label="Things"
            />
          </Grid>
        </Grid>
      </FormGroup> */}
    </Box>
  );
};

export default TransferableSkillCheckboxes; 